import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { AxiosResponse } from "axios";
import { TextEnum } from "../../constants/text";
import { store } from "../../store";
import { createCall } from "../base";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { timeoutError } from "../../constants/reducers/base.reducer";
import {
  leaderboardActionStart,
  leaderboardErrorMessage,
  setLeaderboard,
} from "../../constants/reducers/leaderboard.reducer";
import { GetLeaderboardDTO } from "../../constants/dto/leaderboard";

type LeaderboardPageProps = {
  id: string;
};

let body: GetLeaderboardDTO;
export const getLeaderboardAction = async ({ id }: LeaderboardPageProps) => {
  store.dispatch(leaderboardActionStart(true));
  const {
    page,
    searchText,
    allScoreOrder,
    scoreOrder,
    childOccasionOrder,
    mixedOccasionOrder,
    occasionOrder,
    inviteSentOrder,
  } = store.getState().leaderboard;

  const queryParams = new URLSearchParams();
  if (id) queryParams.append("id", id);
  if (searchText) queryParams.append("searchText", searchText.trim());
  if (page) queryParams.append("page", page.toString());
  if (allScoreOrder) queryParams.append("allScoreOrder", allScoreOrder);
  if (scoreOrder) queryParams.append("scoreOrder", scoreOrder);
  if (childOccasionOrder)
    queryParams.append("childOccasionOrder", childOccasionOrder);
  if (mixedOccasionOrder)
    queryParams.append("mixedOccasionOrder", mixedOccasionOrder);
  if (occasionOrder) queryParams.append("occasionOrder", occasionOrder);
  if (inviteSentOrder) queryParams.append("inviteSentOrder", inviteSentOrder);

  const request = await createCall(
    "cms/leaderboards",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetLeaderboardDTO, response.data);

    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        leaderboardErrorMessage(TextEnum.GET_LEADERBOARD_INVALID_RESPONSE)
      );
      return;
    }

    return store.dispatch(
      setLeaderboard({
        topThree: response.data.topThree,
        pageCount: response.data.pageCount,
        nonWinnerDatas: response.data.nonWinnerDatas,
        campaignType: response.data.campaignType,
      })
    );
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 14002:
          errorMessage = TextEnum.GET_LEADERBOARD_ERROR_IN_SENT_RESPONSE;
          break;
        default:
          break;
      }
      store.dispatch(leaderboardErrorMessage(errorMessage));
      return null;
    }
    store.dispatch(leaderboardActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
