import { Expose } from "class-transformer";
import { IsArray, IsDefined, ValidateNested } from "class-validator";
import { NonWinnerUsers } from "../../entity";

export class GetLeaderboardXlsxDTO {
  @Expose()
  @IsDefined()
  @ValidateNested()
  @IsArray()
  nonWinnerDatas!: NonWinnerUsers[];
}
