import { Expose, Type } from "class-transformer";
import { IsDefined, IsNumber, IsString, ValidateNested } from "class-validator";
import { Role } from "./role.entity";
export class Admin {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  email!: string;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => Role)
  role!: Role;
}
