import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { createAdmin } from "../../actions/admin";
import { TextBox } from "../../constants/features/input";
import { MessageModal } from "../../constants/features/modal";
import { SwitchButton } from "../../constants/features/buttons";
import { CustomBreadCrumb } from "../../constants/features/breadcrumb";
import { useAppDispatch, useAppSelector } from "../../constants/global-hooks";
import {
  useHandleAdminAlertMessage,
  useHandleAdminChangeNormal,
  useHandleAdminDeciderChange,
  useHandleAdminEmailChange,
  useHandleAdminNavigateBack,
} from "../../constants/utils";

import "./create-admin.scss";

export const CreateAdminPage = () => {
  const dispatch = useAppDispatch();
  const { email, errorMessage, adminActionStart, type } = useAppSelector(
    (state: RootState) => state.admin
  );

  const [state, setState] = useState({
    buttonDisabler: true,
    clicked: false,
  });

  const handleAllertMessage = useHandleAdminAlertMessage(dispatch);
  const handleNavigateBack = useHandleAdminNavigateBack(dispatch);

  const handleSendInvite = async () => {
    await createAdmin();
    setState((prevState) => ({ ...prevState, clicked: !prevState.clicked }));
  };

  useEffect(() => {
    if (state.clicked && errorMessage === null && !adminActionStart) {
      handleNavigateBack();
    }
    setState((prevState) => ({ ...prevState, clicked: false }));
  }, [handleNavigateBack, state.clicked, errorMessage, adminActionStart]);

  return (
    <main className="main-page">
      <div className="title">{TextEnum.ADMIN_INVITE_PAGE_TITLE}</div>
      <CustomBreadCrumb
        title={TextEnum.ADMIN_PAGE_TITLE}
        onClick={handleNavigateBack}
        modifyTitle={""}
        createTitle={TextEnum.ADMIN_INVITE_TITLE}
        modify={false}
      />
      <div className="container">
        <SwitchButton
          decider={type === 2}
          handleChangeActual={useHandleAdminChangeNormal(dispatch)}
          mainButton={TextEnum.NORMAL_ADMIN}
          sideButton={TextEnum.MOBILE_ADMIN}
        />
        <div className="inputContainer">
          <TextBox
            value={email}
            onChange={useHandleAdminEmailChange(dispatch)}
            type="input"
            isEmail={true}
            customErrorMessage={TextEnum.EMAIL_REQUIRED_ERROR_MESSAGE}
            setDecider={useHandleAdminDeciderChange(setState)}
            placeholder={TextEnum.EMAIL}
          />
        </div>
        <button
          disabled={adminActionStart || state.buttonDisabler}
          onClick={handleSendInvite}
          className={`create-admin-button ${
            state.buttonDisabler || adminActionStart ? "disabled" : ""
          }`}
        >
          {adminActionStart ? (
            <LoadingOutlined />
          ) : (
            TextEnum.ADMIN_INVITE_BUTTON_TITLE
          )}
        </button>
      </div>
      {errorMessage && (
        <MessageModal
          title=""
          message={errorMessage}
          onCloseClick={handleAllertMessage}
        />
      )}
    </main>
  );
};
