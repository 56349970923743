import { MouseEventHandler } from "react";
import { Modal } from "./modal";
import { TextEnum } from "../../text";

import "./message-modal.scss";

interface MessageModalProps {
  title: string;
  message: string;
  onCloseClick: (message: string) => void;
  onOkClick?: MouseEventHandler;
}

export const MessageModal: React.FC<MessageModalProps> = ({
  title,
  onCloseClick,
  message,
  onOkClick,
}) => {
  return (
    <Modal>
      <div className="message-modal">
        <div className="message-modal__header">
          <div className="message-modal__title"> {title} </div>
          <button className="close-button" onClick={() => onCloseClick(null!)}>
            X
          </button>
        </div>
        <div className="">
          <h3 className="message-modal__message">{message}</h3>
        </div>
        <div className="message-modal__footer">
          {onOkClick ? (
            <button className="button" type="button" onClick={onOkClick}>
              {TextEnum.OK}
            </button>
          ) : null}
          <button
            className="button button-left-margin"
            type="button"
            onClick={() => onCloseClick(null!)}
          >
            {onOkClick ? TextEnum.CANCEL_BUTTON : TextEnum.OK}
          </button>
        </div>
      </div>
    </Modal>
  );
};
