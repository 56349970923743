import { useCallback } from "react";
import { useAppNavigate } from "../global-hooks";
import { getAllAdmin } from "../../actions/admin";
import {
  resetAdmin,
  setAdminEmail,
  setAdminErrorMessage,
  setAdminName,
  setAdminPage,
  setAdminPassword,
  setAdminSearchText,
  setAdminType,
} from "../reducers/admin.reducer";

export const useHandleAdminPageChange = (
  dispatch: Function,
  setSearchTerm: Function
) => {
  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(setAdminPage(page));
      setSearchTerm(false);
    },
    [dispatch, setSearchTerm]
  );
  return handlePageChange;
};

export const useHandleAdminEmailChange = (dispatch: Function) => {
  const handleAdminEmailChange = useCallback(
    (value: string) => {
      dispatch(setAdminEmail(value));
    },
    [dispatch]
  );
  return handleAdminEmailChange;
};

export const useHandleAdminAlertMessage = (dispatch: Function) => {
  const handleAlertMessage = useCallback(
    (message: string) => {
      dispatch(setAdminErrorMessage(message));
    },
    [dispatch]
  );
  return handleAlertMessage;
};

export const useHandleAdminChangeNormal = (dispatch: Function) => {
  const handleChangeNormal = useCallback(
    (value: boolean) => {
      if (value) {
        dispatch(setAdminType(2));
      } else {
        dispatch(setAdminType(1));
      }
    },
    [dispatch]
  );
  return handleChangeNormal;
};

export const useHandleAdminNavigateBack = (dispatch: Function) => {
  const navigate = useAppNavigate();
  const handleNavigateBack = useCallback(() => {
    navigate("/admin");
    dispatch(resetAdmin());
  }, [dispatch, navigate]);
  return handleNavigateBack;
};

export const useHandleAdminSearchChange = (dispatch: Function) => {
  const handleSearchChange = useCallback(
    (value: string) => {
      dispatch(setAdminSearchText(value));
    },
    [dispatch]
  );
  return handleSearchChange;
};

export const useHandleAdminDeciderChange = (setState: Function) => {
  const handleDecider = useCallback(
    (buttonDisabler: boolean) => {
      setState((prevState: any) => ({ ...prevState, buttonDisabler }));
    },
    [setState]
  );
  return handleDecider;
};

export const useHandleAdminInputDeciderChange = (setState: Function) => {
  const handleDecider = useCallback(
    (inputButtonDisabler: boolean) => {
      setState((prevState: any) => ({ ...prevState, inputButtonDisabler }));
    },
    [setState]
  );
  return handleDecider;
};

export const useHandleGetAllAdmin = () => {
  const getAdmins = useCallback(async () => {
    await getAllAdmin();
  }, []);
  return getAdmins;
};

export const useHandleAdminPasswordChange = (dispatch: Function) => {
  const handlePasswordChange = useCallback(
    (value: string) => {
      dispatch(setAdminPassword(value));
    },
    [dispatch]
  );
  return handlePasswordChange;
};

export const useHandlAdminPwCheckChange = (setState: Function) => {
  const handlePwCheckChange = useCallback(
    (value: string) => {
      setState((prevState: any) => ({ ...prevState, pwCheck: value }));
    },
    [setState]
  );
  return handlePwCheckChange;
};

export const useHandleNameChange = (dispatch: Function) => {
  const handleNameChange = useCallback(
    (value: string) => {
      dispatch(setAdminName(value));
    },
    [dispatch]
  );
  return handleNameChange;
};

export const useHandleProfileAlertMessage = (dispatch: Function) => {
  const navigate = useAppNavigate();
  const handleAlertMessage = useCallback(
    (message: string | null) => {
      dispatch(setAdminErrorMessage(message!));
      if (message === null) {
        navigate("/campaign");
      }
    },
    [dispatch, navigate]
  );
  return handleAlertMessage;
};
