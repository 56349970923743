import { AxiosResponse } from "axios";
import { BASE_METHODS } from "../../constants/action-types";

import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import {
  setUserActionStart,
  setUserErrorMessage,
} from "../../constants/reducers/user.reducer";
import { GetLeaderboardXlsxDTO } from "../../constants/dto/leaderboard";
import { setLeaderboardNonWinnerData } from "../../constants/reducers/leaderboard.reducer";

let body: GetLeaderboardXlsxDTO;

export const getLeaderboardXlsxData = async (id: string) => {
  store.dispatch(setUserActionStart(true));
  const queryParams = new URLSearchParams();
  if (id) queryParams.append("id", id);

  const request = await createCall(
    "cms/leaderboards/xlsx",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetLeaderboardXlsxDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setUserErrorMessage(
          TextEnum.GET_LEADERBOARD_XLSX_RESPONSE_INVALID_RESPONSE
        )
      );
      return null;
    }

    store.dispatch(setLeaderboardNonWinnerData(response.data.nonWinnerDatas));
    return response.data;
  } catch (error) {
    store.dispatch(setUserActionStart(false));
    store.dispatch(timeoutError());
    return null;
  }
};
