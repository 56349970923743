import { Expose } from "class-transformer";
import { IsDateString, IsString } from "class-validator";

export class GetAllUserXlsxDataDTO {
  @Expose()
  @IsString()
  email!: string;

  @Expose()
  @IsString()
  name!: string;

  @Expose()
  @IsDateString()
  created!: string;

  @Expose()
  @IsDateString()
  birthDate!: string;

  @Expose()
  @IsString()
  gender!: string;

  @Expose()
  @IsString()
  city!: string;
}
