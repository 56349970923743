import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "../../store";
import { getMeFailure } from "../../constants/reducers/session.reducer";
import {
  basicErrorMessage,
  clearError,
} from "../../constants/reducers/base.reducer";
import { TextEnum } from "../../constants/text";

export const createCall = async (
  url: string,
  method: string,
  contentType: string,
  vesrion: string,
  body?: any,
  queryParams?: any | undefined,
  skip?: boolean
): Promise<AxiosResponse | AxiosError> => {
  const token = store.getState().session.token;
  if (!skip) {
    if (token === null) {
      store.dispatch(getMeFailure());
    }
  }

  const func = await axios({
    method: method,
    url: `${process.env.REACT_APP_API_ORIGIN}/${vesrion}/${url}${
      queryParams ? "?" + queryParams : ""
    }`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": contentType,
    },
    data: body,
  })
    .then((response) => {
      store.dispatch(clearError());
      return response;
    })
    .catch((error) => {
      if (error.response !== undefined && error.response.data) {
        let errorMessage = "";
        switch (error.response.data.code) {
          case 1:
            errorMessage = TextEnum.SERVER_TIMEOUT_ERROR_TEXT;
            break;
          case 10:
            errorMessage = TextEnum.INVALID_PARAMETERS;
            break;
          case 20 || 30:
            errorMessage = TextEnum.NO_RIGHTS;
            break;
          case 1008:
            errorMessage = TextEnum.LOGIN_DELETED_USER_ERROR;
            break;
          case 5001:
            errorMessage = TextEnum.SESSION_EXPIRED;
            break;
          default:
            break;
        }
        store.dispatch(basicErrorMessage(errorMessage));
      }
      return error as AxiosError;
    });
  return new Promise<AxiosResponse | AxiosError>((res, rej) => {
    res(func);
  });
};
