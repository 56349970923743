type NumberInputParams = {
  value: number;
  onChange: (value: number) => void;
  placeholder: string;
  disabledFrom: string;
};

export const NumberInput: React.FC<NumberInputParams> = ({
  value,
  onChange,
  placeholder,
  disabledFrom,
}) => {
  const handleNotInapropriateInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue > 0) {
      onChange(inputValue);
    } else {
      onChange(1);
    }
  };

  return (
    <input
      type="number"
      value={value !== null ? value : ""}
      onChange={handleNotInapropriateInput}
      min={1}
      placeholder={placeholder}
      disabled={new Date(disabledFrom).getTime() <= new Date().getTime()}
    />
  );
};
