import { useMemo, useEffect } from "react";
import { TextArea } from "./text-area";
import { useTextValidator } from "../../global-hooks";
import searchIcon from "../../../static/Search@1.5x.svg";

type TextBoxProps = {
  value: string;
  onChange: (string: string) => void;
  type: string;
  customErrorMessage: string;
  setDecider: (boolean: boolean) => void;
  disabled?: boolean;
  isPassword?: boolean;
  placeholder?: string;
  isLogin?: boolean;
  isEmail?: boolean;
  search?: boolean;
};

export const TextBox: React.FC<TextBoxProps> = ({
  value,
  onChange,
  type,
  setDecider,
  disabled,
  isPassword,
  placeholder,
  isLogin,
  isEmail,
  customErrorMessage,
  search,
}) => {
  const error = useTextValidator({
    input: value,
    isPassword: Boolean(isPassword),
    isEmail: Boolean(isEmail),
  });

  useEffect(() => {
    setDecider(true);
    if (!error.value && value !== "") {
      setDecider(false);
    }
  }, [error.value, value, setDecider]);

  return useMemo(() => {
    return type === "input" ? (
      <div>
        <input
          className={`input-field ${
            isLogin
              ? "loginStyle"
              : error.value
              ? "input-error"
              : disabled
              ? "disabled"
              : ""
          }`}
          maxLength={255}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          disabled={disabled}
          type={isPassword ? "password" : "text"}
          placeholder={placeholder}
        />
        {search && <img src={searchIcon} alt={searchIcon} />}
        <div>
          <span className="input-error-message">
            {error.value &&
              (isPassword && !isLogin ? error.message : customErrorMessage)}
          </span>
        </div>
      </div>
    ) : (
      <div className="input-container">
        <TextArea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
        <span className="input-error-message">
          {error.value && customErrorMessage}
        </span>
      </div>
    );
  }, [
    onChange,
    disabled,
    isLogin,
    isPassword,
    placeholder,
    type,
    value,
    error,
    customErrorMessage,
    search,
  ]);
};
