import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { SuccessDTO } from "../../constants/dto/success";
import {
  removeAdminFromAdmins,
  setAdminActionStart,
  setAdminErrorMessage,
} from "../../constants/reducers/admin.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

type DeleteAdminProps = {
  id: number;
};
let body: SuccessDTO;
export const deleteAdmin = async (props: DeleteAdminProps) => {
  store.dispatch(setAdminActionStart(true));
  const { id } = props;

  const request = await createCall(
    `cms/admins/delete/${id}`,
    BASE_METHODS.DELETE,
    "application/json",
    "1.0.0"
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(SuccessDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setAdminErrorMessage(TextEnum.DELETE_ADMIN_INVALID_RESPONSE)
      );
      return;
    }
    store.dispatch(removeAdminFromAdmins(id));
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 1001:
          errorMessage = TextEnum.DELETE_ADMIN_USER_NOT_FOUND;
          break;
        case 1005:
          errorMessage = TextEnum.DELETE_ADMIN_CANNOT_DELETE_YOURSELF;
          break;
        case 1006:
          errorMessage = TextEnum.DELETE_ADMIN_CANNOT_DELETE_NON_ADMIN_USERS;
          break;
        default:
          break;
      }
      store.dispatch(setAdminErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setAdminActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
