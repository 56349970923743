import { useEffect, useState } from "react";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { modifyAdmin } from "../../actions/admin";
import { TextBox } from "../../constants/features/input";
import { MessageModal } from "../../constants/features/modal";
import { resetAdmin } from "../../constants/reducers/admin.reducer";
import { useAppDispatch, useAppSelector } from "../../constants/global-hooks";
import {
  setAdminEmail,
  setAdminName,
} from "../../constants/reducers/admin.reducer";
import {
  useHandlAdminPwCheckChange,
  useHandleAdminDeciderChange,
  useHandleAdminPasswordChange,
  useHandleNameChange,
  useHandleProfileAlertMessage,
  useHandleAdminInputDeciderChange,
} from "../../constants/utils";

export const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );
  const { email, name, password, errorMessage, adminActionStart } =
    useAppSelector((state: RootState) => state.admin);

  const [state, setState] = useState({
    pwCheck: "",
    clicked: false,
    buttonDisabler: true,
    inputButtonDisabler: true,
  });

  useEffect(() => {
    if (authenticatedUser) {
      dispatch(setAdminName(authenticatedUser.name!));
      dispatch(setAdminEmail(authenticatedUser.email));
    }
  }, [dispatch, authenticatedUser]);

  const handlePasswordCheckChange = useHandlAdminPwCheckChange(setState);

  const handleAllertMessage = useHandleProfileAlertMessage(dispatch);
  const setDecider = useHandleAdminDeciderChange(setState);
  const setInputDecider = useHandleAdminInputDeciderChange(setState);

  const handleModify = async () => {
    await modifyAdmin({ name: name, password: password });
    setState((prevState) => ({ ...prevState, clicked: true }));
  };

  useEffect(() => {
    if (state.clicked && !state.buttonDisabler && !adminActionStart) {
      dispatch(resetAdmin());
    }
    setState((prevState) => ({ ...prevState, clicked: false }));
  }, [
    dispatch,
    handleAllertMessage,
    state.clicked,
    errorMessage,
    adminActionStart,
    state.buttonDisabler,
  ]);

  useEffect(() => {
    setDecider(true);
    if (email && name && password && state.pwCheck) {
      setDecider(false);
    }
  }, [email, name, password, state.pwCheck, setDecider]);

  return (
    <main className="main-page">
      <div className="title">{TextEnum.PROFILE_PAGE_TITLE}</div>
      <div className="container">
        <div className="inputContainer">
          <TextBox
            value={email}
            type="input"
            disabled={true}
            customErrorMessage=""
            onChange={() => {
              return;
            }}
            setDecider={setInputDecider}
            placeholder={TextEnum.EMAIL}
          />
        </div>
        <div className="inputContainer">
          <TextBox
            value={name}
            onChange={useHandleNameChange(dispatch)}
            type="input"
            customErrorMessage={TextEnum.NAME_REQUIRED_ERROR_MESSAGE}
            setDecider={setDecider}
            placeholder={TextEnum.NAME}
          />
        </div>
        <div className="inputContainer">
          <TextBox
            value={password}
            onChange={useHandleAdminPasswordChange(dispatch)}
            type="input"
            isPassword={true}
            customErrorMessage={TextEnum.PASSWORD_FORMAT_INVALID_ERROR_MESSAGE}
            setDecider={setInputDecider}
            placeholder={TextEnum.PASSWORD_TEXT}
          />
        </div>
        <div className="inputContainer">
          <TextBox
            value={state.pwCheck}
            onChange={handlePasswordCheckChange}
            type="input"
            isPassword={true}
            customErrorMessage={TextEnum.NOT_MATCHING_PASSWORDS}
            setDecider={setInputDecider}
            placeholder={TextEnum.PROFILE_PASSWORD_CHECK}
          />
        </div>
        <button
          className={`create-admin-button ${
            adminActionStart ||
            state.buttonDisabler ||
            state.inputButtonDisabler
              ? "disabled"
              : ""
          }`}
          disabled={
            adminActionStart ||
            state.buttonDisabler ||
            state.inputButtonDisabler
          }
          onClick={handleModify}
        >
          {TextEnum.MODIFY}
        </button>
      </div>
      {errorMessage && (
        <MessageModal
          title=""
          message={errorMessage}
          onCloseClick={() => handleAllertMessage(null)}
        />
      )}
    </main>
  );
};
