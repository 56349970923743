import { Outlet } from "react-router-dom";
import pic from "../../static/logo-1.svg";

import "./login.layout.scss";

export const LoginLayout = () => {
  return (
    <main className="layout-main-page">
      <img src={pic} alt={pic} />
      <Outlet />
    </main>
  );
};
