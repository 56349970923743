import { Expose } from "class-transformer";
import { IsBoolean, IsDefined, IsNumber, IsString } from "class-validator";
export class Version {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  version!: string;

  @Expose()
  @IsDefined()
  @IsNumber()
  platform!: number;

  @Expose()
  @IsDefined()
  @IsBoolean()
  critical!: boolean;
}
