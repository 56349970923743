import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import {
  resetCompany,
  setCompanyActionStart,
  setCompanyErrorMessage,
} from "../../constants/reducers/company.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { AxiosResponse } from "axios";
import { SuccessDTO } from "../../constants/dto/success";

let body: SuccessDTO;

export const resendCompanyCode = async () => {
  store.dispatch(setCompanyActionStart(true));
  const { selectedId, email } = store.getState().company;

  const params = {
    id: selectedId,
    email,
  };
  const request = await createCall(
    `cms/companies/resend`,
    BASE_METHODS.POST,
    "application/json",
    "1.0.0",
    params,
    undefined
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(SuccessDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setCompanyErrorMessage(TextEnum.RESEND_COMPANY_CODE_INVALID_RESPONSE)
      );
      return;
    }
    store.dispatch(resetCompany());
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 2001:
          errorMessage = TextEnum.RESEND_COMPANY_CODE_COMPANY_NOT_FOUND;
          break;

        default:
          break;
      }
      store.dispatch(setCompanyErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setCompanyActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
