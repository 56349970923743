import "./badge.scss";
import { TextEnum } from "../../constants/text";
import { BadgeCards } from "./badge-cards";
import { useAppDispatch, useAppSelector } from "../../constants/global-hooks";
import { RootState } from "../../store";
import { MessageModal } from "../../constants/features/modal";
import { useHandleBadgeAlertMessage } from "../../constants/utils";

export const BadgePage = () => {
  const dispatch = useAppDispatch();
  const { errorMessage } = useAppSelector((state: RootState) => state.badge);
  const handleAlertMessage = useHandleBadgeAlertMessage(dispatch);
  return (
    <main className="main-page">
      <div className="title">{TextEnum.BADGE_PAGE_TITLE}</div>
      <div className="card-container">
        <BadgeCards />
      </div>

      {errorMessage && (
        <MessageModal
          title=""
          message={errorMessage!}
          onCloseClick={handleAlertMessage}
        />
      )}
    </main>
  );
};
