import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../constants/global-hooks";
import { RootState } from "../../store";
import { ROLE_TYPE_ENUM } from "../../constants/enum";

interface RequireAuthProps {
  children: JSX.Element;
}

export const RequireSuperAdminAuth = (props: RequireAuthProps) => {
  const authenticatedUser = useAppSelector(
    (state: RootState) => state.session.authenticatedUser
  );
  if (
    authenticatedUser &&
    authenticatedUser?.role.id !== ROLE_TYPE_ENUM.SUPER_ADMIN
  ) {
    return <Navigate to={`/login`} />;
  }
  return props.children;
};
