import { AxiosResponse } from "axios";
import { BASE_METHODS } from "../../constants/action-types";

import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import {
  setCities,
  setUserActionStart,
  setUserErrorMessage,
} from "../../constants/reducers/user.reducer";
import { GetCityOfUsersDTO } from "../../constants/dto/user";
let body: GetCityOfUsersDTO;

export const getAllCityOfUsers = async () => {
  store.dispatch(setUserActionStart(true));

  const request = await createCall(
    "cms/users/cities",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0"
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetCityOfUsersDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setUserErrorMessage(TextEnum.GET_ALL_USER_CITIES_INVALID_RESPONSE)
      );
      return null;
    }
    store.dispatch(setCities({ cities: response.data.cities }));
    return response;
  } catch (error) {}
  store.dispatch(setUserActionStart(false));
  store.dispatch(timeoutError());
  return null;
};
