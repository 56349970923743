import { Expose, Type } from "class-transformer";
import { IsArray, ValidateNested } from "class-validator";
import { Badge } from "../../entity";

export class GetAllBadgeDTO {
  @Expose()
  @IsArray()
  @ValidateNested()
  @Type(() => Badge)
  badges!: Badge[];
}
