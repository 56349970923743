import { useMemo } from "react";
import { TextEnum } from "../../text";
import { CustomOrder } from "../table";
import { RootState } from "../../../store";
import { NonAdminUsers } from "../../entity";
import { useAppDispatch, useAppSelector } from "../../global-hooks";
import {
  useHandleUserCreatedOrderChange,
  useHandleUserNameOrderChange,
  useHandleUserSearchCityChange,
} from "../../utils";

export const UserColumns = () => {
  const dispatch = useAppDispatch();
  const { nameOrder, createdOrder, cities } = useAppSelector(
    (state: RootState) => state.user
  );
  const handleNameOrderChange = useHandleUserNameOrderChange(dispatch);
  const handleCreateOrderChange = useHandleUserCreatedOrderChange(dispatch);
  const handleSearchCity = useHandleUserSearchCityChange(dispatch);

  return useMemo(() => {
    return [
      {
        title: (
          <div className="inline-container">
            {TextEnum.NAME}
            <CustomOrder
              orderFunction={handleNameOrderChange}
              order={nameOrder}
            />
          </div>
        ),
        key: TextEnum.NAME,
        dataIndex: "name",
      },
      {
        title: TextEnum.EMAIL,
        key: TextEnum.EMAIL,
        dataIndex: "email",
      },
      {
        title: TextEnum.GENDER,
        key: TextEnum.GENDER,
        dataIndex: "gender",
        render: (text: string, record: NonAdminUsers) => {
          return record.gender;
        },
      },
      {
        title: TextEnum.BIRTHDATE,
        key: TextEnum.BIRTHDATE,
        dataIndex: "birthDate",
        render: (text: string, record: NonAdminUsers) => {
          return record.birthDate ?? "";
        },
      },
      {
        title: TextEnum.CITY,
        key: TextEnum.CITY,
        dataIndex: "city",
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
        }: {
          setSelectedKeys: (keys: string[]) => void;
          selectedKeys: string[];
        }) => (
          <div className="drpodown-menu">
            {cities.map((city) => (
              <div key={city} className="radio-button-container">
                <label>
                  <input
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                    }}
                    className="radio-button"
                    type="radio"
                    value={city}
                    checked={selectedKeys[0] === city}
                    onChange={(e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                  />
                  {city}
                </label>
              </div>
            ))}
            <div className="button-contanier">
              <button
                className="reset-button"
                onClick={() => {
                  handleSearchCity("");
                  setSelectedKeys([]);
                }}
              >
                Reset
              </button>
              <button
                className="ok-button"
                onClick={() => handleSearchCity(selectedKeys[0])}
              >
                OK
              </button>
            </div>
          </div>
        ),
      },
      {
        title: (
          <div className="inline-container">
            {TextEnum.CREATED}
            <CustomOrder
              orderFunction={handleCreateOrderChange}
              order={createdOrder}
            />
          </div>
        ),
        key: TextEnum.CREATED,
        dataIndex: "created",
        render: (text: string, record: NonAdminUsers) => {
          return new Date(record.created).toLocaleDateString();
        },
        width: "150px",
      },
      {
        title: TextEnum.USER_TABLE_SUBSCRIBED,
        key: TextEnum.USER_TABLE_SUBSCRIBED,
        dataIndex: "subscribed",
        render: (text: string, record: NonAdminUsers) => {
          return record.subscribed === true
            ? TextEnum.SUBSCRIBED
            : TextEnum.NON_SUBSCRIBED;
        },
      },
    ];
  }, [
    handleCreateOrderChange,
    handleNameOrderChange,
    createdOrder,
    nameOrder,
    cities,
    handleSearchCity,
  ]);
};
