import { useMemo } from "react";
import {
  RedoOutlined,
  LoadingOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { TextEnum } from "../../text";
import { CustomOrder } from "../table";
import { Campaign } from "../../entity";
import { RootState } from "../../../store";
import { CustomPopConfirm } from "../popconfirm";
import deleteIcon from "../../../static/trash.svg";
import { deleteCampaign } from "../../../actions/campaign";
import { useAppDispatch, useAppSelector } from "../../global-hooks";
import { restoreCampaign } from "../../../actions/campaign/restore-campaign";
import {
  useHandleCampaignValidFromOrderChange,
  useHandleCampaignValidToOrderChange,
} from "../../utils";
import { getLeaderboardXlsxData } from "../../../actions/leaderboard";

export const CampaignColumns = () => {
  const dispatch = useAppDispatch();
  const { isDeleted, campaignActionStart, validFromOrder, validToOrder } =
    useAppSelector((state: RootState) => state.campaign);

  const handleDelete = async (id: number) => {
    await deleteCampaign({ id });
  };

  const handleRestore = async (id: number) => {
    await restoreCampaign({ id });
  };

  const handleExportLeaderboard = async (id: number) => {
    await getLeaderboardXlsxData(id.toString());
  };

  const validFromOrderChange = useHandleCampaignValidFromOrderChange(dispatch);
  const validToOrderChange = useHandleCampaignValidToOrderChange(dispatch);

  return useMemo(() => {
    const columns = [
      {
        title: TextEnum.PICTURE,
        key: "icon",
        render: (text: string, record: Campaign) => {
          return (
            <img className="table-img" src={record.url} alt={record.url} />
          );
        },
        width: "100px",
      },
      {
        title: TextEnum.CAMPAIGN_TITLE_NAME,
        key: TextEnum.CAMPAIGN_TITLE_NAME,
        render: (text: string, record: Campaign) => {
          return record.name.length > 100
            ? record.name.substring(0, 100) + "..."
            : record.name;
        },
        width: "200px",
      },
      {
        title: TextEnum.CAMPAIGN_SUBTITLE_NAME,
        key: TextEnum.CAMPAIGN_SUBTITLE_NAME,
        render: (text: string, record: Campaign) => {
          return record.subtitle.length > 100
            ? record.subtitle.substring(0, 100) + "..."
            : record.subtitle;
        },
      },
      {
        title: TextEnum.CAMPAIGN_TITLE_DESCRIPTION,
        key: TextEnum.CAMPAIGN_TITLE_DESCRIPTION,
        render: (text: string, record: Campaign) => {
          return record.description.length > 100
            ? record.description
                .substring(0, 100)
                .replace(/<[/]?[b|i|u]>/g, "") + "..."
            : record.description.replace(/<[/]?[b|i|u]>/g, "");
        },
      },
      {
        title: (
          <div className="inline-container">
            {TextEnum.CAMPAIGN_TITLE_VALID_FROM}
            <CustomOrder
              orderFunction={validFromOrderChange}
              order={validFromOrder}
            />
          </div>
        ),
        key: TextEnum.CAMPAIGN_TITLE_VALID_FROM,
        render: (text: string, record: Campaign) => {
          return new Date(record.validFrom).toLocaleDateString();
        },
        width: "150px",
      },
      {
        title: (
          <div className="inline-container">
            {TextEnum.CAMPAIGN_TITLE_VALID_TO}
            <CustomOrder
              orderFunction={validToOrderChange}
              order={validToOrder}
            />
          </div>
        ),
        key: TextEnum.CAMPAIGN_TITLE_VALID_TO,
        render: (text: string, record: Campaign) => {
          return new Date(record.validTo).toLocaleDateString();
        },
        width: "150px",
      },
      {
        title: TextEnum.EXPORT + "és " + TextEnum.DELETE,
        key: TextEnum.DELETE,
        render: (text: string, record: Campaign) => {
          return (
            <div>
              <ExportOutlined
                style={{ marginRight: "2em" }}
                onClick={() => handleExportLeaderboard(record.id)}
              />
              <CustomPopConfirm
                title={TextEnum.CAMPAIGN_DELETE_POPUP_CONFIRM}
                okText={TextEnum.YES}
                cancelText={TextEnum.NO}
                onConfirm={() => handleDelete(record.id)}
                disabled={false}
              >
                <button disabled={campaignActionStart}>
                  {campaignActionStart ? (
                    <LoadingOutlined />
                  ) : (
                    <img
                      className="table-icon"
                      src={deleteIcon}
                      alt={deleteIcon}
                    />
                  )}
                </button>
              </CustomPopConfirm>
            </div>
          );
        },
        width: "150px",
      },
      {
        title: TextEnum.RESTORE,
        key: TextEnum.RESTORE,
        render: (text: string, record: Campaign) => {
          return (
            <CustomPopConfirm
              title={TextEnum.CAMPAIGN_RESTORE_POPUP_CONFIRM}
              okText={TextEnum.YES}
              cancelText={TextEnum.NO}
              onConfirm={() => handleRestore(record.id)}
              disabled={new Date(record.validTo) < new Date()}
            >
              <>
                {new Date(record.validFrom).getTime() >= new Date().getTime() &&
                  new Date(record.validTo).getTime() >=
                    new Date().getTime() && (
                    <button
                      disabled={
                        campaignActionStart ||
                        (new Date(record.validFrom).getTime() >=
                          new Date().getTime() &&
                          new Date(record.validTo).getTime() >=
                            new Date().getTime())
                      }
                    >
                      {campaignActionStart ? (
                        <LoadingOutlined />
                      ) : (
                        <RedoOutlined />
                      )}
                    </button>
                  )}
              </>
            </CustomPopConfirm>
          );
        },
        width: "100px",
      },
    ];
    if (isDeleted === true) {
      return columns
        .filter((column) => column.key !== TextEnum.DELETE)
        .filter((column) => column.key !== TextEnum.MODIFY);
    } else {
      return columns.filter((column) => column.key !== TextEnum.RESTORE);
    }
  }, [
    validFromOrderChange,
    validToOrderChange,
    isDeleted,
    campaignActionStart,
    validFromOrder,
    validToOrder,
  ]);
};
