import { useCallback } from "react";
import { useAppNavigate } from "../global-hooks";
import {
  resetCompany,
  setCompanyCretorEmail,
  setCompanyErrorMessage,
  setCompanyName,
  setCompanyPage,
  setCompanyTaxNumber,
  setSearchText,
} from "../reducers/company.reducer";

export const useHandleCompanyNavigateBack = (dispatch: Function) => {
  const navigate = useAppNavigate();
  const handleNavigateBack = useCallback(() => {
    navigate("/company");
    dispatch(resetCompany());
  }, [dispatch, navigate]);
  return handleNavigateBack;
};

export const useHandleCompanyNameChange = (dispatch: Function) => {
  const handleNameChange = useCallback(
    (value: string) => {
      dispatch(setCompanyName(value));
    },
    [dispatch]
  );
  return handleNameChange;
};

export const useHandleCompanyTaxNumberChange = (dispatch: Function) => {
  const handleTaxNumberChange = useCallback(
    (value: string) => {
      dispatch(setCompanyTaxNumber(value));
    },
    [dispatch]
  );
  return handleTaxNumberChange;
};

export const useHandleCompanyCreatorEmailChange = (dispatch: Function) => {
  const handleCompanyCreatorEmailChange = useCallback(
    (value: string) => {
      dispatch(setCompanyCretorEmail(value));
    },
    [dispatch]
  );
  return handleCompanyCreatorEmailChange;
};

export const useHandleCompanyAlertMessageChange = (dispatch: Function) => {
  const handleAllertMessage = useCallback(
    (message: string | null) => {
      dispatch(setCompanyErrorMessage(message));
    },
    [dispatch]
  );
  return handleAllertMessage;
};

export const useHandleCompanyInputDeciderChange = (setState: Function) => {
  const handleDecider = useCallback(
    (inputButtonDisabler: boolean) => {
      setState((prevState: any) => ({ ...prevState, inputButtonDisabler }));
    },
    [setState]
  );
  return handleDecider;
};

export const useHandleCompanyPageChange = (
  dispatch: Function,
  setSearchTerm: Function
) => {
  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(setCompanyPage(page));
      setSearchTerm(false);
    },
    [dispatch, setSearchTerm]
  );
  return handlePageChange;
};

export const useHandleCompanySearchTextChange = (dispatch: Function) => {
  const handleSearchChange = useCallback(
    (message: string) => {
      console.log(message);
      dispatch(setSearchText(message));
    },
    [dispatch]
  );
  return handleSearchChange;
};
