import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import {
  addBadgeToBadges,
  setBadgeActionStart,
  setBadgeErrorMessage,
} from "../../constants/reducers/badge.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { CreateBadgeDTO } from "../../constants/dto/badge";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

let body: CreateBadgeDTO;

export const createBadge = async () => {
  store.dispatch(setBadgeActionStart(true));
  const { picture, name, type, description, active } = store.getState().badge;

  const formData = new FormData();
  if (picture) {
    const convertedFile = picture;
    formData.append("picture", convertedFile);
  }
  formData.append("name", name);
  formData.append("description", description);
  formData.append("type", type!.toString());
  formData.append("active", active.toString());

  const request = await createCall(
    "cms/badges/create",
    BASE_METHODS.POST,
    "multipart/form-data",
    "1.0.0",
    formData
  );
  try {
    const response = request as AxiosResponse;
    body = plainToClass(CreateBadgeDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setBadgeErrorMessage(TextEnum.CREATE_BADGE_INVALID_RESPONSE)
      );
      return;
    }
    store.dispatch(addBadgeToBadges(response.data.badge));
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 9001:
          errorMessage = TextEnum.CREATE_BADGE_FILE_NOT_FOUND;
          break;
        case 10002:
          errorMessage = TextEnum.CREATE_BADGE_MAX_NUMBER_REACHED;
          break;
        case 10003:
          errorMessage = TextEnum.CREATE_BADGE_THIS_KIND_OF_BADGE_ALREADY_EXIST;
          break;
        default:
          break;
      }
      store.dispatch(setBadgeErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setBadgeActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
