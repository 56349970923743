import { Expose, Type } from "class-transformer";
import { IsArray, IsNumber, ValidateNested } from "class-validator";
import { Version } from "../../entity";

export class GetAllVersionDTO {
  @Expose()
  @ValidateNested()
  @IsArray()
  @Type(() => Version)
  versions!: Version[];

  @Expose()
  @IsNumber()
  pageCount!: number;
}
