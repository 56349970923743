import { useEffect, useState, useCallback } from "react";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { TextBox } from "../../constants/features/input";
import { DataTable } from "../../constants/features/table";
import { MessageModal } from "../../constants/features/modal";
import { DeciderButtons } from "../../constants/features/buttons";
import { CampaignColumns } from "../../constants/features/columns";
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
  useDebounce,
} from "../../constants/global-hooks";
import {
  useHandleCampaignAlertMessage,
  useHandleCampaignModify,
  useHandleCampaignPageChange,
  useHandleCampaignSearchTextChange,
  useHandleGetAllCampaign,
} from "../../constants/utils";

import "./campaign.scss";
import {
  setCampaignPage,
  setIsArchived,
  setIsDeleted,
} from "../../constants/reducers/campaign.reducer";
import * as XLSX from "xlsx";
import { resetLeaderboard } from "../../constants/reducers/leaderboard.reducer";

export const CampaignPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const {
    page,
    isDeleted,
    isArchived,
    errorMessage,
    campaigns,
    pageCount,
    searchText,
    validFromOrder,
    validToOrder,
  } = useAppSelector((state: RootState) => state.campaign);
  const { leaderExport } = useAppSelector(
    (state: RootState) => state.leaderboard
  );

  const [state, setState] = useState({
    searchTerm: false,
    isHovered: false,
    decider: 1,
  });

  const handleAlertMessage = useHandleCampaignAlertMessage(dispatch);
  const handleModify = useHandleCampaignModify();
  const getAllCampaign = useHandleGetAllCampaign();

  useEffect(() => {
    if (searchText || validFromOrder || validToOrder) {
      setState((prevState) => ({ ...prevState, searchTerm: true }));
    }
  }, [searchText, validFromOrder, validToOrder]);

  const debouncedGetCampaigns = useDebounce(
    getAllCampaign,
    state.searchTerm ? 500 : 0
  );

  const handleDeciderChange = (value: number) => {
    setState((prevState) => ({ ...prevState, decider: value }));
    dispatch(setCampaignPage(1));
  };
  useEffect(() => {
    if (state.decider === 3) {
      dispatch(setIsDeleted(true));
      setState((prevState) => ({ ...prevState, searchTerm: false }));
    }
    if (state.decider === 2) {
      dispatch(setIsDeleted(false));
      dispatch(setIsArchived(true));
      setState((prevState) => ({ ...prevState, searchTerm: false }));
    }
    if (state.decider === 1) {
      dispatch(setIsDeleted(false));
      dispatch(setIsArchived(false));
      setState((prevState) => ({ ...prevState, searchTerm: false }));
    }
  }, [state.decider, dispatch]);

  useEffect(() => {
    debouncedGetCampaigns();
  }, [
    debouncedGetCampaigns,
    page,
    isDeleted,
    isArchived,
    searchText,
    validFromOrder,
    validToOrder,
  ]);

  const generateXLSX = useCallback((nonWinnerDatas: any) => {
    if (nonWinnerDatas) {
      const workbook = XLSX.utils.book_new();
      const nonWinnerWorksheet = XLSX.utils.json_to_sheet(nonWinnerDatas);
      XLSX.utils.book_append_sheet(workbook, nonWinnerWorksheet, "All data");

      const arrayBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([arrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `ranglista-${new Date().getTime()}.xlsx`;
      link.click();

      URL.revokeObjectURL(url);
    } else {
      console.warn("No leaderboard to export.");
    }
  }, []);

  useEffect(() => {
    if (leaderExport) {
      generateXLSX(leaderExport);
      dispatch(resetLeaderboard());
    }
  }, [leaderExport, dispatch, generateXLSX]);

  return (
    <main className="main-page">
      <div className="title">{TextEnum.CAMPAIGNS_PAGE_TITLE}</div>
      <div className="buttons-container">
        <div className="campaign-buttons">
          <DeciderButtons
            decider={state.decider}
            handleChange={handleDeciderChange}
            defaultButton={TextEnum.ACTUAL_BUTTON_LABEL}
            secondButton={TextEnum.ARCHIVED_BUTTON_LABEL}
            thirdButton={TextEnum.DELETED_BUTTON_LABEL}
          />
        </div>
        <TextBox
          value={searchText}
          onChange={useHandleCampaignSearchTextChange(dispatch)}
          type={"input"}
          customErrorMessage={""}
          setDecider={() => null}
          placeholder={TextEnum.SEARCH}
          search={true}
        />
        <button
          className="create-campaign-button"
          onClick={() => navigate("/campaign/create")}
        >
          {TextEnum.CREATE_CAMPAIGN}
        </button>
      </div>
      <DataTable
        dataSource={campaigns}
        columns={Object.values(CampaignColumns())}
        maxPage={pageCount}
        currentPage={page}
        setCurrentPage={useHandleCampaignPageChange(dispatch, setState)}
        onRowClick={handleModify}
        functionDisabler={isDeleted}
        isAdminColumn={isDeleted}
      />
      {errorMessage && (
        <MessageModal
          title=""
          message={errorMessage!}
          onCloseClick={handleAlertMessage}
        />
      )}
    </main>
  );
};
