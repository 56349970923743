import { TextEnum } from "../../text";
import { RootState } from "../../../store";
import logo from "../../../static/logo-1.svg";
import userIcon from "../../../static/user@1.5x.png";
import { useAppNavigate, useAppSelector } from "../../global-hooks";

import "./header.scss";

export const Header = () => {
  const navigate = useAppNavigate();
  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );
  return (
    <div className="header-main">
      <div className="header-content">
        <div className="logo-container">
          <img src={logo} alt={logo} />
        </div>
        <div className="text-container">
          {`${TextEnum.LOGGED_IN_AS} ${authenticatedUser?.name}`}
          <img
            src={userIcon}
            alt={userIcon}
            onClick={() => navigate("/profile")}
          />
        </div>
      </div>
    </div>
  );
};
