import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { PredictiveSearchCompanyDTO } from "../../constants/dto/company";
import { setCompany } from "../../constants/reducers/campaign.reducer";
import {
  setCompanyActionStart,
  setCompanyErrorMessage,
} from "../../constants/reducers/company.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { AxiosResponse } from "axios";
type CompanyPredictiveSearchProps = {
  name: string;
};

let body: PredictiveSearchCompanyDTO;
export const predictiveSearchCompany = async ({
  name,
}: CompanyPredictiveSearchProps) => {
  store.dispatch(setCompanyActionStart(true));
  const queryParams = new URLSearchParams();
  if (name) queryParams.append("name", name);

  const request = await createCall(
    "cms/companies/predictive",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(PredictiveSearchCompanyDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setCompanyErrorMessage(
          TextEnum.PREDICTIVE_SEARCH_COMPANY_INVALID_RESPONSE
        )
      );
      return null;
    }
    store.dispatch(setCompany(response.data.companies));
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 2003:
          errorMessage =
            TextEnum.PREDICTIVE_SEARCH_COMPANY_ERROR_IN_SENT_RESPONSE;
          break;
        default:
          break;
      }
      store.dispatch(setCompanyErrorMessage(errorMessage));
      return null;
    }
    store.dispatch(setCompanyActionStart(false));
    store.dispatch(timeoutError());
    return null;
  }
};
