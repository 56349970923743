import { MouseEvent } from "react";

import "./modal.scss";

interface ModalProps {
  className?: string;
  children: JSX.Element | JSX.Element[];
  onModalClose?: CallableFunction;
}

export const Modal: React.FC<ModalProps> = ({
  className,
  children,
  onModalClose,
}) => {
  const modalClose = (event: MouseEvent) => {
    if (event.target === event.currentTarget && onModalClose) {
      onModalClose();
    }
  };

  return (
    <div className="modal-container" onClick={modalClose}>
      <div className={`modal ${className}`}>{children}</div>
    </div>
  );
};
