import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Company } from "../entity";
import { CompanyState } from "../state/company.state";

const initialState: CompanyState = {
  name: "",
  taxNumber: "",
  page: 1,
  errorMessage: null,
  companies: [],
  company: null!,
  pageCount: null!,
  companyActionStart: false,
  email: "",
  searchText: "",
  selectedId: null!,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },

    setCompanyPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setCompanyCretorEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },

    setCompanyTaxNumber: (state, action: PayloadAction<string>) => {
      state.taxNumber = action.payload;
    },

    setCompanies: (
      state,
      action: PayloadAction<{ companies: Company[]; pageCount: number }>
    ) => {
      state.companies = action.payload.companies;
      state.pageCount = action.payload.pageCount;
      state.errorMessage = null;
      state.companyActionStart = false;
    },

    setCompany: (state, action: PayloadAction<{ company: Company }>) => {
      state.company = action.payload.company;
      state.name = action.payload.company?.name;
      state.taxNumber = action.payload.company?.taxNumber;
      state.companyActionStart = false;
    },

    setCompanyErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload;
      state.companyActionStart = false;
    },

    addCompanyToCompanies: (
      state,
      action: PayloadAction<{ company: Company }>
    ) => {
      state.companies = [...state.companies, action.payload.company];
      state.companyActionStart = false;
    },

    removeCompanyFromCompanies: (state, action: PayloadAction<number>) => {
      if (state.companies.length % 10 === 1) {
        state.page -= 1;
        state.pageCount -= 1;
      }
      state.companies = [
        ...state.companies.filter((c) => c.id !== action.payload),
      ];
      state.errorMessage = null;
      state.companyActionStart = false;
    },

    resetCompany: (state) => {
      state.name = "";
      state.taxNumber = "";
      state.page = 1;
      state.company = null!;
      state.errorMessage = "";
      state.email = "";
      state.searchText = "";
      state.selectedId = null!;
      state.companyActionStart = false;
    },

    setCompanyActionStart: (state, action: PayloadAction<boolean>) => {
      state.companyActionStart = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
      state.page = 1;
    },
    setCompanySelectedId: (state, action: PayloadAction<number>) => {
      state.selectedId = action.payload;
    },
  },
});

export const {
  setCompanyName,
  setCompanyPage,
  setCompanies,
  setCompany,
  setCompanyErrorMessage,
  addCompanyToCompanies,
  removeCompanyFromCompanies,
  resetCompany,
  setCompanyActionStart,
  setCompanyTaxNumber,
  setCompanyCretorEmail,
  setSearchText,
  setCompanySelectedId,
} = companySlice.actions;
