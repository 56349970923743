import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import {
  setCampaigns,
  setCampaignErrorMessage,
  setCampaignActionStart,
} from "../../constants/reducers/campaign.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { GetAllCampaignDTO } from "../../constants/dto/campaign";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

let body: GetAllCampaignDTO;

export const getAllCampaign = async () => {
  store.dispatch(setCampaignActionStart(true));
  const {
    searchText,
    page,
    isDeleted,
    validFromOrder,
    validToOrder,
    isArchived,
  } = store.getState().campaign;
  const queryParams = new URLSearchParams();

  if (searchText) queryParams.append("searchText", searchText);
  if (page) queryParams.append("page", page.toString());
  if (validFromOrder) queryParams.append("validFromOrder", validFromOrder);
  if (validToOrder) queryParams.append("validToOrder", validToOrder);
  if (isDeleted !== undefined)
    queryParams.append("isDeleted", isDeleted.toString());
  if (isArchived !== undefined)
    queryParams.append("isArchived", isArchived.toString());

  const request = await createCall(
    "cms/campaigns",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetAllCampaignDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setCampaignErrorMessage(TextEnum.GET_ALL_CAMPAIGN_INVALID_RESPONSE)
      );
      return null;
    }
    store.dispatch(
      setCampaigns({
        campaigns: response.data.campaigns,
        pageCount: response.data.pageCount,
      })
    );
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 8001:
          errorMessage = TextEnum.GET_ALL_CAMPAIGN_ERROR_IN_SENT_RESPONSE;
          break;

        default:
          break;
      }
      store.dispatch(setCampaignErrorMessage(errorMessage));
      return null;
    }
    store.dispatch(setCampaignActionStart(false));
    store.dispatch(timeoutError());
    return null;
  }
};
