import { Expose, Type } from "class-transformer";
import {
  IsBoolean,
  IsDefined,
  IsNumber,
  IsString,
  ValidateNested,
} from "class-validator";
import { Role } from "./role.entity";
export class User {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  email!: string;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string;

  @Expose()
  @IsDefined()
  @IsBoolean()
  firstLogin!: boolean;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type((type) => Role)
  role!: Role;
}
