import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { GetAllAdminDTO } from "../../constants/dto/admin";
import {
  setAdminActionStart,
  setAdminErrorMessage,
  setAdmins,
} from "../../constants/reducers/admin.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

let body: GetAllAdminDTO;

export const getAllAdmin = async () => {
  store.dispatch(setAdminActionStart(true));
  const { searchText, page } = store.getState().admin;
  const queryParams = new URLSearchParams();
  if (searchText) queryParams.append("searchText", searchText.trim());
  if (page) queryParams.append("page", page.toString());
  const request = await createCall(
    "cms/admins",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetAllAdminDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setAdminErrorMessage(TextEnum.GET_ALL_ADMIN_INVALID_RESPONSE)
      );
      return null;
    }
    store.dispatch(
      setAdmins({
        admins: response.data.admins,
        pageCount: response.data.pageCount,
      })
    );
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 1009:
          errorMessage = TextEnum.GET_ALL_ADMIN_ERROR_IN_SENT_RESPONSE;
          break;
        default:
          break;
      }
      store.dispatch(setAdminErrorMessage(errorMessage));
      return null;
    }
  }

  store.dispatch(setAdminActionStart(false));
  store.dispatch(timeoutError());
  return null;
};
