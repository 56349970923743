import { useCallback } from "react";
import { useAppNavigate, useAppParams } from "../global-hooks";
import { getLeaderboardAction } from "../../actions/leaderboard";
import {
  leaderboardAllScoreOrder,
  leaderboardChildOccasionOrder,
  leaderboardErrorMessage,
  leaderboardInviteSentOrder,
  leaderboardMixedOccasionOrder,
  leaderboardOccasionOrder,
  leaderboardScoreOrder,
  setLeaderboardPage,
  setLeaderboardSearchText,
} from "../reducers/leaderboard.reducer";

export const useHandleLeaderboardNavigateBack = () => {
  const navigate = useAppNavigate();
  const handleNavigateBack = useCallback(() => {
    navigate("/campaign");
  }, [navigate]);
  return handleNavigateBack;
};

export const useHandleLeaderboardNavigateBackSecondPage = () => {
  const navigate = useAppNavigate();
  const params = useAppParams();
  const handleNavigateBack = useCallback(() => {
    navigate(`/campaign/modify/${params.id}`);
  }, [navigate, params.id]);
  return handleNavigateBack;
};

export const useHandleLeaderboardSearchTextChange = (dispatch: Function) => {
  const handleSearchChange = useCallback(
    (value: string) => {
      dispatch(setLeaderboardSearchText(value));
    },
    [dispatch]
  );
  return handleSearchChange;
};

export const useHandleLeaderboardPageChange = (dispatch: Function) => {
  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setLeaderboardPage(value));
    },
    [dispatch]
  );
  return handlePageChange;
};

export const useHandleGetLeaderboard = () => {
  const params = useAppParams();
  const getLeaderboard = useCallback(async () => {
    if (params.id) {
      await getLeaderboardAction({ id: params.id });
    }
  }, [params.id]);
  return getLeaderboard;
};

export const useHandleLeaderboardAllScoreChange = (dispatch: Function) => {
  const handleAllScoreOrderChange = useCallback(
    (value: string) => {
      dispatch(leaderboardAllScoreOrder(value));
    },
    [dispatch]
  );
  return handleAllScoreOrderChange;
};

export const useHandleLeaderboardScoreChange = (dispatch: Function) => {
  const handleScoreOrderChange = useCallback(
    (value: string) => {
      dispatch(leaderboardScoreOrder(value));
    },
    [dispatch]
  );
  return handleScoreOrderChange;
};

export const useHandleLeaderboardChildOccasionOrderChange = (
  dispatch: Function
) => {
  const handleChildOccasionOrderChange = useCallback(
    (value: string) => {
      dispatch(leaderboardChildOccasionOrder(value));
    },
    [dispatch]
  );
  return handleChildOccasionOrderChange;
};

export const useHandleLeaderboardMixedOccasionOrderChange = (
  dispatch: Function
) => {
  const handleMixedOccasionOrderChange = useCallback(
    (value: string) => {
      dispatch(leaderboardMixedOccasionOrder(value));
    },
    [dispatch]
  );
  return handleMixedOccasionOrderChange;
};

export const useHandleLeaderboardOccasionOrderChange = (dispatch: Function) => {
  const handleOccasionOrderChange = useCallback(
    (value: string) => {
      dispatch(leaderboardOccasionOrder(value));
    },
    [dispatch]
  );
  return handleOccasionOrderChange;
};

export const useHandleLeaderboardInviteSentOrderChange = (
  dispatch: Function
) => {
  const handleInviteSentOrderChange = useCallback(
    (value: string) => {
      dispatch(leaderboardInviteSentOrder(value));
    },
    [dispatch]
  );
  return handleInviteSentOrderChange;
};

export const useHandleSetAlertMessage = (dispatch: Function) => {
  const handleSetAlertMessage = useCallback(() => {
    dispatch(leaderboardErrorMessage(""));
  }, [dispatch]);
  return handleSetAlertMessage;
};
