import React, { useEffect, useState } from "react";
import { TextBox } from "../input";
import { TextEnum } from "../../text";
import { SwitchButton } from "../buttons";

import "./version-modal.scss";

interface VersionModalProps {
  setModalVisible: (modalVisible: boolean) => void;
  version: string;
  setVersion: (value: string) => void;
  platform: number;
  setPlatform: (value: boolean) => void;
  critical: boolean;
  setCritical: (value: boolean) => void;
  handleSave: () => void;
  error: null | string;
}

export const VersionModal: React.FC<VersionModalProps> = ({
  setModalVisible,
  version,
  setVersion,
  platform,
  setPlatform,
  critical,
  setCritical,
  handleSave,
  error,
}) => {
  const [decider, setDecider] = useState(true);
  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleOK = () => {
    handleSave();
    if (!error) {
      setModalVisible(false);
    }
  };

  const handleChangeActualPlatform = (value: boolean) => {
    setPlatform(value);
  };

  const handleChangeActualCritical = () => {
    setCritical(!critical);
  };

  useEffect(() => {
    setDecider(true);
    if (version) {
      setDecider(false);
    }
  }, [platform, version, critical]);

  return (
    <div className="custom-modal">
      <div className="modal">
        <div className="custom-modal-header">
          <h2>Versions</h2>
        </div>
        <div className="custom-modal-body">
          <TextBox
            value={version}
            placeholder={TextEnum.VERSION}
            onChange={setVersion}
            type="input"
            customErrorMessage=""
            setDecider={setDecider}
          />
          <SwitchButton
            handleChangeActual={handleChangeActualPlatform}
            decider={platform === 1}
            mainButton={TextEnum.ANDROID}
            sideButton={TextEnum.IOS}
          />
          <SwitchButton
            handleChangeActual={handleChangeActualCritical}
            decider={critical}
            mainButton={TextEnum.NOT_CRITICAL}
            sideButton={TextEnum.CRITICAL}
          />
        </div>
        <div className="custom-modal-footer">
          <button onClick={handleCancel}>{TextEnum.CANCEL_BUTTON}</button>
          <button
            onClick={handleOK}
            disabled={decider}
            className={`${decider ? "disabled" : ""}`}
          >
            {TextEnum.SAVE_BUTTON}
          </button>
        </div>
      </div>
    </div>
  );
};
