import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import {
  addCompanyToCompanies,
  setCompanyActionStart,
  setCompanyErrorMessage,
} from "../../constants/reducers/company.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { AxiosResponse } from "axios";
import { CreateCompanyDTO } from "../../constants/dto/company";

let body: CreateCompanyDTO;

export const createCompany = async () => {
  store.dispatch(setCompanyActionStart(true));
  const { name, taxNumber, email } = store.getState().company;
  const params = {
    name,
    taxNumber,
    email,
  };
  const request = await createCall(
    `cms/companies/create`,
    BASE_METHODS.POST,
    "application/json",
    "1.0.0",
    params,
    undefined
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(CreateCompanyDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setCompanyErrorMessage(TextEnum.CREATE_COMPANY_INVALID_RESPONSE)
      );
      return;
    }
    store.dispatch(addCompanyToCompanies(response.data.company));
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 2002:
          errorMessage = TextEnum.CREATE_COMPANY_ALREADY_EXIST;
          break;
        case 2005:
          errorMessage = TextEnum.CREATE_COMPANY_INVALID_COMPANY_ADDRESS;
          break;
        default:
          break;
      }
      store.dispatch(setCompanyErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setCompanyActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
