import { useState, useEffect } from "react";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { MessageModal } from "../../constants/features/modal";
import { SwitchButton } from "../../constants/features/buttons";
import { ImageUploader } from "../../constants/features/picture";
import { NumberInput, TextBox } from "../../constants/features/input";
import { CustomBreadCrumb } from "../../constants/features/breadcrumb";
import { CustomDatePicker } from "../../constants/features/date-picker";
import { CustomCompanySelector } from "../../constants/features/selector";
import {
  resetCampaign,
  setCampaignChildScore,
  setCampaignInviteSent,
  setCampaignMixedScore,
  setCampaignOccasion,
  setCampaignScore,
  setCampaignType,
} from "../../constants/reducers/campaign.reducer";
import {
  useHandleCampaignChildScoreChange,
  useHandleCampaignDeciderChange,
  useHandleCampaignInviteSentChange,
  useHandleCampaignMixedScoreChange,
  useHandleCampaignOccasionChange,
  useHandleCampaignPrivativityChange,
  useHandleCampaignScoreChange,
  useHandleCampaignSubtitleChange,
  useHandleCampaignTypeChange
} from "../../constants/utils/campaign-utils";
import {
  createCampaign,
  getCampaign,
  modifyCampaign,
} from "../../actions/campaign";
import {
  useAppDispatch,
  useAppNavigate,
  useAppParams,
  useAppSelector,
} from "../../constants/global-hooks";
import {
  useHandleCampaignAlertMessage,
  useHandleCampaignDescriptionChange,
  useHandleCampaignNameChange,
  useHandleCampaignNavigateBack,
  useHandleCampaignPictureChange,
  useHandleCampaignPictureUrlChange,
  useHandleCampaignValidFromChange,
  useHandleCampaignValidToChange,
} from "../../constants/utils";

import "./create-campaign.scss";
import { CustomCheckbox } from "../../constants/features/checkbox";
import { resetLeaderboard } from "../../constants/reducers/leaderboard.reducer";

export const CreateCampaignPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const params = useAppParams();
  const {
    picture,
    name,
    description,
    validFrom,
    validTo,
    campaign,
    errorMessage,
    campaignActionStart,
    pictureUrl,
    privateCampaign,
    inviteSent,
    subtitle,
    score,
    occasion,
    mixedScore,
    childScore,
    campaignType,
    selectedId,
  } = useAppSelector((state: RootState) => state.campaign);

  const [state, setState] = useState({
    clicked: false,
    decider: true,
    scoreCheckBox: false,
    occasionCheckBox: false,
    mixedCheckBox: false,
    childCheckBox: false,
    inviteSentCheckBox: false,
  });

  const handleNavigateBack = useHandleCampaignNavigateBack(dispatch);
  const handleAlertMessage = useHandleCampaignAlertMessage(dispatch);
  const handleScoreChange = useHandleCampaignScoreChange(dispatch);
  const handleOccasionChange = useHandleCampaignOccasionChange(dispatch);
  const handleMixedChange = useHandleCampaignMixedScoreChange(dispatch);
  const handleChildChange = useHandleCampaignChildScoreChange(dispatch);
  const handleInviteSent = useHandleCampaignInviteSentChange(dispatch);
  const handleTypeChange = useHandleCampaignTypeChange(dispatch);
  const getCampaignFromProps = async (id: number) => {
    await getCampaign({ id });
  };

  useEffect(() => {
    dispatch(resetCampaign());
    if (params.id) {
      dispatch(resetLeaderboard());
      getCampaignFromProps(parseInt(params.id));
    }
  }, [dispatch, params]);

  const handleSave = async () => {
    if (new Date(validTo) < new Date(new Date().setHours(0, 0, 0, 0))) {
      navigate(`leaderboard`);
    } else {
      if (campaign) {
        await modifyCampaign();
      } else {
        await createCampaign();
      }
      setState((prevState) => ({ ...prevState, clicked: true }));
    }
  };

  useEffect(() => {
    if (state.clicked && errorMessage === null && !campaignActionStart) {
      handleNavigateBack();
    }
    setState((prevState) => ({ ...prevState, clicked: false }));
  }, [handleNavigateBack, state.clicked, errorMessage, campaignActionStart]);

  useEffect(() => {
    if (campaign && params.id) {
      setState((prevState) => ({ ...prevState, modify: true }));
    }
  }, [campaign, params.id]);

  const handleScoreCheckboxChange = (value: boolean) => {
    setState((prevState) => ({ ...prevState, scoreCheckBox: value }));
    if (value === false) {
      dispatch(setCampaignScore(null!));
    }
  };

  const handleOccasionCheckboxChange = (value: boolean) => {
    setState((prevState) => ({ ...prevState, occasionCheckBox: value }));
    if (value === false) {
      dispatch(setCampaignOccasion(null!));
    }
  };

  const handleMixedCheckboxChange = (value: boolean) => {
    setState((prevState) => ({ ...prevState, mixedCheckBox: value }));
    if (value === false) {
      dispatch(setCampaignMixedScore(null!));
    }
  };

  const handleChildCheckboxChange = (value: boolean) => {
    setState((prevState) => ({ ...prevState, childCheckBox: value }));
    if (value === false) {
      dispatch(setCampaignChildScore(null!));
    }
  };

  const handleinviteSentCheckboxChange = (value: boolean) => {
    setState((prevState) => ({ ...prevState, inviteSentCheckBox: value }));
    if (value === false) {
      dispatch(setCampaignInviteSent(null!));
    }
  };

  useEffect(() => {
    if (score !== null) {
      setState((prevState) => ({ ...prevState, scoreCheckBox: true }));
    }
    if (occasion !== null) {
      setState((prevState) => ({ ...prevState, occasionCheckBox: true }));
    }
    if (mixedScore !== null) {
      setState((prevState) => ({ ...prevState, mixedCheckBox: true }));
    }
    if (childScore !== null) {
      setState((prevState) => ({ ...prevState, childCheckBox: true }));
    }
    if (inviteSent !== null) {
      setState((prevState) => ({ ...prevState, inviteSentCheckBox: true }));
    }
  }, [score, occasion, mixedScore, childScore, inviteSent]);

  useEffect(() => {
    if (privateCampaign) {
      dispatch(setCampaignType(true));
    }
  }, [privateCampaign, dispatch]);

  return (
    <main className="main-page">
      <div className="title">
        {campaign ? TextEnum.MODIFY : TextEnum.CREATION_MODAL_TITLE}
      </div>
      <CustomBreadCrumb
        title={TextEnum.CAMPAIGNS_PAGE_TITLE}
        onClick={handleNavigateBack}
        modifyTitle={TextEnum.MODIFY_CAMPAIGN_PAGE_TITLE}
        createTitle={TextEnum.CREATE_CAMPAIGN_PAGE_TITLE}
        modify={campaign ? true : false}
      />

      <div className="container">
        <div className="inputContainer">
          <div className="switch-buttons">
            <SwitchButton
              mainButton={TextEnum.PUBLIC}
              sideButton={TextEnum.PRIVATE}
              handleChangeActual={useHandleCampaignPrivativityChange(dispatch)}
              decider={privateCampaign}
              disabledFrom={validTo}
            />
          </div>
        </div>
        <ImageUploader
          type={2}
          picture={picture}
          pictureUrl={pictureUrl}
          setPictureUrl={useHandleCampaignPictureUrlChange(dispatch)}
          setPicture={useHandleCampaignPictureChange(dispatch)}
          isCircular={false}
          disabled={new Date(validTo).getTime() <= new Date().getTime()}
        />
        {!privateCampaign && (
          <div className="inputContainer">
            <div className="switch-buttons">
              <SwitchButton
                mainButton={TextEnum.SOLO}
                sideButton={TextEnum.TEAM}
                handleChangeActual={handleTypeChange}
                decider={campaignType === 2}
                disabledFrom={validTo}
              />
            </div>
          </div>
        )}

        <div className="inputContainer">
          <TextBox
            onChange={useHandleCampaignNameChange(dispatch)}
            value={name}
            type="input"
            customErrorMessage={TextEnum.NAME_REQUIRED_ERROR_MESSAGE}
            setDecider={useHandleCampaignDeciderChange(setState)}
            placeholder={TextEnum.CAMPAIGN_TITLE_NAME}
            disabled={new Date(validTo).getTime() <= new Date().getTime()}
          />
        </div>
        <div className="inputContainer">
          <TextBox
            onChange={useHandleCampaignSubtitleChange(dispatch)}
            value={subtitle}
            type="input"
            customErrorMessage={TextEnum.SUBTITLE_REQUIRED_ERROR_MESSAGE}
            setDecider={useHandleCampaignDeciderChange(setState)}
            placeholder={TextEnum.CAMPAIGN_SUBTITLE_NAME}
            disabled={new Date(validTo).getTime() <= new Date().getTime()}
          />
        </div>
        <div className="inputContainer">
          <TextBox
            onChange={useHandleCampaignDescriptionChange(dispatch)}
            value={description}
            type="textbox"
            customErrorMessage={TextEnum.DESCRIPTION_REQUIRED_ERROR_MESSAGE}
            setDecider={useHandleCampaignDeciderChange(setState)}
            placeholder={TextEnum.CAMPAIGN_TITLE_DESCRIPTION}
            disabled={new Date(validTo).getTime() <= new Date().getTime()}
          />
        </div>
        <div className="tooltip">
          <CustomCompanySelector />
          <Tooltip
            title={
              !privateCampaign && campaignType === 1
                ? TextEnum.PUBLIC_SOLO_CAMPAIGN
                : !privateCampaign && campaignType === 2
                  ? TextEnum.PUBLIC_TEAM_CAMPAIGN
                  : TextEnum.PRIVATE_TEAM_CAMPAIGN
            }
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>

        <div className="inputContainer">
          <div className="range-picker-container">
            <CustomDatePicker
              validDate={validFrom}
              placeholder={
                new Date(validFrom) >= new Date(new Date().setHours(0, 0, 0, 0))
                  ? ""
                  : !validFrom
                    ? TextEnum.FROM
                    : validFrom
              }
              onChange={useHandleCampaignValidFromChange(dispatch)}
              disabledFrom={validTo}
            />
            <CustomDatePicker
              validDate={validTo}
              placeholder={
                new Date(validTo) >= new Date(new Date().setHours(0, 0, 0, 0))
                  ? ""
                  : !validTo
                    ? TextEnum.TO
                    : validTo
              }
              onChange={useHandleCampaignValidToChange(dispatch)}
              compareDate={validFrom}
              disabledFrom={validTo}
            />
          </div>
        </div>

        <div className="checkbox-container">
          <div className="custom-checkbox">
            <CustomCheckbox
              onChange={handleScoreCheckboxChange}
              label={TextEnum.CAMPAIGN_SCORE_CHECKBOX_TITLE}
              checked={state.scoreCheckBox}
              disabledFrom={validTo}
            />
          </div>
          {state.scoreCheckBox && (
            <div className="number-input-container">
              <NumberInput
                onChange={handleScoreChange}
                value={score}
                placeholder=""
                disabledFrom={validTo}
              />
            </div>
          )}
        </div>

        <div className="checkbox-container">
          <div className="custom-checkbox">
            <CustomCheckbox
              onChange={handleOccasionCheckboxChange}
              label={TextEnum.CAMPAIGN_OCCASION_CHECKBOX_TITLE}
              checked={state.occasionCheckBox}
              disabledFrom={validTo}
            />
          </div>
          {state.occasionCheckBox && (
            <div className="number-input-container">
              <NumberInput
                onChange={handleOccasionChange}
                value={occasion}
                placeholder=""
                disabledFrom={validTo}
              />
            </div>
          )}
        </div>

        <div className="checkbox-container">
          <div className="custom-checkbox">
            <CustomCheckbox
              onChange={handleinviteSentCheckboxChange}
              label={TextEnum.CAMPAIGN_INVITE_SENT_CHECKBOX_TITLE}
              checked={state.inviteSentCheckBox}
              disabledFrom={validTo}
            />
          </div>
          {state.inviteSentCheckBox && (
            <div className="number-input-container">
              <NumberInput
                onChange={handleInviteSent}
                value={inviteSent}
                placeholder=""
                disabledFrom={validTo}
              />
            </div>
          )}
        </div>
        <button
          disabled={
            new Date(validTo) < new Date(new Date().setHours(0, 0, 0, 0))
              ? false
              : campaignActionStart ||
              !picture ||
              name.length === 0 ||
              description.length === 0 ||
              !validFrom ||
              !validTo ||
              (privateCampaign && !selectedId) ||
              (!state.occasionCheckBox &&
                !state.scoreCheckBox &&
                !state.mixedCheckBox &&
                !state.childCheckBox &&
                !state.inviteSentCheckBox) ||
              (state.occasionCheckBox && !occasion) ||
              (state.scoreCheckBox && !score) ||
              (state.mixedCheckBox && !mixedScore) ||
              (state.childCheckBox && !childScore) ||
              (state.inviteSentCheckBox && !inviteSent)
          }
          className={`create-campaign-button ${new Date(validTo) < new Date(new Date().setHours(0, 0, 0, 0))
            ? "outdated"
            : campaignActionStart ||
              !picture ||
              name.length === 0 ||
              description.length === 0 ||
              !validFrom ||
              !validTo ||
              (privateCampaign && !selectedId) ||
              (!state.occasionCheckBox &&
                !state.scoreCheckBox &&
                !state.mixedCheckBox &&
                !state.childCheckBox &&
                !state.inviteSentCheckBox) ||
              (state.occasionCheckBox && !occasion) ||
              (state.scoreCheckBox && !score) ||
              (state.mixedCheckBox && !mixedScore) ||
              (state.childCheckBox && !childScore) ||
              (state.inviteSentCheckBox && !inviteSent)
              ? "disabled"
              : ""
            }`}
          onClick={handleSave}
        >
          {campaignActionStart ? (
            <LoadingOutlined />
          ) : new Date(validTo) < new Date(new Date().setHours(0, 0, 0, 0)) ? (
            TextEnum.SHOW_LEADERBOARD
          ) : (
            TextEnum.SAVE_BUTTON
          )}
        </button>
      </div>

      {errorMessage && (
        <MessageModal
          title=""
          message={errorMessage}
          onCloseClick={handleAlertMessage}
        />
      )}
    </main>
  );
};
