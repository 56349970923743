import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Badge } from "../entity";
import { BadgeState } from "../state";

const initialState: BadgeState = {
  badges: [],
  badge: null!,
  name: "",
  picture: null!,
  pictureUrl: "",
  type: null!,
  errorMessage: null,
  badgeActionStart: false,
  description: "",
  active: false,
};

export const badgeSlice = createSlice({
  name: "badge",
  initialState,
  reducers: {
    setBadges: (state, action: PayloadAction<Badge[]>) => {
      state.badges = action.payload;
      state.errorMessage = null!;
      state.badgeActionStart = false;
    },

    setBadge: (state, action: PayloadAction<Badge>) => {
      state.badge = action.payload;
      state.name = action.payload.name;
      state.description = action.payload.description;
      state.picture = action.payload.picture;
      state.pictureUrl = action.payload.url;
      state.type = action.payload.type;
      state.active = action.payload.active;
      state.errorMessage = null!;
      state.badgeActionStart = false;
    },

    setBadgeName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },

    setBadgeDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },

    setBadgePicture: (state, action: PayloadAction<any | null>) => {
      state.picture = action.payload;
    },

    setBadgePictureUrl: (state, action: PayloadAction<string>) => {
      state.pictureUrl = action.payload;
    },

    setBadgeType: (state, action: PayloadAction<number>) => {
      state.type = action.payload;
    },
    setBadgeActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },

    addBadgeToBadges: (state, action: PayloadAction<Badge>) => {
      state.badges = [...state.badges, action.payload];
      state.errorMessage = null!;
      state.badgeActionStart = false;
    },

    setBadgeErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.badgeActionStart = false;
    },

    resetBadge: (state) => {
      state.badge = null!;
      state.name = "";
      state.description = "";
      state.picture = null;
      state.pictureUrl = "";
      state.type = null;
      state.active = false;
    },

    setBadgeActionStart: (state, action: PayloadAction<boolean>) => {
      state.badgeActionStart = action.payload;
    },
  },
});

export const {
  setBadges,
  setBadge,
  setBadgeName,
  setBadgeDescription,
  setBadgePicture,
  setBadgePictureUrl,
  setBadgeType,
  addBadgeToBadges,
  resetBadge,
  setBadgeErrorMessage,
  setBadgeActionStart,
  setBadgeActive,
} = badgeSlice.actions;
