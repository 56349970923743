import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ForgotPasswordState } from "../state";
import { ForgotPasswordConfirmDTO } from "../dto/forgot-password";

const initialState: ForgotPasswordState = {
  email: "",
  password: "",
  passwordCheck: "",
  errorMessage: null,
  isTokenValid: null!,
  token: null,
  forgotPasswordActionStart: false,
};

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setPasswordCheck: (state, action: PayloadAction<string>) => {
      state.passwordCheck = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload;
      state.forgotPasswordActionStart = false;
    },
    setIsTokenValid: (
      state,
      action: PayloadAction<ForgotPasswordConfirmDTO>
    ) => {
      state.isTokenValid = action.payload;
      state.errorMessage = null;
      state.forgotPasswordActionStart = false;
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      state.forgotPasswordActionStart = false;
    },
    resetForgotPassword: (state) => {
      state.email = "";
      state.password = "";
      state.passwordCheck = "";
      state.token = null;
    },
    setForgotPasswordActionStart: (state, action: PayloadAction<boolean>) => {
      state.forgotPasswordActionStart = action.payload;
    },
  },
});

export const {
  setEmail,
  setPassword,
  setPasswordCheck,
  setErrorMessage,
  setIsTokenValid,
  setToken,
  resetForgotPassword,
  setForgotPasswordActionStart,
} = forgotPasswordSlice.actions;
