import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { getAllVersions } from "../../actions/version";
import { resetVersion } from "../reducers/version.reducer";

export const versionLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetVersion());

  return await getAllVersions();
};
