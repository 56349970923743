export enum TextEnum {
  LOGGED_IN_AS = "Bejelentkezve, mint ",
  EMAIL_TEXT = "E-mail cím",
  PASSWORD_PLACEHOLDER = "***************",
  PASSWORD_TEXT = "Jelszó",
  SEARCH = "Keresés",
  LOGIN_TEXT = "Bejelentkezés",
  UNAUTHORIZED_ERROR_TEXT = "Nem megfelelő email - jelszó páros",
  SERVER_TIMEOUT_ERROR_TEXT = "A szerver jelenleg nem elérhető!\nKérlek próbáld meg később!",
  LOGIN_UNEXPECTED_RESPONSE = "Nem megfelelő válasz a szervertől.",
  LOGIN_INVALID_JSON = "Nem megfelelő válasz a szervertől.",
  LOGIN_INVALID_RESPONSE = "Nem megfelelő válasz a szervertől.",
  LOGIN_TOO_MANY_ATTEMPTS = "Túl sok hibás próbálkozás, hátralevő idő: ",
  FORGOT_PASSWORD = "Elfelejtetted a jelszót?",
  FORGOT_PASSWORD_BUTTON = "Jelszóemlékeztető",
  FORGOT_PASSWORD_MODAL_MESSAGE = "A jelszóemlékeztetőt elküldtük, kérlek nézd meg a postaládádat.",
  FORGOT_PASSWORD_CONFIRM_MODAL_MESSAGE = "A jelszavad sikeresen megváltozott!",
  NO_RESPONSE_FROM_SERVER = "Nem érkezett feldolgozható válasz a szervertől.",
  LOGOUT = "Kijelentkezés",
  PAGE_NOT_FOUND_TITLE = "Oops! Az oldal nem található",
  PAGE_NOT_FOUND_SUBTITLE = "Az oldal amit keresel lehet, hogy nem létezik, megváltoztatták az elérését, elköltözött, vagy nem elérhető.",
  PAGE_NOT_FOUND_GO_BACK_TO_HOMEPAGE = "Ide kattintva visszatérhetsz a főoldalra.",
  CAMPAIGN_TITLE_NAME = "Cím",
  CAMPAIGN_SUBTITLE_NAME = "Alcím",
  CAMPAIGN_TITLE_DESCRIPTION = "Leírás",
  CAMPAIGN_TITLE_VALID_FROM = "Kezdő dátum",
  CAMPAIGN_TITLE_VALID_TO = "Vég dátum",
  PAGINATOR_PREV = "Előző",
  PAGINATOR_NEXT = "Következő",
  CREATION_BUTTON = "+",
  CREATION_MODAL_TITLE = "Létrehozás",
  SAVE_BUTTON = "Mentés",
  CANCEL_BUTTON = "Mégsem",
  PICTURE_UPLOADER = "Képfeltöltő",
  PICTURE_UPLOADER_DESCRIPTION = "Húzd ide a képet vagy kattints a feltöltéshez.",
  MODIFY = "Módosítás",
  DELETE = "Törlés",
  CAMPAIGNS_PAGE_TITLE = "Kihívások",
  NO_DATA = "Nincs megjeleníthető elem.",
  CAMPAIGN_DELETE_POPUP_CONFIRM = "Biztosan törölni szeretnéd ezt a kihívást?",
  YES = "Igen",
  NO = "Nem",
  UPLOAD = "Feltöltés",
  CREATE_CAMPAIGN_PAGE_TITLE = "Új kihívás létrehozása",
  MODIFY_CAMPAIGN_PAGE_TITLE = "Kihívás módosítása",
  ACTUAL_BUTTON_LABEL = "Aktuális",
  ARCHIVED_BUTTON_LABEL = "Archivált",
  DELETED_BUTTON_LABEL = "Törölt",
  RESTORE = "Visszaállítás",
  CAMPAIGN_RESTORE_POPUP_CONFIRM = "Biztosan vissza szeretnéd állítani ezt a kihívást?",
  COMPANY_ZIP_CODE = "Irányítószám",
  COMPANY_CITY = "Város",
  COMPANY_NAME = "Cégnév",
  COMPANY_STREET = "Utca",
  COMPANY_HOUSE_NUMBER = "Házszám",
  COMPANY_TAX_NUMBER = "Adószám",
  COMPANY_CODE = "Kód",
  CREATE_COMPANY_PAGE_TITLE = "Cég létrehozása",
  MODIFY_COMPANY_PAGE_TITLE = "Cég módosítása",
  SEARCH_CAMPAIGN_NAME = "Keress címet",
  SEARCH_CAMPAIGN_DESCRIPTION = "Keress leírást",
  SEARCH_COMPANY_NAME = "Keress cégnevet",
  SEARCH_COMPANY_CITY = "Keress várost",
  SEARCH_COMPANY_ZIP_CODE = "Keress irányítószámot",
  SEARCH_COMPANY_STREET = "Keress utcát",
  COMPANY_DELETE_POPUP_CONFIRM = "Biztosan törölni szeretnéd ezt a céget?",
  COMPANY_PAGE_TITLE = "Cégek",
  SELECT_COMPANY_PLACEHOLDER = "Válassz egy céget",
  NONE_OF_THEM = "Egyik sem",
  BADGE_PAGE_TITLE = "Jelvények",
  CREATE_BADGE_PAGE_TITLE = "Jelvény létrehozása",
  MODIFY_BADGE_PAGE_TITLE = "Jelvény módosítása",
  BADGE_NAME_TITLE = "Jelvény neve",
  BADGE_DESCRIPTION_TITLE = "Jelvény leírása",
  FourDaysInARow = "Mert 4 napig kerékpárral mentél dolgozni.",
  EightDaysInARow = "Mert 8 napig kerékpárral mentél dolgozni.",
  TwelveDaysInARow = "Mert 12 napig kerékpárral mentél dolgozni.",
  SixteenDaysInARow = " Mert 16 napig kerékpárral mentél dolgozni.",
  TwentyOneDaysInARow = "Mert 21 napig kerékpárral mentél dolgozni.",
  OneCupon = "Mert 1 alkalommal támogattad az ügyünket.",
  ThreeCupons = "Mert 3 alkalommal támogattad az ügyünket.",
  FiveCupons = "Mert 5 alkalommal támogattad az ügyünket.",
  NAME = "Név",
  EMAIL = "Email cím",
  TEAM_LEADER = "Csapatkapitány",
  SEARCH_NAME = "Keress nevet",
  SEARCH_EMAIL = "Keress email címet",
  ADMIN_PAGE_TITLE = "Adminok",
  ADMIN_INVITE_TITLE = "Admin meghívása",
  ADMIN_INVITE_PAGE_TITLE = "Meghívás",
  ADMIN_INVITE_BUTTON_TITLE = "Meghívás küldése",
  PROFILE_PAGE_TITLE = "Profil",
  PROFILE_PASSWORD_CHECK = "Jelszó megerősítés",
  PROFILE_SUCCESS_MESSAGE = "Adataid sikeresen módosultak!",
  ADMIN_DELETE_POPUP_CONFIRM = "Biztosan törölni szeretnéd ezt a kollégát?",
  ERROR = "Hiba",
  INVALID_PARAMETERS = "Nem megfelelő paraméterek!",
  NO_RIGHTS = "Nincs jogosultságod ehhez a művelethez!",
  SESSION_EXPIRED = "A munkamenet lejárt. Kérlek jelentkezz be újra!",
  INVALID_EMAIL_PASSWORD = "Hibás email - jelszó páros!",
  CREATE_ADMIN_INVALID_RESPONSE = "A meghívás sikertelen volt, szerverhiba miatt.",
  USER_WITH_THIS_EMAIL_ALREADY_EXIST = "A felhasználó már létezik ezzel az email címmel.",
  DELETE_ADMIN_INVALID_RESPONSE = "A törlés sikertelen volt, szerverhiba miatt.",
  DELETE_ADMIN_USER_NOT_FOUND = "Hiba a törlés közben, a felhasználó nem található!",
  LOGIN_DELETED_USER_ERROR = "A fiókod törölve lett.",
  DELETE_ADMIN_CANNOT_DELETE_YOURSELF = "Hiba a törlés közben, nem törölheted magad mint admin!",
  DELETE_ADMIN_CANNOT_DELETE_NON_ADMIN_USERS = "Hiba a törlés közben, nem törölhetsz nem admin felhasználókat!",
  GET_ALL_ADMIN_INVALID_RESPONSE = "Az adminok betöltése sikertelen volt, szerverhiba miatt.",
  GET_ALL_ADMIN_ERROR_IN_SENT_RESPONSE = "Szerverhiba: hiba a küldött admin adatokban.",
  MODIFY_ADMIN_INVALID_RESPONSE = "A módosítás siketerlen volt, szerverhiba miatt.",
  MODIFY_ADMIN_USER_NOT_FOUND = "Hiba a módosítás közben, a felhasználó nem található.",
  CREATE_BADGE_INVALID_RESPONSE = "Hiba a jelvény lértehozása közben, szerverhiba miatt.",
  CREATE_BADGE_FILE_NOT_FOUND = "Hiba a jelvény mentése közben, a file nem található.",
  CREATE_BADGE_MAX_NUMBER_REACHED = "Hiba a jelvény mentése közben, elérted a maximális jelvények mennyiséget (8db).",
  CREATE_BADGE_THIS_KIND_OF_BADGE_ALREADY_EXIST = "Hiba a jelvény mentése közben, ez a típusú jelvény már létezik.",
  GET_ALL_BADGE_INVALID_RESPONSE = "A jelvények betöltése sikertelen volt, szerverhiba miatt.",
  GET_ONE_BADGE_INVALID_RESPONSE = "A jelvény betöltése sikertelen volt, szerverhiba miatt.",
  GET_ONE_BADGE_BADGE_NOT_FOUND = "A jelvény nem található.",
  MODIFY_BADGE_INVALID_RESPONSE = "A jelvény módosítása sikertelen volt, szerverhiba miatt.",
  CREATE_CAMPAIGN_INVALID_RESPONSE = "A kihívás létrehozása sikertelen volt, szerverhiba miatt.",
  CREATE_CAMPAIGN_FILE_NOT_FOUND = "Hiba a kihívás létrehozása közben, a kép nem található.",
  CREATE_CAMPAIGN_COMPANY_NOT_FOUND = "Hiba a kihívás létrehozása közben, a cég nem található.",
  CREATE_CAMPAIGN_CAMPAIGN_ALREADY_EXIST = "Hiba a kihívás létrehozása közben, ezen a néven már létezik kihívás!\nKérlek nézd meg a törölt kihívások között is.",
  CREATE_CAMPAIGN_INVALID_DATE = "Hiba a kihívás létrehozása közben, a végdátumnak nagyobbnak kell lennie a kezdődátumnál.",
  CREATE_CAMPAIGN_CAMPAIGN_NOT_FOUND = "Hiba a kihívás létrehozása közben, a ranglistát nem sikerült létrehozni.",
  DELETE_CAMPAIGN_INVALID_RESPONSE = "A kihívás törlése sikertelen volt, szerverhiba miatt.",
  DELETE_CAMPAIGN_CAMPAIGN_NOT_FOUND = "Hiba a kihívás törlése közben, a kihívás nem található.",
  DELETE_CAMPAIGN_FILE_NOT_FOUND = "Hiba a kihívás törlése közben, a kihívás képe nem található.",
  GET_ALL_CAMPAIGN_INVALID_RESPONSE = "A kihívások betöltése sikertelen volt, szerverhiba miatt.",
  GET_ALL_CAMPAIGN_ERROR_IN_SENT_RESPONSE = "Szerverhiba: hiba a küldött kihívás adatokban.",
  GET_ONE_CAMPAIGN_INVALID_RESPONSE = "Hiba a kihívás betöltése közben, szerverhiba miatt.",
  GET_ONE_CAMPAIGN_CAMPAIGN_NOT_FOUND = "Kiba a kihívás betöltése közben, a kihívás nem található.",
  MODIFY_CAMPAIGN_INVALID_RESPONSE = "Hiba a kihívás módosítása közben, szerverhiba miatt.",
  MODIFY_CAMPAIGN_CAMPAIGN_NOT_FOUND = "Hiba a kihívás módosítása közben, a kihívás nem található.",
  MODIFY_CAMPAIGN_FILE_NOT_FOUND = "Hiba a kihívás módosítása közben, a kihívás képe nem található.",
  RESTORE_CAMPAIGN_INVALID_RESPONSE = "Hiba a kihívás visszaállítása közben, szerverhiba miatt.",
  RESTORE_CAMPAIGN_CAMPAIGN_NOT_FOUND = "Hiba a kihívás visszaállítása közben, a kihívás nem található.",
  RESTORE_CAMPAIGN_EXPIRED_CAMPAIGN = "Hiba a kihívás visszaállítása közben, a kihívás lejárt.",
  DELETE_COMPANY_INVALID_RESPONSE = "Hiba a cég törlése közben, szerverhiba miatt.",
  DELETE_COMPANY_COMPANY_NOT_FOUND = "Hiba a cég törlése közben, a cég nem található.",
  GET_ALL_COMPANY_INVALID_RESPONSE = "Hiba a cégek betöltése közben, szerverhiba miatt.",
  GET_ALL_COMPANY_ERROR_IN_SENT_RESPONSE = "Szerverhiba: hiba a küldött cégadatokban, ",
  GET_ONE_COMPANY_INVALID_RESPONSE = "Hiba a cég betöltése közben, szerverhiba miatt.",
  GET_ONE_COMPANY_COMPANY_NOT_FOUND = "Hiba a cég betöltése közben, a cég nem található!",
  PREDICTIVE_SEARCH_COMPANY_INVALID_RESPONSE = "Hiba a cégek keresése közben, szerverhiba miatt.",
  PREDICTIVE_SEARCH_COMPANY_ERROR_IN_SENT_RESPONSE = "Szerverhiba: hiba a küldött keresési adatokban.",
  FORGOT_PASSWORD_CONFIRM_INVALID_RESPONSE = "Hiba a hitelesítés közben, szerverhiba miatt.",
  FORGOT_PASSWORD_CONFIRM_TOKEN_INVALID = "A token lejárt, vagy már felhasználták, kérj új jelszóemlékeztetőt!",
  FOROT_PASSWORD_CONFIRM_PAGE_TITLE = "Kérlek add meg az új jelszavad!",
  FORGOT_PASSWORD_MODIFY_PASSWORD_INVALID_RESPONSE = "Hiba a jelszóváltoztatás közben, szerverhiba miatt.",
  FORGOT_PASSWORD_MODIFY_PASSWORD_SUCCESS = "Jelszavadat sikeresen megváltoztattad!",
  FORGOT_PASSWORD_MODIFY_PASSWORD_USER_NOT_FOUND = "Hiba a jelszóvéltoztatás közben, lehetséges hogy időközben törölték ezt a felhasználót.",
  CREATE_CAMPAIGN_COMPANY_SEARCH_BUTTON = "Keress céget",
  NAME_REQUIRED_ERROR_MESSAGE = "Név megadása kötelező!",
  SUBTITLE_REQUIRED_ERROR_MESSAGE = "Alcím megadása kötelező!",
  PASSWORD_FORMAT_INVALID_ERROR_MESSAGE = "Nem megfelelő formátum!",
  NOT_MATCHING_PASSWORDS = "A jelszavak nem egyeznek!",
  EMAIL_REQUIRED_ERROR_MESSAGE = "Kérlek valós email címet adj meg!",
  DESCRIPTION_REQUIRED_ERROR_MESSAGE = "Leírás megadása kötelező!",
  CREATE_COMPANY_ALREADY_EXIST = "A cég már létezik!",
  CREATE_COMPANY_INVALID_RESPONSE = "Hiba a cég létrehozása közben, szerverhiba miatt.",
  MODIFY_COMPANY_COMPANY_NOT_FOUND = "Hiba a cég módosítása közben, a cég nem található!",
  MODIFY_COMPANY_COMPANY_ALREADY_EXIST = "Hiba a cég módosítása közben, a cég már létezik!",
  MODIFY_COMPANY_INVALID_RESPONSE = "Hiba a cég módosítása közben, szerverhiba miatt.",
  CITY_REQUIRED_ERROR_MESSAGE = "Város megadása kötelező!",
  ZIP_CODE_REQUIRED_ERROR_MESSAGE = "Irányítószám megadása kötelező!",
  STREET_REQUIRED_ERROR_MESSAGE = "Utca megadása kötelező!",
  HOUSE_NUMBER_REQUIRED_ERROR_MESSAGE = "Házszám megadása kötelező!",
  TAX_NUMBER_REQUIRED_ERROR_MESSAGE = "Adószám megadása kötelező!",
  CREATE_COMPANY_INVALID_COMPANY_ADDRESS = "Nem valós helyadatok!",
  GET_ALL_USER_INVALID_RESPONSE = "A felhasználók betöltése sikertelen volt, szerverhiba miatt.",
  GET_ALL_USER_ERROR_IN_SENT_RESPONSE = "Szerverhiba: hiba a küldött user adatokban.",
  USER_PAGE_TITLE = "Felhasználók",
  CREATED = "Létrehozva",
  DELETE_USERS_INVALID_RESPONSE = "A felhasználó(k) törlése sikertelen volt, szerverhiba miatt.",
  DELETE_USER_BUTTON_TITLE = "Felhasználó törlése",
  DELETE_MULTIPLE_USER_BUTTON_TITLE = "Felhasználók törlése",
  USER_DELETE_POPUP_CONFIRM = "Biztosan törölni szeretnéd a felhasználót?",
  MULTIPLE_USER_DELETE_POPUP_CONFIRM = "Biztosan törölni szeretnéd a felhasználókat?",
  GET_ALL_TEAM_INVALID_RESPONSE = "A csapatok betöltése sikertelen volt, szerverhiba miatt.",
  GET_ALL_TEAM_ERROR_IN_SENT_RESPONSE = "Szerverhiba: hiba a küldött csapatadatokban.",
  DELETE_TEAMS_INVALID_RESPONSE = "A csapat(ok) törlése sikertelen volt, szerverhiba miatt.",
  TEAM_PAGE_TITLE = "Csapatok",
  TEAM_DELETE_POPUP_CONFIRM = "Biztosan törölni szeretnéd a csapatot?",
  MULTIPLE_TEAM_DELETE_POPUP_CONFIRM = "Biztosan törölni szeretnéd a csapatokat?",
  DELETE_TEAM_BUTTON_TITLE = "Csapat törlése",
  DELETE_MULTIPLE_TEAM_BUTTON_TITLE = "Csapatok törlése",
  SEARCH_TEAM_LEADER = "Keress csapatkapitányt",
  COMPANY_TEAM = "Céges",
  PAYMENT_INVALID_RESPONSE = "Szerverhiba.",
  PAYMENT_NOT_VALID = "Hiba a fizetés közben.",
  PAYMENT_DONE_ALREADY = "A fizetés már megtörtént.",
  PAYMENT_VALIDATION_FAILED = "Hiba a hitelesítés közben. Sikertelen fizetés.",
  PAYMENT_WAS_SUCCESSFUL = "Sikeres fizetés.",
  CREATE_CAMPAIGN = "+ Új kihívás",
  PICTURE = "Kép",
  UPLOAD_PICTURE = "Kép feltöltése",
  FROM = "-tól",
  TO = "-ig",
  NUMBER_OF_PARTICIPANTS = "Résztvevők száma szerinti cél",
  ACTIVITY_OF_PARTICIPANTS = "Aktivitás szerinti cél",
  PARTICIPANTS = "résztvevő",
  ACTIVITY = "aktív út",
  GOAL_TYPE_DAYS = "nap alatt.",
  HEAD = "fő",
  COMPANY_CREATION_BUTTON = "+ Új cég",
  CREATE_ADMIN = "+ Új admin",
  PRIVATE = "Privát",
  PUBLIC = "Publikus",
  PERCENTAGE = "70%",
  NEW_BADGE_CARD_TITLE = "Új jelvény létrehozása",
  NORMAL_ADMIN = "Admin",
  MOBILE_ADMIN = "Mobile admin",
  TYPE = "Típus",
  VERSION_PAGE_TITLE = "Verziókövetés",
  GET_ALL_VERSION_INVALID_RESPONSE = "A verziók betöltése sikertelen volt, szerverhiba miatt.",
  GET_ALL_VERSION_ERROR_IN_SENT_RESPONSE = "Szerverhiba: hiba a küldött verzió adatokban.",
  VERSION_DELETE_POPUP_CONFIRM = "Biztosan törölni szeretnéd ezt a verziót?",
  VERSION = "Verzió",
  PLATFORM = "Platform",
  CRITICAL = "Kritikus",
  NOT_CRITICAL = "Nem kritikus",
  DELETE_VERSION_INVALID_RESPONSE = "A verzió törlése sikertelen volt, szerverhiba miatt.",
  DELETE_VERSION_NOT_FOUND = "Hiba a törlés közben, a verzió nem található!",
  ANDROID = "Android",
  IOS = "IOS",
  NEW_VERSION = "+ Új verzió",
  CREATE_VERSION_INVALID_RESPONSE = "Hiba a verziószám lértehozása közben, szerverhiba miatt.",
  VERSION_ALREADY_EXIST = "A verzió már létezik ezekkel aparaméterekkel.",
  VERSION_NOT_FOUND = "A verzió nem létezik.",
  MODIFY_VERSION_INVALID_RESPONSE = "Hiba a verziószám módosítása közben, szerverhiba miatt.",
  LEADERBOARD_PAGE_TITLE = "Ranglista",
  GET_LEADERBOARD_INVALID_RESPONSE = "Az eredménytábla betöltése sikertelen volt, szerverhiba miatt.",
  GET_LEADERBOARD_ERROR_IN_SENT_RESPONSE = "Szerverhiba: hiba a küldött eredménytábla adatokban.",
  PERSONAL = "Egyéni",
  DELETE_PICTURE = "Kép törlése",
  SHOW_LEADERBOARD = "Eredménytábla megtekintése",
  OK = "OK",
  FISRT_LOGIN_ADMIN_INVALID_RESPONSE = "Hiba a jelszóváltoztatás közben, szerverhiba miatt.",
  FISRT_LOGIN_ADMIN_USER_NOT_FOUND = "Hiba a jelszóváltoztatás közben, a felhasználó nem található!",
  SELECT_TYPE_PLACEHOLDER = "Válassz egy típust",
  THE_PICTURE_FILE_SIZE_IS_TOO_BIG = "A feltölteni kívént kép mérete nem lehet nagyobb 5MB-nál",
  MIN_PASSWORD_CHAR = "Minimum 8 karakter hosszúnak kell lennie!",
  MUST_CONTAIN_CAPITAL_AND_LOWERCASE_LETTERS = "Tartalmazni kell legalább egy kis és egy nagy betűt!",
  MUST_CONTAIN_NUMBER = "Tartalmazni kell legalább egy számot!",
  CAMPAIGN_SCORE_CHECKBOX_TITLE = "Pontszám",
  CAMPAIGN_OCCASION_CHECKBOX_TITLE = "Munkába járás",
  CAMPAIGN_MIXED_CHECKBOX_TITLE = "Tömegközlekedés kombinálása",
  CAMPAIGN_CHILD_CHECKBOX_TITLE = "Gyerek elvitele",
  CAMPAIGN_INVITE_SENT_CHECKBOX_TITLE = "Meghívás",
  SOLO = "Egyéni",
  TEAM = "Csapatos",
  GENDER = "Nem",
  BIRTHDATE = "Szül év.",
  CITY = "Város",
  MALE = "Férfi",
  FEMALE = "Nő",
  OTHER = "Egyéb",
  RESEND_COMPANY_CODE_INVALID_RESPONSE = "Hiba a kód újraküldése közben, nem megfelelő válasz a szervertől!",
  RESEND_COMPANY_CODE_COMPANY_NOT_FOUND = "Hiba a kód újraküldése közben, a cég nem található!",
  RESEND_BUTTON_HEADER = "Újraküldés és Törlés",
  PUBLIC_SOLO_CAMPAIGN = "A kihívás minden egyéni felhasználó számára látható lesz, de szponzorként megjelölhető a listából egy cég neve (Pl. Erste Bank kampánya)",
  PUBLIC_TEAM_CAMPAIGN = "A kihívás minden privát és céges csapat számára látható lesz, de szponzorként megjelölhető a listából egy cég neve (Pl. Erste Bank kampánya)",
  PRIVATE_TEAM_CAMPAIGN = "A kihívás a kiválasztott céghez tartozó céges csoportok számára lesz látható és csatlakozható. ",
  GET_ALL_USER_CITIES_INVALID_RESPONSE = "A városok betöltése sikertelen volt, szerverhiba miatt.",
  GET_ALL_USER_XLSX_RESPONSE_INVALID_RESPONSE = "Az exportálás sikertelen volt, szerverhiba miatt",
  CREATE_BADGE_INFO_TOOLTIP = "Jelvény kép feltöltéséhez használjon 1:1-hez arányú alpha típusú képet (alpha: háttér nélküli)",
  ALL_SCORE = "Kampányidő alatt össz pontszám",
  GET_LEADERBOARD_XLSX_RESPONSE_INVALID_RESPONSE = "Az exportálás sikertelen volt, szerverhiba miatt",
  ACTIVATION = "Aktiváció:",
  USER_TABLE_SUBSCRIBED = "Feliratkozói státusz",
  SUBSCRIBED = "Feliratkozva",
  NON_SUBSCRIBED = "Nincs feliratkozva",
  LEADERBOARD_TABLE_COMPLETED_HEADER = "Teljesítési státusz",
  LEADERBOARD_TABLE_COMPLETED = "Teljesítve",
  LEADERBOARD_TABLE_NOT_COMPLETED = "Nem teljesített",
  MEMBERS = "Tagok száma",
  EXPORT = "Exportálás",
}
