import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import {
  removeCampaignFromCampaigns,
  setCampaignActionStart,
  setCampaignErrorMessage,
} from "../../constants/reducers/campaign.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { AxiosResponse } from "axios";
import { SuccessDTO } from "../../constants/dto/success";

type restoreCampaignParam = {
  id: number;
};

let body: SuccessDTO;

export const restoreCampaign = async ({ id }: restoreCampaignParam) => {
  store.dispatch(setCampaignActionStart(true));
  const request = await createCall(
    `cms/campaigns/restore/${id}`,
    BASE_METHODS.PATCH,
    "application/json",
    "1.0.0",
    undefined,
    undefined
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(SuccessDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setCampaignErrorMessage(TextEnum.RESTORE_CAMPAIGN_INVALID_RESPONSE)
      );
      return;
    }
    store.dispatch(removeCampaignFromCampaigns(id));
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 8002:
          errorMessage = TextEnum.RESTORE_CAMPAIGN_CAMPAIGN_NOT_FOUND;
          break;
        case 8013:
          errorMessage = TextEnum.RESTORE_CAMPAIGN_EXPIRED_CAMPAIGN;
          break;
        default:
          break;
      }
      store.dispatch(setCampaignErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setCampaignActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
