type PlayerType = {
  key: string;
  name: string;
  position: number;
  className: string;
  setIndex: (index: number | null) => void;
};

export const Player: React.FC<PlayerType> = ({
  key,
  name,
  position,
  className,
  setIndex,
}) => {
  return (
    <div
      key={key}
      className={`player ${className}`}
      onMouseEnter={() => setIndex(position)}
      onMouseLeave={() => setIndex(null)}
    >
      <h2>{`${name}`}</h2>
      <div className="position">{position}</div>
    </div>
  );
};
