import prevIcon from "../../../static/left.svg";
import nextIcon from "../../../static/right.svg";

type PaginatorProps = {
  maxPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
};

export const Paginator: React.FC<PaginatorProps> = ({
  maxPages,
  currentPage,
  setCurrentPage,
}) => {
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <div className="pagination">
      <button
        className={`prev-button ${currentPage === 1 ? "disabled" : ""}`}
        onClick={handlePrevPage}
        disabled={currentPage === 1}
      >
        <img
          style={{ marginBottom: "5px", marginLeft: "-2.5px" }}
          src={prevIcon}
          alt={prevIcon}
        />
      </button>
      <span className="page-info">
        {currentPage} / {maxPages === 0 ? 1 : maxPages}
      </span>
      <button
        className={`next-button ${currentPage >= maxPages ? "disabled" : ""}`}
        onClick={handleNextPage}
        disabled={currentPage >= maxPages}
      >
        <img
          style={{ marginBottom: "5px", marginLeft: "-2.5px" }}
          src={nextIcon}
          alt={nextIcon}
        />
      </button>
    </div>
  );
};
