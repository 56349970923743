import { useState, useCallback, useEffect } from "react";
import { TextEnum } from "../../text";
import { BadgeTypes } from "../../enum";
import { RootState } from "../../../store";
import { setBadgeType } from "../../reducers/badge.reducer";
import { useAppDispatch, useAppSelector } from "../../global-hooks";

type BadgeOptionType = {
  label: string;
  value: number;
};

export const CustomSelector = () => {
  const dispatch = useAppDispatch();
  const { type, badge, badges } = useAppSelector(
    (state: RootState) => state.badge
  );

  const [state, setState] = useState({
    showOptions: false,
    selectedBadgeType: "",
    searchBadgeTypeName: "",
  });

  const handleSelectBadgeType = useCallback(
    (id: number) => {
      const label = BadgeTypes.filter((type) => type.value === id)[0].label;
      setState((prevState) => ({ ...prevState, selectedBadgeType: label }));
      setState((prevState) => ({ ...prevState, searchBadgeTypeName: label }));

      dispatch(setBadgeType(id));
    },
    [dispatch]
  );

  useEffect(() => {
    if (badge) {
      handleSelectBadgeType(badge.type);
    }
  }, [handleSelectBadgeType, badge]);

  return (
    <div className="select-wrapper">
      <input
        maxLength={255}
        disabled={badges.length >= 8}
        style={badges.length >= 8 ? { backgroundColor: "silver" } : {}}
        type="text"
        placeholder={
          state.selectedBadgeType
            ? state.selectedBadgeType
            : TextEnum.SELECT_TYPE_PLACEHOLDER
        }
        onBlur={() =>
          setState((prevState) => ({
            ...prevState,
            showOptions: false,
          }))
        }
        onFocus={() =>
          setState((prevState) => ({
            ...prevState,
            showOptions: true,
          }))
        }
        defaultValue={state.searchBadgeTypeName}
      />

      {state.showOptions && (
        <ul className="options-list">
          {BadgeTypes.filter(
            (bt) => !badges.map((b) => b.type).includes(bt.value)
          ).map((option: BadgeOptionType) => (
            <li
              className={type && type === option.value ? "selected" : ""}
              key={option.value}
              onMouseDown={() => handleSelectBadgeType(option.value)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
