import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../entity";
import { UserState } from "../state/user.state";

const initialState: UserState = {
  users: [],
  searchText: "",
  page: 1,
  nameOrder: "",
  createdOrder: "",
  pageCount: null!,
  errorMessage: "",
  userActionStart: false,
  selectedIds: [],
  searchCity: "",
  cities: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (
      state,
      action: PayloadAction<{ users: User[]; pageCount: number }>
    ) => {
      state.users = action.payload.users;
      state.pageCount = action.payload.pageCount;
      state.errorMessage = null!;
      state.userActionStart = false;
    },

    setUserPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setUserSerachText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
      state.page = 1;
    },

    setUserNameOrder: (state, action: PayloadAction<string>) => {
      state.nameOrder = action.payload;
    },

    setUserCreateOrder: (state, action: PayloadAction<string>) => {
      state.createdOrder = action.payload;
    },

    removeUsers: (state) => {
      state.users = state.users.filter(
        (user) => !state.selectedIds.includes(user.id)
      );

      state.errorMessage = null!;
      state.userActionStart = false;
    },

    setUserActionStart: (state, action: PayloadAction<boolean>) => {
      state.userActionStart = action.payload;
    },

    setUserErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },

    setSelectedIds: (state, action: PayloadAction<number[]>) => {
      state.selectedIds = action.payload;
    },

    resetUsers: (state) => {
      state.searchText = "";
      state.createdOrder = "";
      state.nameOrder = "";
      state.page = 1;
      state.selectedIds = [];
      state.searchCity = "";
    },

    setSearchCity: (state, action: PayloadAction<string>) => {
      state.searchCity = action.payload;
    },

    setCities: (state, action: PayloadAction<{ cities: string[] }>) => {
      state.cities = action.payload.cities;
    },
  },
});

export const {
  setUsers,
  setUserPage,
  setUserSerachText,
  setUserNameOrder,
  setUserCreateOrder,
  removeUsers,
  setUserActionStart,
  setUserErrorMessage,
  resetUsers,
  setSelectedIds,
  setSearchCity,
  setCities,
} = userSlice.actions;
