import React from "react";
import "./not-found.scss";
import { TextEnum } from "../../constants/text";

export const NotFoundPage: React.FC = () => {
  return (
    <div className="error-container">
      <div className="error-title">404</div>
      <div className="error-subtitle">{TextEnum.PAGE_NOT_FOUND_TITLE}</div>
      <div>{TextEnum.PAGE_NOT_FOUND_SUBTITLE}</div>
    </div>
  );
};
