import { useState, useEffect } from "react";
import { TextEnum } from "../text";

type ValidatorType = {
  input: string;
  isPassword: boolean;
  isEmail: boolean;
};

export const useTextValidator = ({
  input,
  isPassword,
  isEmail,
}: ValidatorType) => {
  const [state, setState] = useState({ value: false, message: "" });

  useEffect(() => {
    if (input) {
      if (input.trim() === "") {
        setState((prevState) => ({ ...prevState, value: true }));
      }
      if (isPassword) {
        const PASSWORD_LETTER_TEST_REGEX = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
        const PASSWORD_NUMBER_TEST_REGEX = /\d/;
        if (input.length < 8) {
          setState(() => ({
            value: true,
            message: TextEnum.MIN_PASSWORD_CHAR,
          }));
        } else if (!PASSWORD_LETTER_TEST_REGEX.test(input)) {
          setState(() => ({
            value: true,
            message: TextEnum.MUST_CONTAIN_CAPITAL_AND_LOWERCASE_LETTERS,
          }));
        } else if (!PASSWORD_NUMBER_TEST_REGEX.test(input)) {
          setState(() => ({
            value: true,
            message: TextEnum.MUST_CONTAIN_NUMBER,
          }));
        } else {
          setState((prevState) => ({ ...prevState, value: false }));
        }
        // const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        // setState(!PASSWORD_REGEX.test(input));
      }
      if (isEmail) {
        const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        setState((prevState) => ({
          ...prevState,
          value: !EMAIL_REGEX.test(input),
        }));
      }
    } else {
      setState((prevState) => ({ ...prevState, value: false }));
    }
  }, [input, isPassword, isEmail]);

  return state;
};
