import { useEffect, useState } from "react";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { TextBox } from "../../constants/features/input";
import { DataTable } from "../../constants/features/table";
import { MessageModal } from "../../constants/features/modal";
import { AdminColumns } from "../../constants/features/columns";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useAppNavigate,
} from "../../constants/global-hooks";
import {
  useHandleAdminAlertMessage,
  useHandleAdminPageChange,
  useHandleAdminSearchChange,
  useHandleGetAllAdmin,
} from "../../constants/utils";

import "./admin.scss";

export const AdminPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { searchText, page, pageCount, admins, errorMessage } = useAppSelector(
    (state: RootState) => state.admin
  );
  const [searchTerm, setSearchTerm] = useState(false);
  const handleAlertMessage = useHandleAdminAlertMessage(dispatch);
  const getAdmins = useHandleGetAllAdmin();
  useEffect(() => {
    if (searchText) {
      setSearchTerm(true);
    }
  }, [searchText]);

  const debouncedGetAdmins = useDebounce(getAdmins, searchTerm ? 500 : 0);

  useEffect(() => {
    debouncedGetAdmins();
  }, [debouncedGetAdmins, page, searchText]);

  return (
    <main className="main-page">
      <div className="title">{TextEnum.ADMIN_PAGE_TITLE}</div>
      <div className="admin-buttons-container">
        <TextBox
          value={searchText}
          onChange={useHandleAdminSearchChange(dispatch)}
          type={"input"}
          customErrorMessage={""}
          setDecider={() => null}
          placeholder={TextEnum.SEARCH}
          search={true}
        />
        <button
          className="create-admin-button"
          onClick={() => navigate("/admin/create")}
        >
          {TextEnum.CREATE_ADMIN}
        </button>
      </div>
      <div>
        <DataTable
          dataSource={admins}
          columns={Object.values(AdminColumns())}
          maxPage={pageCount}
          currentPage={page}
          setCurrentPage={useHandleAdminPageChange(dispatch, setSearchTerm)}
          onRowClick={() => {}}
          functionDisabler={true}
          isAdminColumn={true}
        />
      </div>
      {errorMessage && (
        <MessageModal
          title=""
          message={errorMessage}
          onCloseClick={handleAlertMessage}
        />
      )}
    </main>
  );
};
