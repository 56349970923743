import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";

import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

import { SuccessDTO } from "../../constants/dto/success";
import {
  paymentActionStart,
  resetPayment,
  setPaymentErrorMessage,
} from "../../constants/reducers/payment.reducer";
let body: SuccessDTO;

export const payment = async () => {
  store.dispatch(paymentActionStart(true));
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const queryParams = new URLSearchParams();
  if (token) queryParams.append("token", token);
  const request = await createCall(
    "paypal",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(SuccessDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setPaymentErrorMessage({
          code: 20,
          message: TextEnum.PAYMENT_INVALID_RESPONSE,
        })
      );
      return null;
    }
    store.dispatch(resetPayment());
    return response;
  } catch (error) {}
  const responseError = request as any;
  const errorResponeData: CustomErrorType = responseError.response?.data;
  if (errorResponeData) {
    let errorMessage = { code: 1, message: "" };
    switch (errorResponeData.code) {
      case 12002:
        errorMessage = { code: 12002, message: TextEnum.PAYMENT_NOT_VALID };
        break;
      case 12003:
        errorMessage = { code: 12003, message: TextEnum.PAYMENT_DONE_ALREADY };
        break;
      case 12005:
        errorMessage = {
          code: 12005,
          message: TextEnum.PAYMENT_VALIDATION_FAILED,
        };
        break;
      default:
        break;
    }
    store.dispatch(setPaymentErrorMessage(errorMessage));
    return null;
  }
  store.dispatch(paymentActionStart(false));
  store.dispatch(timeoutError());
  return null;
};
