import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Campaign } from "../entity";
import { CampaignState } from "../state";

const initialState: CampaignState = {
  name: "",
  description: "",
  page: 1,
  validFrom: "",
  validTo: "",
  isDeleted: false,
  errorMessage: null,
  campaigns: [],
  campaign: null!,
  pageCount: null!,
  picture: null!,
  privateCampaign: false,
  pictureUrl: "",
  company: [],
  selectedId: null!,
  campaignActionStart: false,
  searchText: "",
  validFromOrder: "",
  validToOrder: "",
  inviteSent: null!,
  subtitle: "",
  score: null!,
  childScore: null!,
  mixedScore: null!,
  occasion: null!,
  campaignType: 1,
  isArchived: false,
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaignName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setCampaignSubtitle: (state, action: PayloadAction<string>) => {
      state.subtitle = action.payload;
    },
    setCampaignDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setCampaignScore: (state, action: PayloadAction<number>) => {
      state.score = action.payload;
    },
    setCampaignChildScore: (state, action: PayloadAction<number>) => {
      state.childScore = action.payload;
    },
    setCampaignMixedScore: (state, action: PayloadAction<number>) => {
      state.mixedScore = action.payload;
    },
    setCampaignInviteSent: (state, action: PayloadAction<number>) => {
      state.inviteSent = action.payload;
    },
    setCampaignOccasion: (state, action: PayloadAction<number>) => {
      state.occasion = action.payload;
    },
    setCampaignType: (state, action: PayloadAction<boolean>) => {
      state.campaignType = action.payload === false ? 1 : 2;
    },
    setCampaignPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setValidFrom: (state, action: PayloadAction<string>) => {
      state.validFrom = action.payload;
    },
    setValidTo: (state, action: PayloadAction<string>) => {
      state.validTo = action.payload;
    },
    setIsDeleted: (state, action: PayloadAction<boolean>) => {
      state.isDeleted = action.payload;
    },
    setIsArchived: (state, action: PayloadAction<boolean>) => {
      state.isArchived = action.payload;
    },
    setCampaignPicture: (state, action: PayloadAction<any | null>) => {
      state.picture = action.payload;
    },
    setCampaignPictureUrl: (state, action: PayloadAction<string>) => {
      state.pictureUrl = action.payload;
    },
    setCampaigns: (
      state,
      action: PayloadAction<{ campaigns: Campaign[]; pageCount: number }>
    ) => {
      state.campaigns = action.payload.campaigns;
      state.pageCount = action.payload.pageCount;
      state.errorMessage = null;
      state.campaignActionStart = false;
    },
    setCampaign: (state, action: PayloadAction<{ campaign: Campaign }>) => {
      let formattedValidFromDate = "";
      let formattedValidToDate = "";
      if (
        action.payload.campaign.validFrom ||
        action.payload.campaign.validTo
      ) {
        const dateFrom = new Date(action.payload.campaign.validFrom);
        const yearFrom = dateFrom.getFullYear();
        const monthFrom = String(dateFrom.getMonth() + 1).padStart(2, "0");
        const dayFrom = String(dateFrom.getDate()).padStart(2, "0");

        const dateTo = new Date(action.payload.campaign.validTo);
        const yearTo = dateTo.getFullYear();
        const monthTo = String(dateTo.getMonth() + 1).padStart(2, "0");
        const dayTo = String(dateTo.getDate()).padStart(2, "0");

        formattedValidFromDate = `${yearFrom}-${monthFrom}-${dayFrom}`;
        formattedValidToDate = `${yearTo}-${monthTo}-${dayTo}`;
      }
      state.campaign = action.payload.campaign;
      state.subtitle = action.payload.campaign.subtitle;
      state.name = action.payload.campaign.name;
      state.description = action.payload.campaign.description;
      state.picture = action.payload.campaign.url;
      state.pictureUrl = action.payload.campaign.url;
      state.validFrom = formattedValidFromDate;
      state.validTo = formattedValidToDate;
      state.errorMessage = null;
      state.campaignActionStart = false;
      state.inviteSent = action.payload.campaign.goal.inviteSent ?? null!;
      state.privateCampaign = action.payload.campaign.private;
      state.score = action.payload.campaign.goal.score ?? null!;
      state.childScore = action.payload.campaign.goal.childScore ?? null!;
      state.mixedScore = action.payload.campaign.goal.mixedScore ?? null!;
      state.occasion = action.payload.campaign.goal.occasion ?? null!;
      state.campaignType = action.payload.campaign.type;
      state.selectedId = action.payload.campaign.company?.id;
    },
    setCampaignErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload;
      state.campaignActionStart = false;
    },
    addCampaignToCampaigns: (
      state,
      action: PayloadAction<{ campaign: Campaign }>
    ) => {
      state.campaigns = [...state.campaigns, action.payload.campaign];
      state.errorMessage = null;
      state.campaignActionStart = false;
    },
    removeCampaignFromCampaigns: (state, action: PayloadAction<number>) => {
      if (state.campaigns.length % 10 === 1) {
        state.page -= 1;
        state.pageCount -= 1;
      }
      state.campaigns = [
        ...state.campaigns.filter((c) => c.id !== action.payload),
      ];
      state.errorMessage = null;
      state.campaignActionStart = false;
    },
    setCompany: (state, action: PayloadAction<any>) => {
      state.company = action.payload;
      state.errorMessage = null;
      state.campaignActionStart = false;
    },
    setCampaignSelectedId: (state, action: PayloadAction<number>) => {
      state.selectedId = action.payload;
    },
    resetCampaign: (state) => {
      state.campaign = null!;
      state.name = "";
      state.description = "";
      state.validFrom = "";
      state.validTo = "";
      state.picture = null!;
      state.pictureUrl = "";
      state.selectedId = null!;
      state.searchText = "";
      state.privateCampaign = false;
      state.inviteSent = null!;
      state.subtitle = "";
      state.score = null!;
      state.childScore = null!;
      state.mixedScore = null!;
      state.occasion = null!;
      state.campaignType = 1;
    },
    setCampaignActionStart: (state, action: PayloadAction<boolean>) => {
      state.campaignActionStart = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
      state.page = 1;
    },

    setValidFromOrder: (state, action: PayloadAction<string>) => {
      state.validToOrder = "";
      state.validFromOrder = action.payload;
    },
    setValidToOrder: (state, action: PayloadAction<string>) => {
      state.validFromOrder = "";
      state.validToOrder = action.payload;
    },
    setPrivateCampaign: (state, action: PayloadAction<boolean>) => {
      state.privateCampaign = action.payload;
    },
  },
});

export const {
  setCampaignName,
  setCampaignDescription,
  setCampaignPage,
  setValidFrom,
  setValidTo,
  setIsDeleted,
  setCampaignPicture,
  setCampaigns,
  setCampaign,
  setCampaignErrorMessage,
  addCampaignToCampaigns,
  removeCampaignFromCampaigns,
  resetCampaign,
  setCampaignPictureUrl,
  setCompany,
  setCampaignSelectedId,
  setCampaignActionStart,
  setSearchText,
  setValidFromOrder,
  setValidToOrder,
  setPrivateCampaign,
  setCampaignInviteSent,
  setCampaignSubtitle,
  setCampaignScore,
  setCampaignChildScore,
  setCampaignMixedScore,
  setCampaignOccasion,
  setCampaignType,
  setIsArchived,
} = campaignSlice.actions;
