import { Expose } from "class-transformer";
import {
  IsBoolean,
  IsDefined,
  IsNumber,
  IsString,
  IsDateString,
  IsOptional,
} from "class-validator";
export class Team {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string;

  @Expose()
  @IsDefined()
  @IsBoolean()
  companyTeam!: boolean;

  @Expose()
  @IsDefined()
  @IsString()
  creator!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  created!: string;

  @Expose()
  @IsOptional()
  company!: {
    name: string;
  } | null;
}
