import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { getAllCampaign } from "../../actions/campaign";
import { resetCampaign } from "../reducers/campaign.reducer";

export const campaignLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetCampaign());

  return await getAllCampaign();
};
