import React from "react";
interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabledFrom: string;
}

export const CustomCheckbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  disabledFrom,
}) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (onChange) {
      onChange(checked);
    }
  };
  return (
    <label>
      {label}
      <input
        disabled={new Date(disabledFrom).getTime() <= new Date().getTime()}
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
      />
    </label>
  );
};
