import { AxiosResponse } from "axios";
import { BASE_METHODS } from "../../constants/action-types";

import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

import { GetAllTeamXlsxDataDTO } from "../../constants/dto/team";
import {
  setTeamActionStart,
  setTeamErrorMessage,
} from "../../constants/reducers/team.reducer";
let body: GetAllTeamXlsxDataDTO;

export const getAllTeamXlsxData = async () => {
  store.dispatch(setTeamActionStart(true));

  const request = await createCall(
    "cms/teams/xlsx",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0"
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetAllTeamXlsxDataDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setTeamErrorMessage(
          TextEnum.GET_ALL_USER_XLSX_RESPONSE_INVALID_RESPONSE
        )
      );
      return null;
    }
    return response.data;
  } catch (error) {
    store.dispatch(setTeamActionStart(false));
    store.dispatch(timeoutError());
    return null;
  }
};
