import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  adminSlice,
  badgeSlice,
  baseSlice,
  campaignSlice,
  companySlice,
  leaderboardSlice,
  loginSlice,
  paymentSlice,
  sessionSlice,
  teamSlice,
  userSlice,
  versionSlice,
  forgotPasswordSlice,
} from "../constants/reducers";

export const store = configureStore({
  reducer: {
    session: sessionSlice.reducer,
    login: loginSlice.reducer,
    base: baseSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    campaign: campaignSlice.reducer,
    company: companySlice.reducer,
    badge: badgeSlice.reducer,
    admin: adminSlice.reducer,
    user: userSlice.reducer,
    team: teamSlice.reducer,
    payment: paymentSlice.reducer,
    version: versionSlice.reducer,
    leaderboard: leaderboardSlice.reducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
