import { Expose } from "class-transformer";
import { IsDefined, IsNumber, IsString, IsBoolean } from "class-validator";
export class Badge {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string;

  @Expose()
  @IsDefined()
  @IsString()
  description!: string;

  @Expose()
  @IsDefined()
  @IsString()
  picture!: string;

  @Expose()
  @IsDefined()
  @IsBoolean()
  active!: boolean;

  @Expose()
  @IsDefined()
  @IsString()
  url!: string;

  @Expose()
  @IsDefined()
  @IsNumber()
  type!: number;
}
