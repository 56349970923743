import { Expose, Type } from "class-transformer";
import { IsArray, IsNumber, ValidateNested } from "class-validator";
import { Campaign } from "../../entity";

export class GetAllCampaignDTO {
  @Expose()
  @ValidateNested()
  @Type(() => Campaign)
  @IsArray()
  campaigns!: Campaign[];

  @Expose()
  @IsNumber()
  pageCount!: number;
}
