import React, { useCallback, useMemo } from "react";
import { SyncOutlined } from "@ant-design/icons";
import orderIcon from "../../../static/down.svg";

type CutromOrderProp = {
  orderFunction: (order: string) => void;
  order: string;
};

export const CustomOrder: React.FC<CutromOrderProp> = ({
  order,
  orderFunction,
}) => {
  const handleOrderChange = useCallback(() => {
    if (order === "") {
      orderFunction("ASC");
    }
    if (order === "ASC") {
      orderFunction("DESC");
    }
    if (order === "DESC") {
      orderFunction("");
    }
  }, [orderFunction, order]);

  return useMemo(() => {
    return (
      <div>
        {order === "" || order === "ASC" ? (
          <img
            src={orderIcon}
            alt={orderIcon}
            className={`${order === "ASC" ? "rotate-180" : ""}`}
            onClick={handleOrderChange}
          />
        ) : (
          <SyncOutlined
            className="recicle-position"
            onClick={handleOrderChange}
          />
        )}
      </div>
    );
  }, [handleOrderChange, order]);
};
