import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { Badge } from "../../entity";

export class CreateBadgeDTO {
  @Expose()
  @ValidateNested()
  @Type(() => Badge)
  badge!: Badge;
}
