import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";

import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { GetAllTeamDTO } from "../../constants/dto/team";
import {
  setTeamActionStart,
  setTeamErrorMessage,
  setTeams,
} from "../../constants/reducers/team.reducer";

let body: GetAllTeamDTO;

export const getAllTeam = async () => {
  store.dispatch(setTeamActionStart(true));
  const {
    searchText,
    nameOrder,
    page,
    companyTeam,
    companyName,
    createdOrder,
  } = store.getState().team;
  const queryParams = new URLSearchParams();
  if (searchText) queryParams.append("searchText", searchText);
  if (nameOrder) queryParams.append("nameOrder", nameOrder);
  if (page) queryParams.append("page", page.toString());
  if (companyTeam) queryParams.append("companyTeam", companyTeam.toString());
  if (companyName) queryParams.append("companyName", companyName);
  if (createdOrder) queryParams.append("dateOrder", createdOrder);

  const request = await createCall(
    "cms/teams",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetAllTeamDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setTeamErrorMessage(TextEnum.GET_ALL_TEAM_INVALID_RESPONSE)
      );
      return null;
    }
    store.dispatch(
      setTeams({
        teams: response.data.teams,
        pageCount: response.data.pageCount,
      })
    );
    return response;
  } catch (error) {}
  const responseError = request as any;
  const errorResponeData: CustomErrorType = responseError.response?.data;
  if (errorResponeData) {
    let errorMessage = "";
    switch (errorResponeData.code) {
      case 3017:
        errorMessage = TextEnum.GET_ALL_TEAM_ERROR_IN_SENT_RESPONSE;
        break;
      default:
        break;
    }
    store.dispatch(setTeamErrorMessage(errorMessage));
    return null;
  }
  store.dispatch(setTeamActionStart(false));
  store.dispatch(timeoutError());
  return null;
};
