import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../entity";
import { cookie } from "../helper";
import { SessionState } from "../state";

const initialState: SessionState = {
  authenticatedUser: undefined,
  token: cookie("token"),
  activeConfirmMessage: null,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    getMeOk: (state, action: PayloadAction<{ user: User }>) => {
      state.authenticatedUser = action.payload.user;
    },
    getMeFailure: (state) => {
      state.authenticatedUser = null;
    },
    sessionLoginOk: (state, action: PayloadAction<{ user: User; token: string }>) => {
      cookie("token", action.payload.token);
      state.authenticatedUser = action.payload.user;
      state.token = action.payload.token;
    },
    logout: (state) => {
      cookie("token", "");
      state.authenticatedUser = null;
      state.token = null;
    },
    setActiveConfirmMessage: (state, action: PayloadAction<string | null>) => {
      state.activeConfirmMessage = action.payload;
    },
  },
});

export const {
  getMeOk,
  getMeFailure,
  sessionLoginOk,
  logout,
  setActiveConfirmMessage,
} = sessionSlice.actions;
