import { LoginDTO } from "../../constants/dto/admin";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

import { AxiosResponse } from "axios";
import { TextEnum } from "../../constants/text";
import { store } from "../../store";
import {
  loginInvalidResponse,
  loginStarted,
  setAlertMessage,
  tooManyLoginAttempts,
} from "../../constants/reducers/login.reducer";
import { createCall } from "../base";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { sessionLoginOk } from "../../constants/reducers/session.reducer";
import { timeoutError } from "../../constants/reducers/base.reducer";

let body: LoginDTO;
export const loginAction = async () => {
  store.dispatch(loginStarted());
  const { email, password } = store.getState().login;

  const params = {
    email: email,
    password: password,
  };

  const request = await createCall(
    "cms/admins/login",
    BASE_METHODS.POST,
    "application/json",
    "1.0.0",
    params,
    undefined,
    true
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(LoginDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(loginInvalidResponse());
      return;
    }
    store.dispatch(
      sessionLoginOk({
        user: response.data.user,
        token: response.data.token.accessToken,
      })
    );
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 1002:
          errorMessage = TextEnum.INVALID_EMAIL_PASSWORD;
          store.dispatch(setAlertMessage(errorMessage));
          break;
        case 1004:
          const date = new Date(errorResponeData.data);
          store.dispatch(tooManyLoginAttempts(date));
          break;
        default:
          break;
      }
      return;
    }
    store.dispatch(timeoutError());
    return;
  }
};
