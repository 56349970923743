import { useEffect, useState } from "react";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { TextBox } from "../../constants/features/input";
import { DataTable } from "../../constants/features/table";
import { MessageModal } from "../../constants/features/modal";
import { TeamColumns } from "../../constants/features/columns";
import { SwitchButton } from "../../constants/features/buttons";
import { CustomPopConfirm } from "../../constants/features/popconfirm";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "../../constants/global-hooks";
import {
  useHandleChangeTeamType,
  useHandleDeleteTeams,
  useHandleGetTeams,
  useHandleTeamAlertMessage,
  useHandleTeamPageChange,
  useHandleTeamSearchChange,
  useHandleWannapeDeletedTeams,
} from "../../constants/utils";

import "./team.scss";
import { getAllTeamXlsxData } from "../../actions/team";
import * as XLSX from "xlsx";

export const TeamPage = () => {
  const dispatch = useAppDispatch();
  const {
    teams,
    searchText,
    page,
    pageCount,
    errorMessage,
    nameOrder,
    createdOrder,
    selectedIds,
    companyTeam,
    companyName,
  } = useAppSelector((state: RootState) => state.team);
  const [searchTerm, setSearchTerm] = useState(false);

  const handleAlertMessage = useHandleTeamAlertMessage(dispatch);
  const getTeams = useHandleGetTeams();

  useEffect(() => {
    if (searchText || companyTeam || companyName || nameOrder || createdOrder) {
      setSearchTerm(true);
    }
  }, [searchText, companyTeam, companyName, nameOrder, createdOrder]);

  const debouncedGetTeams = useDebounce(getTeams, searchTerm ? 500 : 0);

  useEffect(() => {
    debouncedGetTeams();
  }, [
    debouncedGetTeams,
    page,
    searchText,
    companyTeam,
    companyName,
    nameOrder,
    createdOrder,
  ]);

  const generateXLSX = async () => {
    const users = await getAllTeamXlsxData();

    if (users.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(users);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

      const arrayBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Uint8Array(arrayBuffer);

      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `csapatok-${new Date().getTime()}.xlsx`;
      link.click();

      URL.revokeObjectURL(url);
    } else {
      console.warn("No users to export.");
    }
  };

  return (
    <main className="main-page">
      <div className="title">{TextEnum.TEAM_PAGE_TITLE}</div>
      <button className="export-button" onClick={generateXLSX}>
        Exportálás
      </button>
      <div className="team-buttons-container">
        <TextBox
          value={searchText}
          onChange={useHandleTeamSearchChange(dispatch)}
          type={"input"}
          customErrorMessage={""}
          setDecider={() => null}
          placeholder={TextEnum.SEARCH}
          search={true}
        />
        <CustomPopConfirm
          title={
            selectedIds.length > 1
              ? TextEnum.MULTIPLE_TEAM_DELETE_POPUP_CONFIRM
              : TextEnum.TEAM_DELETE_POPUP_CONFIRM
          }
          okText={TextEnum.YES}
          cancelText={TextEnum.NO}
          onConfirm={useHandleDeleteTeams(dispatch)}
          disabled={selectedIds.length === 0}
        >
          <button
            className={`team-delete-button ${
              selectedIds.length === 0 ? "disabled" : ""
            }`}
          >
            {selectedIds.length > 1
              ? TextEnum.DELETE_MULTIPLE_TEAM_BUTTON_TITLE
              : TextEnum.DELETE_TEAM_BUTTON_TITLE}
          </button>
        </CustomPopConfirm>
      </div>

      <div>
        <DataTable
          dataSource={teams}
          columns={Object.values(TeamColumns())}
          maxPage={pageCount}
          currentPage={page}
          setCurrentPage={useHandleTeamPageChange(dispatch, setSearchTerm)}
          onRowClick={useHandleWannapeDeletedTeams(dispatch)}
          isUserOrTeamColumn={true}
          selectedIds={selectedIds}
          setSelectedIds={useHandleWannapeDeletedTeams(dispatch)}
        />
      </div>
      {errorMessage && (
        <MessageModal
          title=""
          message={errorMessage}
          onCloseClick={handleAlertMessage}
        />
      )}
    </main>
  );
};
