import { useMemo } from "react";
import { TextEnum } from "../../text";
import { useAppDispatch, useAppSelector } from "../../global-hooks";
import { RootState } from "../../../store";
import { CustomOrder } from "../table";
import {
  useHandleLeaderboardAllScoreChange,
  useHandleLeaderboardChildOccasionOrderChange,
  useHandleLeaderboardInviteSentOrderChange,
  useHandleLeaderboardMixedOccasionOrderChange,
  useHandleLeaderboardOccasionOrderChange,
  useHandleLeaderboardScoreChange,
} from "../../utils";
import { NonWinnerUsers } from "../../entity";

export const LeaderboardColumns = () => {
  const dispatch = useAppDispatch();
  const {
    lederboardType,
    allScoreOrder,
    scoreOrder,
    childOccasionOrder,
    mixedOccasionOrder,
    occasionOrder,
    inviteSentOrder,
  } = useAppSelector((state: RootState) => state.leaderboard);

  const allScoreOrderChange = useHandleLeaderboardAllScoreChange(dispatch);
  const scoreOrderChange = useHandleLeaderboardScoreChange(dispatch);
  const childOccasionOrderChange =
    useHandleLeaderboardChildOccasionOrderChange(dispatch);
  const mixedOccasionOrderChange =
    useHandleLeaderboardMixedOccasionOrderChange(dispatch);
  const occasionOrderChange = useHandleLeaderboardOccasionOrderChange(dispatch);
  const inviteSentOrderChange =
    useHandleLeaderboardInviteSentOrderChange(dispatch);

  return useMemo(() => {
    const columns = [
      {
        title: TextEnum.EMAIL,
        key: TextEnum.EMAIL,
        dataIndex: "email",
      },
      {
        title: TextEnum.NAME,
        key: TextEnum.NAME,
        dataIndex: "name",
      },
      {
        title: TextEnum.GENDER,
        key: TextEnum.GENDER,
        render: (text: string, record: NonWinnerUsers) => {
          return record.gender === 1
            ? TextEnum.MALE
            : record.gender === 2
            ? TextEnum.FEMALE
            : TextEnum.OTHER;
        },
      },
      {
        title: TextEnum.BIRTHDATE,
        key: TextEnum.BIRTHDATE,
        render: (text: string, record: NonWinnerUsers) => {
          return record.birthDate ?? "";
        },
      },
      {
        title: TextEnum.CITY,
        key: TextEnum.CITY,
        dataIndex: "city",
      },
      {
        title: TextEnum.CREATED,
        key: TextEnum.CREATED,
        render: (text: string, record: NonWinnerUsers) => {
          return new Date(record.created).toLocaleDateString();
        },
      },
      {
        title: (
          <div className="inline-container">
            {TextEnum.ALL_SCORE}
            <CustomOrder
              orderFunction={allScoreOrderChange}
              order={allScoreOrder}
            />
          </div>
        ),
        key: TextEnum.ALL_SCORE,
        dataIndex: "allScore",
      },
      {
        title: (
          <div className="inline-container">
            {TextEnum.CAMPAIGN_SCORE_CHECKBOX_TITLE}
            <CustomOrder orderFunction={scoreOrderChange} order={scoreOrder} />
          </div>
        ),
        key: TextEnum.CAMPAIGN_SCORE_CHECKBOX_TITLE,
        dataIndex: "score",
      },
      {
        title: (
          <div className="inline-container">
            {TextEnum.CAMPAIGN_CHILD_CHECKBOX_TITLE}
            <CustomOrder
              orderFunction={childOccasionOrderChange}
              order={childOccasionOrder}
            />
          </div>
        ),
        key: TextEnum.CAMPAIGN_CHILD_CHECKBOX_TITLE,
        dataIndex: "childOccasion",
      },
      {
        title: (
          <div className="inline-container">
            {TextEnum.CAMPAIGN_MIXED_CHECKBOX_TITLE}
            <CustomOrder
              orderFunction={mixedOccasionOrderChange}
              order={mixedOccasionOrder}
            />
          </div>
        ),
        key: TextEnum.CAMPAIGN_MIXED_CHECKBOX_TITLE,
        dataIndex: "mixedOccasion",
      },
      {
        title: (
          <div className="inline-container">
            {TextEnum.CAMPAIGN_OCCASION_CHECKBOX_TITLE}
            <CustomOrder
              orderFunction={occasionOrderChange}
              order={occasionOrder}
            />
          </div>
        ),
        key: TextEnum.CAMPAIGN_OCCASION_CHECKBOX_TITLE,
        dataIndex: "occasion",
      },
      {
        title: (
          <div className="inline-container">
            {TextEnum.CAMPAIGN_INVITE_SENT_CHECKBOX_TITLE}
            <CustomOrder
              orderFunction={inviteSentOrderChange}
              order={inviteSentOrder}
            />
          </div>
        ),
        key: TextEnum.CAMPAIGN_INVITE_SENT_CHECKBOX_TITLE,
        dataIndex: "inviteSent",
      },
      {
        title: TextEnum.MEMBERS,
        key: TextEnum.MEMBERS,
        dataIndex: "memberCount",
      },
      {
        title: TextEnum.LEADERBOARD_TABLE_COMPLETED_HEADER,
        key: TextEnum.LEADERBOARD_TABLE_COMPLETED_HEADER,
        render: (text: string, record: NonWinnerUsers) => {
          return record.completed
            ? TextEnum.LEADERBOARD_TABLE_COMPLETED
            : TextEnum.LEADERBOARD_TABLE_NOT_COMPLETED;
        },
      },
    ];

    if (lederboardType === 2) {
      return columns.filter(
        (col) =>
          col.key !== TextEnum.EMAIL &&
          col.key !== TextEnum.GENDER &&
          col.key !== TextEnum.BIRTHDATE &&
          col.key !== TextEnum.CITY
      );
    }
    return columns.filter((col) => col.key !== TextEnum.MEMBERS);
  }, [
    allScoreOrder,
    allScoreOrderChange,
    childOccasionOrder,
    childOccasionOrderChange,
    inviteSentOrder,
    inviteSentOrderChange,
    lederboardType,
    mixedOccasionOrder,
    mixedOccasionOrderChange,
    occasionOrder,
    occasionOrderChange,
    scoreOrder,
    scoreOrderChange,
  ]);
};
