import { Expose, Type } from "class-transformer";
import {
  IsBoolean,
  IsDateString,
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { Company } from "./company.entity";
import { Goal } from "./goal.entity";

type LeaderboardType = {
  id: number;
};

export class Campaign {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string;

  @Expose()
  @IsDefined()
  @IsString()
  subtitle!: string;

  @Expose()
  @IsDefined()
  @IsString()
  description!: string;

  @Expose()
  @IsDefined()
  @IsString()
  url!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  validFrom!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  validTo!: string;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => Goal)
  goal!: Goal;

  @Expose()
  @IsDefined()
  @IsBoolean()
  deleted!: boolean;

  @Expose()
  @IsDefined()
  @IsNumber()
  type!: number;

  @Expose()
  @IsDefined()
  @IsBoolean()
  private!: boolean;

  @Expose()
  @IsOptional()
  @ValidateNested()
  leaderboard!: LeaderboardType;

  @Expose()
  @IsOptional()
  @ValidateNested()
  @Type(() => Company)
  company!: Company;
}
