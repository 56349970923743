import { store } from "../../store";
import { createCall } from "../base";
import { BASE_METHODS } from "../../constants/action-types";
import {
  setErrorMessage,
  setForgotPasswordActionStart,
} from "../../constants/reducers/forgot.password.reducer";
import { TextEnum } from "../../constants/text";
import { SuccessDTO } from "../../constants/dto/success";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { AxiosResponse } from "axios";

let body: SuccessDTO;

export const forgotPassword = async () => {
  store.dispatch(setForgotPasswordActionStart(true));
  const email = store.getState().forgotPassword.email;
  const bodyToSend = {
    email,
  };
  const request = await createCall(
    "cms/admins/forgot-password",
    BASE_METHODS.POST,
    "application/json",
    "1.0.0",
    bodyToSend,
    undefined,
    true
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(SuccessDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setErrorMessage(TextEnum.FORGOT_PASSWORD_CONFIRM_INVALID_RESPONSE)
      );
      return;
    }
    store.dispatch(setErrorMessage(TextEnum.FORGOT_PASSWORD_MODAL_MESSAGE));
    return response;
  } catch (error) {
    store.dispatch(setForgotPasswordActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
