import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { Version } from "../../entity";

export class CreateVersionDTO {
  @Expose()
  @ValidateNested()
  @Type(() => Version)
  version!: Version;
}
