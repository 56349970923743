import { TextEnum } from "../text";

export const BadgeTypes = [
  {
    label: TextEnum.FourDaysInARow,
    value: 1,
  },
  {
    label: TextEnum.EightDaysInARow,
    value: 2,
  },
  {
    label: TextEnum.TwelveDaysInARow,
    value: 3,
  },
  {
    label: TextEnum.SixteenDaysInARow,
    value: 4,
  },
  {
    label: TextEnum.TwentyOneDaysInARow,
    value: 5,
  },
  {
    label: TextEnum.OneCupon,
    value: 6,
  },
  {
    label: TextEnum.ThreeCupons,
    value: 7,
  },
  {
    label: TextEnum.FiveCupons,
    value: 8,
  },
];
