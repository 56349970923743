import { useMemo } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Admin } from "../../entity";
import { TextEnum } from "../../text";
import { RootState } from "../../../store";
import { CustomPopConfirm } from "../popconfirm";
import deleteIcon from "../../../static/trash.svg";
import { useAppSelector } from "../../global-hooks";
import { deleteAdmin } from "../../../actions/admin";

export const AdminColumns = () => {
  const { adminActionStart } = useAppSelector(
    (state: RootState) => state.admin
  );

  const handleDelete = async (id: number) => {
    await deleteAdmin({ id });
  };

  return useMemo(() => {
    return [
      {
        title: TextEnum.NAME,
        key: TextEnum.NAME,
        dataIndex: "name",
        width: "350px",
      },
      {
        title: TextEnum.EMAIL,
        key: TextEnum.EMAIL,
        dataIndex: "email",
        width: "350px",
      },
      {
        title: TextEnum.TYPE,
        key: TextEnum.TYPE,
        render: (text: string, record: Admin) => {
          return record.role.name;
        },
        width: "120px",
      },
      {
        title: TextEnum.DELETE,
        key: TextEnum.DELETE,
        render: (text: string, record: Admin) => {
          return (
            <CustomPopConfirm
              title={TextEnum.ADMIN_DELETE_POPUP_CONFIRM}
              okText={TextEnum.YES}
              cancelText={TextEnum.NO}
              onConfirm={() => handleDelete(record.id)}
              disabled={false}
            >
              <button
                disabled={adminActionStart}
                className="admin-table-delete-button"
              >
                {adminActionStart ? (
                  <LoadingOutlined />
                ) : (
                  <img
                    className="table-icon"
                    src={deleteIcon}
                    alt={deleteIcon}
                  />
                )}
              </button>
            </CustomPopConfirm>
          );
        },
        width: "100px",
      },
    ];
  }, [adminActionStart]);
};
