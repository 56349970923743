import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { ModifyCampaignDTO } from "../../constants/dto/campaign";
import {
  setCampaignActionStart,
  setCampaignErrorMessage,
} from "../../constants/reducers/campaign.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

let body: ModifyCampaignDTO;

export const modifyCampaign = async () => {
  store.dispatch(setCampaignActionStart(true));
  const {
    picture,
    validFrom,
    validTo,
    subtitle,
    inviteSent,
    name,
    description,
    privateCampaign,
    selectedId,
    score,
    childScore,
    mixedScore,
    occasion,
    campaign,
    campaignType,
  } = store.getState().campaign;

  const formData = new FormData();
  if (picture) {
    formData.append("picture", picture);
  }
  if (
    validFrom !== null &&
    validTo !== null &&
    new Date(validFrom) < new Date(validTo)
  ) {
    formData.append("validFrom", validFrom);
    formData.append("validTo", validTo);
  } else {
    setCampaignErrorMessage(TextEnum.INVALID_PARAMETERS);
    return;
  }
  const goalParams = {
    score,
    childScore,
    inviteSent,
    mixedScore,
    occasion,
  };
  formData.append("name", name);
  formData.append("subtitle", subtitle);
  formData.append("description", description);
  formData.append("goal", JSON.stringify(goalParams));
  formData.append("privateCampaign", privateCampaign.toString());
  formData.append("id", campaign.id.toString());
  formData.append("type", campaignType.toString());
  if (selectedId) {
    formData.append("companyId", selectedId.toString());
  }

  const request = await createCall(
    "cms/campaigns/modify",
    BASE_METHODS.PUT,
    "multipart/form-data",
    "2.0.0",
    formData,
    undefined
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(ModifyCampaignDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setCampaignErrorMessage(TextEnum.MODIFY_CAMPAIGN_INVALID_RESPONSE)
      );
      return;
    }
    store.dispatch(setCampaignActionStart(false));
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 8002:
          errorMessage = TextEnum.MODIFY_CAMPAIGN_CAMPAIGN_NOT_FOUND;
          break;
        case 9001:
          errorMessage = TextEnum.MODIFY_CAMPAIGN_FILE_NOT_FOUND;
          break;
        default:
          break;
      }
      store.dispatch(setCampaignErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setCampaignActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
