import React, { useState, useRef, useMemo, useCallback } from "react";
import { TextEnum } from "../../text";
import { InfoMessageModal, PictureCropperModal } from "../modal";

import "./picture-uploader.scss";

type PictureUploaderProps = {
  picture: File;
  pictureUrl: string;
  setPictureUrl: (value: string) => void;
  setPicture: (value: File) => void;
  isCircular: boolean;
  type: number;
  disabled?: boolean;
};

export const ImageUploader = ({
  picture,
  pictureUrl,
  setPictureUrl,
  setPicture,
  isCircular,
  type,
  disabled,
}: PictureUploaderProps) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const removePictureFiles = useCallback(() => {
    setPicture(null!);
    setPictureUrl("");
  }, [setPicture, setPictureUrl]);

  const handleFile = useCallback(
    (file: File | undefined) => {
      if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
        if (file.size <= 5 * 1024 * 1024) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          setPicture(file);
          setPictureUrl(file.name);
          setModalVisible(true);
        } else {
          <InfoMessageModal
            message={TextEnum.THE_PICTURE_FILE_SIZE_IS_TOO_BIG}
            onClose={() => null}
          />;
          removePictureFiles();
        }
      } else {
        removePictureFiles();
      }
    },
    [removePictureFiles, setPicture, setPictureUrl]
  );

  const onSelectFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          setPictureUrl(reader.result?.toString() || "")
        );
        reader.readAsDataURL(e.target.files[0]);
        handleFile(e.target.files[0]);
        e.target.value = "";
      }
    },
    [handleFile, setPictureUrl]
  );

  const handleFileDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (!disabled) {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
        const file = event.dataTransfer.files?.[0];
        handleFile(file);
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          setPictureUrl(reader.result?.toString() || "")
        );
        reader.readAsDataURL(file);
      }
    },
    [handleFile, setPictureUrl, disabled]
  );

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (!disabled) {
        setIsDragOver(true);
      }
    },
    [disabled]
  );

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
  };

  const handleContainerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return useMemo(() => {
    return (
      <div className={`picture-uploader-container `}>
        <div
          className="background-container"
          onDrop={handleFileDrop}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onClick={handleContainerClick}
        >
          {!picture && !pictureUrl ? (
            <button disabled={disabled}>{TextEnum.UPLOAD_PICTURE}</button>
          ) : (
            <button
              className="delete-picture"
              onClick={removePictureFiles}
              disabled={disabled}
              style={disabled ? { visibility: "hidden" } : {}}
            >
              {TextEnum.DELETE_PICTURE}
            </button>
          )}
          <input
            ref={fileInputRef}
            type="file"
            onChange={onSelectFile}
            accept=".png,.jpg,.jpeg"
            disabled={disabled}
          />
          <div
            className={`picture-preview-container ${
              isDragOver ? "drag-over" : ""
            }`}
          >
            {picture && previewCanvasRef.current !== null ? (
              <canvas
                className="picture-preview"
                ref={previewCanvasRef}
                style={{
                  objectFit: "contain",
                }}
              />
            ) : (
              pictureUrl && (
                <img
                  className="picture-preview"
                  src={pictureUrl}
                  alt={pictureUrl}
                />
              )
            )}
          </div>
        </div>
        {modalVisible && !disabled && (
          <PictureCropperModal
            pictureUrl={pictureUrl}
            type={type}
            isCircular={isCircular}
            setPicture={setPicture}
            previewCanvasRef={previewCanvasRef}
            setModalVisible={setModalVisible}
            setPictureUrl={setPictureUrl}
          />
        )}
      </div>
    );
  }, [
    handleFileDrop,
    isCircular,
    isDragOver,
    modalVisible,
    onSelectFile,
    picture,
    pictureUrl,
    previewCanvasRef,
    removePictureFiles,
    setPicture,
    setPictureUrl,
    type,
    disabled,
    handleDragOver,
  ]);
};
