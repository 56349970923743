import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NonWinnerUsers, TopThree } from "../entity";
import { LeaderboardState } from "../state";

const initialState: LeaderboardState = {
  topThree: null,
  errorMessage: null!,
  page: 1,
  searchText: "",
  leaderboardActionStart: false,
  pageCount: null!,
  nonWinnerDatas: [],
  allScoreOrder: "",
  scoreOrder: "",
  childOccasionOrder: "",
  mixedOccasionOrder: "",
  occasionOrder: "",
  inviteSentOrder: "",
  lederboardType: null!,
  selectedIds: [],
  leaderExport: null!,
};

export const leaderboardSlice = createSlice({
  name: "leaderboard",
  initialState,
  reducers: {
    setLeaderboard: (
      state,
      action: PayloadAction<{
        topThree: TopThree;
        pageCount: number;
        nonWinnerDatas: NonWinnerUsers[];
        campaignType: number;
      }>
    ) => {
      state.topThree = action.payload.topThree;
      state.pageCount = action.payload.pageCount;
      state.lederboardType = action.payload.campaignType;
      state.leaderboardActionStart = false;
      let topUsers: NonWinnerUsers[] = [];
      for (const userData of action.payload.nonWinnerDatas) {
        if (
          action.payload.topThree.first === (userData.email || userData.name) ||
          action.payload.topThree.second ===
            (userData.email || userData.name) ||
          action.payload.topThree.third === (userData.email || userData.name)
        ) {
          topUsers.push(userData);
        }
      }
      state.selectedIds = topUsers.map((u) => u.id);
      if (
        state.scoreOrder === "" &&
        state.childOccasionOrder === "" &&
        state.mixedOccasionOrder === "" &&
        state.occasionOrder === "" &&
        state.inviteSentOrder === "" &&
        state.allScoreOrder === ""
      ) {
        topUsers = topUsers.sort((a, b) => b.score! - a.score!);
        state.nonWinnerDatas = [
          ...topUsers,
          ...action.payload.nonWinnerDatas.filter(
            (u) => !topUsers.some((tu) => tu.id === u.id)
          ),
        ];
      } else {
        state.nonWinnerDatas = action.payload.nonWinnerDatas;
      }
    },
    setLeaderboardPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLeaderboardSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
      state.page = 1;
    },
    leaderboardErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.leaderboardActionStart = false;
    },
    leaderboardActionStart: (state, action: PayloadAction<boolean>) => {
      state.leaderboardActionStart = action.payload;
    },
    leaderboardAllScoreOrder: (state, action: PayloadAction<string>) => {
      state.scoreOrder = "";
      state.childOccasionOrder = "";
      state.mixedOccasionOrder = "";
      state.occasionOrder = "";
      state.inviteSentOrder = "";
      state.allScoreOrder = action.payload;
      state.page = 1;
    },
    leaderboardScoreOrder: (state, action: PayloadAction<string>) => {
      state.scoreOrder = action.payload;
      state.childOccasionOrder = "";
      state.mixedOccasionOrder = "";
      state.occasionOrder = "";
      state.inviteSentOrder = "";
      state.allScoreOrder = "";
      state.page = 1;
    },
    leaderboardChildOccasionOrder: (state, action: PayloadAction<string>) => {
      state.childOccasionOrder = action.payload;
      state.scoreOrder = "";
      state.mixedOccasionOrder = "";
      state.occasionOrder = "";
      state.inviteSentOrder = "";
      state.allScoreOrder = "";
      state.page = 1;
    },
    leaderboardMixedOccasionOrder: (state, action: PayloadAction<string>) => {
      state.mixedOccasionOrder = action.payload;
      state.childOccasionOrder = "";
      state.scoreOrder = "";
      state.occasionOrder = "";
      state.inviteSentOrder = "";
      state.allScoreOrder = "";
      state.page = 1;
    },
    leaderboardOccasionOrder: (state, action: PayloadAction<string>) => {
      state.occasionOrder = action.payload;
      state.mixedOccasionOrder = "";
      state.childOccasionOrder = "";
      state.scoreOrder = "";
      state.inviteSentOrder = "";
      state.allScoreOrder = "";
      state.page = 1;
    },
    leaderboardInviteSentOrder: (state, action: PayloadAction<string>) => {
      state.inviteSentOrder = action.payload;
      state.occasionOrder = "";
      state.mixedOccasionOrder = "";
      state.childOccasionOrder = "";
      state.scoreOrder = "";
      state.allScoreOrder = "";
      state.page = 1;
    },

    setLeaderboardNonWinnerData: (
      state,
      action: PayloadAction<NonWinnerUsers[]>
    ) => {
      state.leaderExport = action.payload;
    },
    resetLeaderboard: (state) => {
      state.inviteSentOrder = "";
      state.occasionOrder = "";
      state.mixedOccasionOrder = "";
      state.childOccasionOrder = "";
      state.scoreOrder = "";
      state.allScoreOrder = "";
      state.page = 1;
      state.selectedIds = [];
      state.topThree = null;
      state.searchText = "";
      state.pageCount = null!;
      state.nonWinnerDatas = [];
      state.lederboardType = null!;
      state.leaderExport = null!;
    },
  },
});

export const {
  setLeaderboard,
  setLeaderboardPage,
  setLeaderboardSearchText,
  leaderboardErrorMessage,
  leaderboardActionStart,
  leaderboardAllScoreOrder,
  leaderboardScoreOrder,
  leaderboardChildOccasionOrder,
  leaderboardMixedOccasionOrder,
  leaderboardOccasionOrder,
  leaderboardInviteSentOrder,
  setLeaderboardNonWinnerData,
  resetLeaderboard,
} = leaderboardSlice.actions;
