import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { payment } from "../../actions/payment";
import { resetPayment } from "../reducers/payment.reducer";

export const paymentLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetPayment());

  return await payment();
};
