import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { getAllAdmin } from "../../actions/admin";
import { resetAdmin } from "../reducers/admin.reducer";

export const adminLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetAdmin());
  
  return await getAllAdmin();
};
