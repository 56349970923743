import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { setBadgeErrorMessage } from "../../constants/reducers/badge.reducer";
import { store } from "../../store";
import { ModifyBadgeDTO } from "../../constants/dto/badge";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { setAdminActionStart } from "../../constants/reducers/admin.reducer";

let body: ModifyBadgeDTO;

export const modifyBadge = async () => {
  store.dispatch(setAdminActionStart(true));

  const { picture, name, type, badge, description, active } =
    store.getState().badge;

  const formData = new FormData();
  if (picture) {
    const convertedFile = picture;
    formData.append("picture", convertedFile);
  }
  formData.append("name", name);
  formData.append("description", description);
  formData.append("type", type!.toString());
  formData.append("id", badge.id.toString());
  formData.append("active", active.toString());

  const request = await createCall(
    "cms/badges/modify",
    BASE_METHODS.PUT,
    "multipart/form-data",
    "1.0.0",
    formData
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(ModifyBadgeDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setBadgeErrorMessage(TextEnum.MODIFY_BADGE_INVALID_RESPONSE)
      );
      return;
    }
    store.dispatch(setAdminActionStart(false));
    return request;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 10001:
          errorMessage = TextEnum.GET_ONE_BADGE_BADGE_NOT_FOUND;
          break;
        default:
          break;
      }
      store.dispatch(setBadgeErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setAdminActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
