import React, { useState, useEffect } from "react";

import "./success.message-modal.scss";

interface ModalProps {
  message: string;
  onClose: () => void;
}

export const InfoMessageModal: React.FC<ModalProps> = ({
  message,
  onClose,
}) => {
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(false);
      onClose();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return (
    <div className={`info-modal ${showModal ? "show" : "hide"}`}>
      <div className="info-modal-content">
        <span className="info-close" onClick={() => setShowModal(false)}>
          &times;
        </span>
        <p>{message}</p>
      </div>
    </div>
  );
};
