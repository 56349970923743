import { useCallback } from "react";
import { getOneBadge } from "../../actions/badge";
import { useAppNavigate, useAppParams } from "../global-hooks";
import {
  resetBadge,
  setBadgeActive,
  setBadgeDescription,
  setBadgeErrorMessage,
  setBadgeName,
  setBadgePicture,
  setBadgePictureUrl,
} from "../reducers/badge.reducer";

export const useHandleBadgeNavigateBack = (dispatch: Function) => {
  const navigate = useAppNavigate();
  const handleNavigateBack = useCallback(() => {
    navigate("/badge");
    dispatch(resetBadge());
  }, [dispatch, navigate]);
  return handleNavigateBack;
};

export const useHandleBadgeNameChange = (dispatch: Function) => {
  const handleNameChange = useCallback(
    (value: string) => {
      dispatch(setBadgeName(value));
    },
    [dispatch]
  );
  return handleNameChange;
};

export const useHandleBadgeDescriptionChange = (dispatch: Function) => {
  const handleDescriptionChange = useCallback(
    (value: string) => {
      dispatch(setBadgeDescription(value));
    },
    [dispatch]
  );
  return handleDescriptionChange;
};

export const useHandleBadgeFromParams = () => {
  const params = useAppParams();
  const handleGetBadge = useCallback(async () => {
    if (params.id) await getOneBadge({ id: parseInt(params.id) });
  }, [params.id]);
  return handleGetBadge;
};

export const useHandleBadgeAlertMessage = (dispatch: Function) => {
  const handleAllertMessage = useCallback(
    (message: string | null) => {
      dispatch(setBadgeErrorMessage(message!));
    },
    [dispatch]
  );
  return handleAllertMessage;
};

export const useHandleBadgeSetPictureUrl = (dispatch: Function) => {
  const setPictureUrl = useCallback(
    (value: string) => {
      dispatch(setBadgePictureUrl(value));
    },
    [dispatch]
  );
  return setPictureUrl;
};
export const useHandleBadgeSetPicture = (dispatch: Function) => {
  const setPicture = useCallback(
    (value: File) => {
      dispatch(setBadgePicture(value));
    },
    [dispatch]
  );
  return setPicture;
};

export const useHandleBadgeDeciderChange = (setState: Function) => {
  const handleDecider = useCallback(
    (decider: boolean) => {
      setState((prevState: any) => ({ ...prevState, decider }));
    },
    [setState]
  );
  return handleDecider;
};

export const useHandleBadgeActiveChange = (dispatch: Function) => {
  const handleDecider = useCallback(
    (value: boolean) => {
      dispatch(setBadgeActive(value));
    },
    [dispatch]
  );
  return handleDecider;
};
