import { useEffect, useMemo, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { TextBox } from "../../constants/features/input";
import { InfoMessageModal } from "../../constants/features/modal";
import { forgotPasswordModifyPassword } from "../../actions/forgot-password";
import { useAppDispatch, useAppSelector } from "../../constants/global-hooks";
import { setErrorMessage } from "../../constants/reducers/forgot.password.reducer";
import {
  useHandleForgotPasswordPasswordChange,
  useHandleForgotPasswordPasswordCheckChange,
} from "../../constants/utils";

import "./forgot-password-confirm.scss";

export const ForgotPasswordConfirmPage = () => {
  const dispatch = useAppDispatch();
  const {
    isTokenValid,
    password,
    passwordCheck,
    errorMessage,
    forgotPasswordActionStart,
  } = useAppSelector((state: RootState) => state.forgotPassword);

  const [state, setState] = useState({
    loading: true,
    buttonDisabler: true,
  });
  const handlePwChange = useHandleForgotPasswordPasswordChange(dispatch);
  const handlePwCheckChange =
    useHandleForgotPasswordPasswordCheckChange(dispatch);

  const PASSWORD_REGEX = useMemo(() => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  }, []);

  useEffect(() => {
    if (isTokenValid || isTokenValid === undefined || isTokenValid === null) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  }, [isTokenValid]);

  const handleConfirm = async () => {
    await forgotPasswordModifyPassword();
  };

  useEffect(() => {
    setState((prevState) => ({ ...prevState, buttonDisabler: true }));
    if (PASSWORD_REGEX.test(password) && password === passwordCheck) {
      setState((prevState) => ({ ...prevState, buttonDisabler: false }));
    }
  }, [PASSWORD_REGEX, password, passwordCheck]);

  const handleModalClose = () => {
    dispatch(setErrorMessage(null));
  };

  return (
    <div>
      {state.loading || forgotPasswordActionStart ? (
        <div>
          <LoadingOutlined />
        </div>
      ) : isTokenValid !== null && isTokenValid !== undefined ? (
        <div className="container">
          <div className="forgot-password-input">
            <div className="inputContainer">
              <TextBox
                placeholder={TextEnum.EMAIL_TEXT}
                value={isTokenValid.email}
                disabled={true}
                type="input"
                setDecider={() => {}}
                customErrorMessage=""
                onChange={() => {}}
              />
            </div>
            <div className="inputContainer">
              <TextBox
                placeholder={TextEnum.PASSWORD_TEXT}
                value={password}
                type="input"
                setDecider={() => {}}
                customErrorMessage=""
                onChange={handlePwChange}
                isPassword={true}
              />
            </div>
            <div className="inputContainer">
              <TextBox
                placeholder={TextEnum.PROFILE_PASSWORD_CHECK}
                value={passwordCheck}
                type="input"
                setDecider={() => {}}
                customErrorMessage=""
                onChange={handlePwCheckChange}
                isPassword={true}
              />
            </div>
          </div>

          <button
            className={`forgot-password-button ${
              state.buttonDisabler ? "disabled" : ""
            }`}
            onClick={handleConfirm}
            disabled={state.buttonDisabler}
          >
            {TextEnum.SAVE_BUTTON}
          </button>
          {errorMessage !== null && (
            <InfoMessageModal
              message={TextEnum.FORGOT_PASSWORD_CONFIRM_MODAL_MESSAGE}
              onClose={handleModalClose}
            />
          )}
        </div>
      ) : (
        <div className="forgot-password-title">
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};
