import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { Menu } from "../../constants/features/menu";
import { Header } from "../../constants/features/header";
import { MessageModal } from "../../constants/features/modal";
import { loginOk } from "../../constants/reducers/login.reducer";
import { ConfirmMessage } from "../../constants/features/message";
import { clearError } from "../../constants/reducers/base.reducer";
import {
  getMeFailure,
  setActiveConfirmMessage,
} from "../../constants/reducers/session.reducer";
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from "../../constants/global-hooks";

import "./default.layout.scss";
import { ROLE_TYPE_ENUM } from "../../constants/enum";

export const DefaultLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { activeConfirmMessage, authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );
  const { errorMessage, errorTitle } = useAppSelector(
    (state: RootState) => state.base
  );

  useEffect(() => {
    dispatch(loginOk());
  }, [dispatch]);

  if (authenticatedUser?.firstLogin) {
    return <Navigate to={"/first-login"} />;
  }

  if (
    authenticatedUser?.role.id !== ROLE_TYPE_ENUM.ADMIN &&
    authenticatedUser?.role.id !== ROLE_TYPE_ENUM.SUPER_ADMIN &&
    authenticatedUser?.role.id !== ROLE_TYPE_ENUM.MOBILE_ADMIN
  ) {
    return <Navigate to={"/login"} />;
  }
  
  return (
    <div>
      <Header />
      <Menu />
      <Outlet />

      {errorMessage ? (
        <MessageModal
          title={errorTitle ?? ""}
          message={errorMessage}
          onCloseClick={() => {
            if (errorMessage === TextEnum.NO_RIGHTS) {
              navigate("/login");
              dispatch(getMeFailure());
            }
            dispatch(clearError());
          }}
        />
      ) : (
        ""
      )}

      {activeConfirmMessage !== null ? (
        <ConfirmMessage
          message={activeConfirmMessage}
          onClose={() => dispatch(setActiveConfirmMessage(null))}
        />
      ) : null}
    </div>
  );
};
