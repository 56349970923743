import { useCallback } from "react";
import { deleteUsers, getAllUsers } from "../../actions/user";
import {
  resetUsers,
  setSearchCity,
  setSelectedIds,
  setUserCreateOrder,
  setUserErrorMessage,
  setUserNameOrder,
  setUserPage,
  setUserSerachText,
} from "../reducers/user.reducer";

export const useHandleUserpageChange = (
  dispatch: Function,
  setSearchTerm: Function
) => {
  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(setUserPage(page));
      setSearchTerm(false);
    },
    [dispatch, setSearchTerm]
  );
  return handlePageChange;
};

export const useHandleUserAlertMessage = (dispatch: Function) => {
  const handleAlertMessage = useCallback(
    (message: string) => {
      dispatch(setUserErrorMessage(message));
    },
    [dispatch]
  );
  return handleAlertMessage;
};

export const useHandleGetUsers = () => {
  const getUsers = useCallback(async () => {
    await getAllUsers();
  }, []);

  return getUsers;
};

export const useHandleDeleteUsers = (dispatch: Function) => {
  const deleteButtonAction = useCallback(async () => {
    await deleteUsers();
    dispatch(resetUsers());
  }, [dispatch]);

  return deleteButtonAction;
};

export const useHandleWannabeDeletedUsers = (dispatch: Function) => {
  const handleWannabeDeletedUsers = useCallback(
    (record: number[]) => {
      dispatch(setSelectedIds(record));
    },
    [dispatch]
  );

  return handleWannabeDeletedUsers;
};

export const useHandleUserSearchText = (dispatch: Function) => {
  const handleSearchChange = useCallback(
    (value: string) => {
      dispatch(setUserSerachText(value));
    },
    [dispatch]
  );
  return handleSearchChange;
};

export const useHandleUserNameOrderChange = (dispatch: Function) => {
  const handleNameOrderChange = useCallback(
    (event: string) => {
      dispatch(setUserNameOrder(event));
    },
    [dispatch]
  );
  return handleNameOrderChange;
};

export const useHandleUserCreatedOrderChange = (dispatch: Function) => {
  const handleCreateOrderChange = useCallback(
    (event: string) => {
      dispatch(setUserCreateOrder(event));
    },
    [dispatch]
  );
  return handleCreateOrderChange;
};

export const useHandleUserSearchCityChange = (dispatch: Function) => {
  const handleSearchCityChange = useCallback(
    (event: string) => {
      dispatch(setSearchCity(event));
    },
    [dispatch]
  );
  return handleSearchCityChange;
};
