import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import {
  addAdminToAdmins,
  setAdminActionStart,
  setAdminErrorMessage,
} from "../../constants/reducers/admin.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { SuccessDTO } from "../../constants/dto/success";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

let body: SuccessDTO;
export const createAdmin = async () => {
  store.dispatch(setAdminActionStart(true));
  const { email, type } = store.getState().admin;

  const params = {
    email: email.trim(),
    type,
  };

  const request = await createCall(
    "cms/admins/registration",
    BASE_METHODS.POST,
    "application/json",
    "1.0.0",
    params
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(SuccessDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      setAdminErrorMessage(TextEnum.CREATE_ADMIN_INVALID_RESPONSE);
      return;
    }
    store.dispatch(addAdminToAdmins(response.data));
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 1003:
          errorMessage = TextEnum.USER_WITH_THIS_EMAIL_ALREADY_EXIST;
          break;
        default:
          break;
      }
      store.dispatch(setAdminErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setAdminActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
