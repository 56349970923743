import { store } from "../../store";
import { getMeOk } from "../../constants/reducers/session.reducer";
import { createCall } from "../base";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { AxiosResponse } from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import {
  setAdminActionStart,
  setAdminErrorMessage,
} from "../../constants/reducers/admin.reducer";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { GetMeDTO } from "../../constants/dto/admin";

let body: GetMeDTO;
export const firstLogin = async () => {
  store.dispatch(setAdminActionStart(true));
  const { password } = store.getState().admin;
  const params = {
    password,
  };
  const request = await createCall(
    "cms/admins/first-login",
    BASE_METHODS.PUT,
    "application/json",
    "1.0.0",
    params,
    undefined
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetMeDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setAdminErrorMessage(TextEnum.FISRT_LOGIN_ADMIN_INVALID_RESPONSE)
      );
      return;
    }
    store.dispatch(getMeOk({ user: response.data.user }));
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 1001:
          errorMessage = TextEnum.FISRT_LOGIN_ADMIN_USER_NOT_FOUND;
          break;
        default:
          break;
      }
      store.dispatch(setAdminErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setAdminActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
