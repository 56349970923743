import { useState, useEffect } from "react";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { firstLogin } from "../../actions/admin";
import { TextBox } from "../../constants/features/input";
import { MessageModal } from "../../constants/features/modal";
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from "../../constants/global-hooks";
import {
  useHandlAdminPwCheckChange,
  useHandleAdminAlertMessage,
  useHandleAdminDeciderChange,
  useHandleAdminPasswordChange,
} from "../../constants/utils";
import { ROLE_TYPE_ENUM } from "../../constants/enum";

export const FirstLoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { password, errorMessage } = useAppSelector(
    (state: RootState) => state.admin
  );
  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );

  const [state, setState] = useState({
    pwCheck: "",
    decider: true,
    doubleCheck: true,
    clicked: false,
  });

  const handleErrorMessageChange = useHandleAdminAlertMessage(dispatch);
  const handlePwCheckChange = useHandlAdminPwCheckChange(setState);
  const handleSave = async () => {
    await firstLogin();
    setState((prevState) => ({ ...prevState, clicked: true }));
  };

  useEffect(() => {
    setState((prevState) => ({ ...prevState, doubleCheck: true }));
    if (password !== "" && state.pwCheck !== "") {
      if (password === state.pwCheck) {
        setState((prevState) => ({ ...prevState, doubleCheck: false }));
      }
    } else {
      setState((prevState) => ({ ...prevState, doubleCheck: true }));
    }
  }, [password, state.pwCheck]);

  useEffect(() => {
    if (errorMessage === null && state.clicked) {
      setState((prevState) => ({ ...prevState, clicked: false }));
      if (authenticatedUser?.role.id === ROLE_TYPE_ENUM.MOBILE_ADMIN) {
        navigate("/version");
      } else if (
        authenticatedUser?.role.id === ROLE_TYPE_ENUM.SUPER_ADMIN ||
        authenticatedUser?.role.id === ROLE_TYPE_ENUM.ADMIN
      ) {
        navigate("/campaign");
      }
    }
  }, [errorMessage, state.clicked, authenticatedUser?.role.id, navigate]);

  return (
    <div className="container">
      <div className="inputContainer">
        <TextBox
          value={password}
          onChange={useHandleAdminPasswordChange(dispatch)}
          type="input"
          isPassword={true}
          setDecider={useHandleAdminDeciderChange(setState)}
          customErrorMessage={""}
          placeholder={TextEnum.PASSWORD_TEXT}
        />
      </div>
      <div className="inputContainer">
        <TextBox
          value={state.pwCheck}
          onChange={handlePwCheckChange}
          type="input"
          isPassword={true}
          setDecider={useHandleAdminDeciderChange(setState)}
          customErrorMessage={""}
          placeholder={TextEnum.PROFILE_PASSWORD_CHECK}
        />
      </div>
      <button
        className={`forgot-password-button ${state.doubleCheck ? "disabled" : ""
          }`}
        onClick={handleSave}
        disabled={state.doubleCheck}
      >
        {TextEnum.SAVE_BUTTON}
      </button>
      {errorMessage !== null && (
        <MessageModal
          title={TextEnum.ERROR}
          message={errorMessage}
          onCloseClick={handleErrorMessageChange}
        />
      )}
    </div>
  );
};
