import { useState, useEffect } from "react";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { TextBox } from "../../constants/features/input";
import { MessageModal } from "../../constants/features/modal";
import { createBadge, modifyBadge } from "../../actions/badge";
import { ImageUploader } from "../../constants/features/picture";
import { CustomSelector } from "../../constants/features/selector";
import { CustomBreadCrumb } from "../../constants/features/breadcrumb";
import {
  useAppDispatch,
  useAppParams,
  useAppSelector,
} from "../../constants/global-hooks";
import {
  useHandleBadgeAlertMessage,
  useHandleBadgeDeciderChange,
  useHandleBadgeFromParams,
  useHandleBadgeNameChange,
  useHandleBadgeNavigateBack,
  useHandleBadgeSetPicture,
  useHandleBadgeSetPictureUrl,
} from "../../constants/utils";
import { Switch, Tooltip } from "antd";

import "./create-badge.scss";
import {
  useHandleBadgeActiveChange,
  useHandleBadgeDescriptionChange,
} from "../../constants/utils/badge-utils";

export const CreateBadgePage = () => {
  const params = useAppParams();
  const dispatch = useAppDispatch();
  const {
    badge,
    name,
    description,
    type,
    picture,
    errorMessage,
    badgeActionStart,
    pictureUrl,
    active,
  } = useAppSelector((state: RootState) => state.badge);

  const [state, setState] = useState({
    clicked: false,
    decider: true,
  });

  const handleNavigateBack = useHandleBadgeNavigateBack(dispatch);
  const getBadge = useHandleBadgeFromParams();
  const setDecider = useHandleBadgeDeciderChange(setState);

  useEffect(() => {
    if (params.id) {
      getBadge();
    }
  }, [params, getBadge]);

  const handleAlertMessage = useHandleBadgeAlertMessage(dispatch);

  const handleSave = async () => {
    handleAlertMessage(null!);
    if (badge) {
      await modifyBadge();
    } else {
      await createBadge();
    }
    setState((prevState) => ({ ...prevState, clicked: true }));
  };

  useEffect(() => {
    if (state.clicked && errorMessage === null && !badgeActionStart) {
      handleNavigateBack();
    }
    setState((prevState) => ({ ...prevState, clicked: false }));
  }, [handleNavigateBack, state.clicked, errorMessage, badgeActionStart]);

  useEffect(() => {
    if (name && description && picture && type) {
      setDecider(false);
    } else {
      setDecider(true);
    }
  }, [name, description, picture, type, setDecider]);

  return (
    <main className="main-page">
      <div>
        <div className="title">
          {badge ? TextEnum.MODIFY : TextEnum.CREATION_MODAL_TITLE}
        </div>
      </div>

      <CustomBreadCrumb
        title={TextEnum.BADGE_PAGE_TITLE}
        onClick={handleNavigateBack}
        modifyTitle={TextEnum.MODIFY_BADGE_PAGE_TITLE}
        createTitle={TextEnum.CREATE_BADGE_PAGE_TITLE}
        modify={badge ? true : false}
      />
      <div className="container">
        <div className="uploader-with-info">
          <ImageUploader
            type={1}
            picture={picture}
            pictureUrl={pictureUrl}
            setPictureUrl={useHandleBadgeSetPictureUrl(dispatch)}
            setPicture={useHandleBadgeSetPicture(dispatch)}
            isCircular={true}
          />
          <Tooltip
            style={{ position: "absolute", top: 0 }}
            title={TextEnum.CREATE_BADGE_INFO_TOOLTIP}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>

        <div className="inputContainer">
          <TextBox
            onChange={useHandleBadgeNameChange(dispatch)}
            value={name}
            type="input"
            customErrorMessage={TextEnum.NAME_REQUIRED_ERROR_MESSAGE}
            setDecider={setDecider}
            placeholder={TextEnum.BADGE_NAME_TITLE}
          />
        </div>
        <div className="inputContainer">
          <TextBox
            onChange={useHandleBadgeDescriptionChange(dispatch)}
            value={description}
            type="input"
            customErrorMessage={TextEnum.DESCRIPTION_REQUIRED_ERROR_MESSAGE}
            setDecider={setDecider}
            placeholder={TextEnum.BADGE_DESCRIPTION_TITLE}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label className="switch-button-label">{TextEnum.ACTIVATION}</label>
          <Switch
            className="switch-button"
            onChange={useHandleBadgeActiveChange(dispatch)}
            checked={active}
          />
        </div>

        <CustomSelector />
        <button
          disabled={badgeActionStart || state.decider}
          className={`create-badge-button ${
            state.decider || badgeActionStart ? "disabled" : ""
          }`}
          onClick={handleSave}
        >
          {badgeActionStart ? <LoadingOutlined /> : TextEnum.SAVE_BUTTON}
        </button>
      </div>
      {errorMessage && (
        <MessageModal
          title=""
          message={errorMessage}
          onCloseClick={handleAlertMessage}
        />
      )}
    </main>
  );
};
