import { useMemo } from "react";
import { Team } from "../../entity";
import { TextEnum } from "../../text";
import { CustomOrder } from "../table";
import { RootState } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../global-hooks";
import {
  useHandleTeamCreatedOrderChange,
  useHandleTeamNameOrderChange,
} from "../../utils";

export const TeamColumns = () => {
  const dispatch = useAppDispatch();
  const { companyTeam, nameOrder, createdOrder } = useAppSelector(
    (state: RootState) => state.team
  );

  const handleNameOrderChange = useHandleTeamNameOrderChange(dispatch);
  const handleCreatedOrderChange = useHandleTeamCreatedOrderChange(dispatch);

  const columns = useMemo(() => {
    return [
      {
        title: (
          <div className="inline-container">
            {TextEnum.NAME}

            <CustomOrder
              orderFunction={handleNameOrderChange}
              order={nameOrder}
            />
          </div>
        ),
        key: TextEnum.NAME,
        dataIndex: "name",
        width: "350px",
      },
      {
        title: TextEnum.TEAM_LEADER,
        key: TextEnum.TEAM_LEADER,
        dataIndex: "creator",
        width: "350px",
      },
      {
        title: TextEnum.COMPANY_NAME,
        key: TextEnum.COMPANY_NAME,
        render: (text: string, record: Team) => {
          return record.company?.name;
        },
        width: "350px",
      },
      {
        title: (
          <div className="inline-container">
            {TextEnum.CREATED}
            <CustomOrder
              orderFunction={handleCreatedOrderChange}
              order={createdOrder}
            />
          </div>
        ),
        key: TextEnum.CREATED,
        dataIndex: "created",
        render: (text: string, record: Team) => {
          return new Date(record.created).toLocaleDateString();
        },
        width: "150px",
      },
    ];
  }, [
    handleNameOrderChange,
    handleCreatedOrderChange,
    createdOrder,
    nameOrder,
  ]);

  if (!companyTeam) {
    return columns.filter((col) => col.key !== TextEnum.COMPANY_NAME);
  }
  return columns;
};
