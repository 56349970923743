import "./company.scss";
import { useCallback, useEffect, useState } from "react";
import { DataTable } from "../../constants/features/table";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "../../constants/global-hooks";
import { RootState } from "../../store";
import { MessageModal, Modal } from "../../constants/features/modal";
import { TextEnum } from "../../constants/text";
import { getAllCompany, resendCompanyCode } from "../../actions/company";
import { CompanyColumns } from "../../constants/features/columns";
import { Company } from "../../constants/entity";
import { useNavigate } from "react-router-dom";
import { TextBox } from "../../constants/features/input";
import {
  useHandleCompanyAlertMessageChange,
  useHandleCompanyCreatorEmailChange,
  useHandleCompanyPageChange,
  useHandleCompanySearchTextChange,
} from "../../constants/utils";
import {
  resetCompany,
  setCompanySelectedId,
} from "../../constants/reducers/company.reducer";

export const CompanyPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { page, errorMessage, pageCount, companies, searchText, email } =
    useAppSelector((state: RootState) => state.company);
  const [searchTerm, setSearchTerm] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleAlertMessage = useHandleCompanyAlertMessageChange(dispatch);
  const handleEmailChange = useHandleCompanyCreatorEmailChange(dispatch);
  useEffect(() => {
    if (searchText) {
      setSearchTerm(true);
    }
  }, [searchText]);

  const getCompanies = useCallback(async () => {
    await getAllCompany();
  }, []);

  const debouncedGetCompanies = useDebounce(getCompanies, searchTerm ? 500 : 0);

  useEffect(() => {
    debouncedGetCompanies();
  }, [debouncedGetCompanies, page, searchText]);

  const handleModify = useCallback(
    (record: Company) => {
      navigate(`/company/modify/${record.id}`);
    },
    [navigate]
  );

  const handleModalVisible = (id?: number) => {
    if (modalVisible === false) {
      dispatch(resetCompany());
    }
    setModalVisible(!modalVisible);
    if (id) {
      dispatch(setCompanySelectedId(id));
    }
  };
  const resendCode = async () => {
    await resendCompanyCode();
    setClicked(true);
  };

  useEffect(() => {
    if (clicked && errorMessage === null) {
      setModalVisible(false);
      dispatch(resetCompany());
    }
    setClicked(false);
  }, [errorMessage, clicked, dispatch]);

  return (
    <main className="main-page">
      <div className="title">{TextEnum.COMPANY_PAGE_TITLE}</div>
      <div className="company-buttons-container">
        <TextBox
          value={searchText}
          onChange={useHandleCompanySearchTextChange(dispatch)}
          type={"input"}
          customErrorMessage={""}
          setDecider={() => null}
          placeholder={TextEnum.SEARCH}
          search={true}
        />
        <button
          className="create-company-button"
          onClick={() => navigate("/company/create")}
        >
          {TextEnum.COMPANY_CREATION_BUTTON}
        </button>
      </div>
      <DataTable
        dataSource={companies}
        columns={Object.values(CompanyColumns({ handleModalVisible }))}
        maxPage={pageCount}
        currentPage={page}
        setCurrentPage={useHandleCompanyPageChange(dispatch, setSearchTerm)}
        onRowClick={handleModify}
      />
      {errorMessage && (
        <MessageModal
          title=""
          message={errorMessage}
          onCloseClick={handleAlertMessage}
        />
      )}
      {modalVisible && (
        <Modal onModalClose={handleModalVisible} className="companyModal">
          <button className="close-button" onClick={() => handleModalVisible()}>
            X
          </button>
          <div className="container">
            <div className="inputContainer">
              <TextBox
                value={email}
                onChange={handleEmailChange}
                customErrorMessage={""}
                isEmail={true}
                type="input"
                setDecider={() => null}
                placeholder={TextEnum.EMAIL}
              />
            </div>
          </div>
          <div className="button-container">
            <button className="resend-company-code-button" onClick={resendCode}>
              Küldés
            </button>
          </div>
        </Modal>
      )}
    </main>
  );
};
