import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { GetOneCampaignDTO } from "../../constants/dto/campaign";
import {
  setCampaign,
  setCampaignActionStart,
  setCampaignErrorMessage,
} from "../../constants/reducers/campaign.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

type GetOneCampaignProp = {
  id: number;
};

let body: GetOneCampaignDTO;

export const getCampaign = async ({ id }: GetOneCampaignProp) => {
  store.dispatch(setCampaignActionStart(true));
  const queryParams = new URLSearchParams();
  if (id) queryParams.append("id", id.toString());
  const request = await createCall(
    "cms/campaigns/get-one",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );
  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetOneCampaignDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setCampaignErrorMessage(TextEnum.GET_ONE_CAMPAIGN_INVALID_RESPONSE)
      );
      return;
    }

    store.dispatch(setCampaign({ campaign: response.data.campaign }));
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 8002:
          errorMessage = TextEnum.GET_ONE_CAMPAIGN_CAMPAIGN_NOT_FOUND;
          break;

        default:
          break;
      }
      store.dispatch(setCampaignErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setCampaignActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
