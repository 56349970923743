import { Expose } from "class-transformer";
import { IsDateString, IsString } from "class-validator";

export class GetAllTeamXlsxDataDTO {
  @Expose()
  @IsString()
  name!: string;

  @Expose()
  @IsString()
  creator!: string;

  @Expose()
  @IsDateString()
  created!: string;

  @Expose()
  @IsString()
  company!: string;

  @Expose()
  @IsString()
  users!: string;
}
