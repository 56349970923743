import { createBrowserRouter } from "react-router-dom";
import { UserPage } from "../../components/user";
import { LoginLayout } from "../../layout/login";
import { TeamPage } from "../../components/team";
import { LoginPage } from "../../components/login";
import { teamLoader } from "../loader/team-loader";
import { DefaultLayout } from "../../layout/default";
import { PaymentPage } from "../../components/payment";
import { VerisonPage } from "../../components/version";
import { ProfilePage } from "../../components/profile";
import { paymentLoader } from "../loader/payment-loader";
import { NotFoundPage } from "../../components/not-found";
import { FirstLoginPage } from "../../components/first-login";
import { LeaderboardPage } from "../../components/leaderboard";
import { AdminPage, CreateAdminPage } from "../../components/admin";
import { BadgePage, CreateBadgePage } from "../../components/badge";
import { CompanyPage, CreateCompanyPage } from "../../components/company";
import { CampaignPage, CreateCampaignPage } from "../../components/campaign";
import {
  ForgotPasswordConfirmPage,
  ForgotPasswordPage,
} from "../../components/forgot-password";
import {
  RequireAuth,
  RequireMobileAdminAuth,
  RequireSuperAdminAuth,
} from "../../components/require-auth";
import {
  ForgotPasswordConfirmLoader,
  adminLoader,
  badgeLoader,
  badgeTypeLoader,
  campaignLoader,
  companyLoader,
  predictiveLoader,
  userLoader,
  versionLoader,
} from "../loader";

export const router = createBrowserRouter([
  {
    element: (
      <RequireAuth>
        <DefaultLayout />
      </RequireAuth>
    ),
    errorElement: null,
    path: "/",
    children: [
      {
        element: <CampaignPage />,
        loader: campaignLoader,
        path: `campaign`,
      },
      {
        element: <CreateCampaignPage />,
        loader: predictiveLoader,
        path: "campaign/create",
      },
      {
        element: <CreateCampaignPage />,
        loader: predictiveLoader,
        path: "campaign/modify/:id",
      },
      {
        element: <LeaderboardPage />,
        path: "campaign/modify/:id/leaderboard",
      },
      {
        element: <CompanyPage />,
        loader: companyLoader,
        path: `company`,
      },
      {
        element: <CreateCompanyPage />,
        path: `company/create`,
      },
      {
        element: <CreateCompanyPage />,
        path: `company/modify/:id`,
      },
      {
        element: <BadgePage />,
        loader: badgeLoader,
        path: `badge`,
      },
      {
        element: <CreateBadgePage />,
        loader: badgeTypeLoader,
        path: `badge/create`,
      },
      {
        element: <CreateBadgePage />,
        loader: badgeTypeLoader,
        path: `badge/modify/:id`,
      },
      {
        element: <UserPage />,
        loader: userLoader,
        path: `user`,
      },
      {
        element: <TeamPage />,
        loader: teamLoader,
        path: `team`,
      },
      {
        element: (
          <RequireSuperAdminAuth>
            <AdminPage />
          </RequireSuperAdminAuth>
        ),
        loader: adminLoader,
        path: `admin`,
      },
      {
        element: (
          <RequireSuperAdminAuth>
            <CreateAdminPage />
          </RequireSuperAdminAuth>
        ),
        path: `admin/create`,
      },
      {
        element: (
          <RequireMobileAdminAuth>
            <VerisonPage />
          </RequireMobileAdminAuth>
        ),
        loader: versionLoader,
        path: `version`,
      },
      {
        element: <ProfilePage />,
        path: `profile`,
      },
    ],
  },
  {
    element: <LoginLayout />,
    errorElement: null,
    path: "/",
    children: [
      {
        element: <LoginPage />,
        path: `login`,
      },
      {
        element: <ForgotPasswordPage />,
        path: `forgot-password`,
      },
      {
        element: <FirstLoginPage />,
        path: `first-login`,
      },
      {
        element: <ForgotPasswordConfirmPage />,
        loader: ForgotPasswordConfirmLoader,
        path: `forgot-password-confirm`,
      },
    ],
  },
  {
    element: <PaymentPage />,
    loader: paymentLoader,
    path: `paypal`,
  },
  {
    element: <PaymentPage />,
    path: `paypal/canceled`,
  },
  {
    element: <NotFoundPage />,
    path: "*",
  },
]);
