import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { getAllCityOfUsers, getAllUsers } from "../../actions/user";
import { resetUsers } from "../reducers/user.reducer";

export const userLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetUsers());
  await getAllCityOfUsers();
  return await getAllUsers();
};
