import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { store } from "./store";
import { router } from "./constants/router";
import { getMe } from "./actions/admin/getMe";
import { useAppDispatch } from "./constants/global-hooks";

import "./App.css";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!window.location.href.includes("forgot-password"))
      dispatch(getMe);
  }, [dispatch]);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>
  );
}

export default App;
