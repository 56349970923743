import { useCallback } from "react";
import { deleteTeams, getAllTeam } from "../../actions/team";
import {
  resetTeams,
  setCompanyTeam,
  setCreatedOrder,
  setNameOrder,
  setSelectedIds,
  setTeamErrorMessage,
  setTeamPage,
  setTeamSearchText,
} from "../reducers/team.reducer";

export const useHandleTeamPageChange = (
  dispatch: Function,
  setSearchTerm: Function
) => {
  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(setTeamPage(page));
      setSearchTerm(false);
    },
    [dispatch, setSearchTerm]
  );
  return handlePageChange;
};

export const useHandleTeamAlertMessage = (dispatch: Function) => {
  const handleAlertMessage = useCallback(
    (message: string) => {
      dispatch(setTeamErrorMessage(message));
    },
    [dispatch]
  );

  return handleAlertMessage;
};

export const useHandleGetTeams = () => {
  const getTeams = useCallback(async () => {
    await getAllTeam();
  }, []);
  return getTeams;
};

export const useHandleDeleteTeams = (dispatch: Function) => {
  const deleteButtonAction = useCallback(async () => {
    await deleteTeams();
    dispatch(resetTeams());
  }, [dispatch]);
  return deleteButtonAction;
};

export const useHandleWannapeDeletedTeams = (dispatch: Function) => {
  const handleWannabeDeletedTeams = useCallback(
    (record: number[]) => {
      dispatch(setSelectedIds(record));
    },
    [dispatch]
  );

  return handleWannabeDeletedTeams;
};

export const useHandleTeamSearchChange = (dispatch: Function) => {
  const handleSearchChange = useCallback(
    (value: string) => {
      dispatch(setTeamSearchText(value));
    },
    [dispatch]
  );
  return handleSearchChange;
};

export const useHandleChangeTeamType = (
  dispatch: Function,
  setSearchTerm: Function
) => {
  const handleChangeTeamType = useCallback(
    (value: boolean) => {
      dispatch(setCompanyTeam(value));
      setSearchTerm(false);
    },
    [dispatch, setSearchTerm]
  );
  return handleChangeTeamType;
};

export const useHandleTeamNameOrderChange = (dispatch: Function) => {
  const handleNameOrderChange = useCallback(
    (event: string) => {
      dispatch(setNameOrder(event));
    },
    [dispatch]
  );
  return handleNameOrderChange;
};

export const useHandleTeamCreatedOrderChange = (dispatch: Function) => {
  const handleCreatedOrderChange = useCallback(
    (event: string) => {
      dispatch(setCreatedOrder(event));
    },
    [dispatch]
  );
  return handleCreatedOrderChange;
};
