import React, { useEffect, useState } from "react";
import { TextEnum } from "../../text";
import { RootState } from "../../../store";
import { ROLE_TYPE_ENUM } from "../../enum";
import { MenuElements } from "./menu.elements";
import { MenuItemProps, MenuItem } from "./menu.items";
import logoutIcon from "../../../static/sign-out@1.5x.png";
import { logoutAction } from "../../../actions/admin/logout";
import { useAppNavigate, useAppSelector } from "../../global-hooks";

import "./menu.scss";

export const Menu: React.FC = () => {
  const navigate = useAppNavigate();
  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );
  const [state, setState] = useState({
    decider: MenuElements,
    isHovered: false,
  });

  const handleMouseEnter = () => {
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, isHovered: true }));
    }, 600);
  };

  const handleMouseLeave = () => {
    setState((prevState) => ({ ...prevState, isHovered: false }));
  };

  useEffect(() => {
    if (authenticatedUser) {
      switch (authenticatedUser.role.id) {
        case ROLE_TYPE_ENUM.SUPER_ADMIN:
          setState((prevState) => ({
            ...prevState,
            decider: MenuElements,
          }));
          break;
        case ROLE_TYPE_ENUM.ADMIN:
          const normalAdminFiltered = MenuElements.filter(
            (element) =>
              element.label !== TextEnum.ADMIN_PAGE_TITLE &&
              element.label !== TextEnum.VERSION_PAGE_TITLE
          );
          setState((prevState) => ({
            ...prevState,
            decider: normalAdminFiltered,
          }));
          break;
        case ROLE_TYPE_ENUM.MOBILE_ADMIN:
          const mobileAdminFiltered = MenuElements.filter(
            (element) => element.label === TextEnum.VERSION_PAGE_TITLE
          );
          setState((prevState) => ({
            ...prevState,
            decider: mobileAdminFiltered,
          }));
          break;

        default:
          break;
      }
    }
  }, [authenticatedUser]);

  const logout = async () => {
    await logoutAction();
    navigate("/login");
  };

  return (
    <div className="left-menu">
      {state.decider.map((item: MenuItemProps, index: number) => (
        <MenuItem key={index} {...item} path={item.path} />
      ))}

      <div className="logout">
        <button
          onClick={logout}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img src={logoutIcon} alt={TextEnum.LOGOUT} />
          <div className="menu-label">{TextEnum.LOGOUT}</div>
        </button>
      </div>
    </div>
  );
};
