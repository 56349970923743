import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { getAllTeam } from "../../actions/team";
import { resetTeams } from "../reducers/team.reducer";

export const teamLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetTeams());

  return await getAllTeam();
};
