import React, { useState, useRef, useEffect } from "react";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import {
  RedoOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { canvasPreview } from "./canvas-preview";

import "react-image-crop/dist/ReactCrop.css";

type PictureUploaderProps = {
  pictureUrl: string;
  setPicture: (value: File) => void;
  isCircular: boolean;
  previewCanvasRef: React.RefObject<HTMLCanvasElement>;
  type: number;
};

export const PictureCropper: React.FC<PictureUploaderProps> = ({
  pictureUrl,
  isCircular,
  setPicture,
  previewCanvasRef,
  type,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [state, setState] = useState({
    scale: 1,
    rotate: 0,
    crop: undefined as Crop | undefined,
    completedCrop: undefined as PixelCrop | undefined,
  });

  useEffect(() => {
    if (
      state.completedCrop?.width &&
      state.completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        state.completedCrop,
        state.scale,
        state.rotate
      );
      const canvas = previewCanvasRef.current;
      if (canvas) {
        // Get the image data from the canvas
        const dataURL = canvas.toDataURL("image/png");
        const base64Data = dataURL.split(",")[1]; // Extract only the base64-encoded data

        // Convert the base64 data to a File object
        const byteString = atob(base64Data);
        const mimeString = "image/png"; // Specify the MIME type of the image
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const file = new File([ab], "image.png", { type: mimeString });
        setPicture(file);
      }
    }
  }, [
    state.completedCrop,
    state.scale,
    state.rotate,
    previewCanvasRef,
    setPicture,
  ]);

  const setCropState = (newCrop: Crop) => {
    setState((prevState) => ({
      ...prevState,
      crop: newCrop,
    }));
  };

  const setCompletedCropState = (newCompletedCrop: PixelCrop) => {
    setState((prevState) => ({
      ...prevState,
      completedCrop: newCompletedCrop,
    }));
  };

  const handleRotatePictureForward = () => {
    setState((prevState) => ({ ...prevState, rotate: state.rotate + 5 }));
  };

  const handleRotatePicturebackward = () => {
    setState((prevState) => ({ ...prevState, rotate: state.rotate - 5 }));
  };

  const handleZoomIn = () => {
    setState((prevState) => ({ ...prevState, scale: state.scale + 0.1 }));
  };

  const handleZoomOut = () => {
    setState((prevState) => ({ ...prevState, scale: state.scale - 0.1 }));
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          x: 0,
          y: 0,
          unit: "%",
          width: 100,
        },
        type === 2 ? 2 / 1 : 1,
        width,
        height
      ),
      width,
      height
    );

    setState((prevState) => ({
      ...prevState,
      crop: crop,
    }));
  };

  return (
    <div className="App">
      {!!pictureUrl && (
        <div>
          <div>
            <ReactCrop
              aspect={type === 2 ? 2 / 1 : 1}
              crop={state.crop}
              onChange={(_, percentCrop) => setCropState(percentCrop)}
              onComplete={(c) => setCompletedCropState(c)}
              circularCrop={isCircular}
            >
              <img
                onLoad={onImageLoad}
                ref={imgRef}
                alt={pictureUrl}
                src={pictureUrl}
                style={{
                  transform: `scale(${state.scale}) rotate(${state.rotate}deg)`,
                }}
              />
            </ReactCrop>
          </div>
          <UndoOutlined
            className="picture-rotate-button"
            onClick={handleRotatePicturebackward}
          />
          <RedoOutlined
            className="picture-rotate-button"
            onClick={handleRotatePictureForward}
          />
          <ZoomInOutlined
            className="picture-rotate-button"
            onClick={handleZoomIn}
          />

          <ZoomOutOutlined
            className="picture-rotate-button"
            onClick={handleZoomOut}
          />
        </div>
      )}
      <div>
        <canvas
          ref={previewCanvasRef}
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: state.completedCrop?.width,
            height: state.completedCrop?.height,
          }}
        />
      </div>
    </div>
  );
};
