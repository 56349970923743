import { useMemo } from "react";
import { Breadcrumb } from "antd";

import "./breadcrumb.scss";

type BreadcrumbProps = {
  title: string;
  onClick: () => void;
  modifyTitle: string;
  createTitle: string;
  modify: boolean;
  onSecondPageClick?: () => void;
  thirdPageTitle?: string;
};

export const CustomBreadCrumb: React.FC<BreadcrumbProps> = ({
  title,
  onClick,
  modify,
  modifyTitle,
  createTitle,
  thirdPageTitle,
  onSecondPageClick,
}) => {
  return useMemo(() => {
    return (
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <div className="navigator">{title}</div>,
            onClick: onClick,
          },
          {
            title: modify ? (
              <div className={thirdPageTitle ? "navigator" : ""}>
                {modifyTitle}
              </div>
            ) : (
              createTitle
            ),
            onClick: thirdPageTitle ? onSecondPageClick : () => {},
          },
          {
            title: thirdPageTitle,
          },
        ]}
      />
    );
  }, [
    onClick,
    createTitle,
    modify,
    modifyTitle,
    title,
    onSecondPageClick,
    thirdPageTitle,
  ]);
};
