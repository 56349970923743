import { Expose } from "class-transformer";
import {
  IsBoolean,
  IsDateString,
  IsDefined,
  IsNumber,
  IsNumberString,
  IsOptional,
  IsString,
} from "class-validator";
export class NonAdminUsers {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  email!: string;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string;

  @Expose()
  @IsDefined()
  @IsString()
  created!: string;

  @Expose()
  @IsDefined()
  @IsString()
  gender!: string;

  @Expose()
  @IsOptional()
  @IsString()
  birthDate?: string | null;

  @Expose()
  @IsDefined()
  @IsString()
  city!: string;

  @Expose()
  @IsDefined()
  @IsBoolean()
  subscribed!: boolean;
}
