import { useRef } from "react";

import "./textbox.scss";

type TextBoxProps = {
  placeholder?: string;
  value: string;
  onChange: (string: string) => void;
  disabled?: boolean;
};

export const TextArea: React.FC<TextBoxProps> = ({
  value,
  placeholder,
  onChange,
  disabled,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);

    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  };

  return (
    <textarea
      // style={disabled ? { backgroundColor: "lightgray" } : {}}
      disabled={disabled}
      maxLength={800}
      ref={textareaRef}
      className="text-area"
      placeholder={placeholder}
      value={value}
      onChange={handleInputChange}
    />
  );
};
