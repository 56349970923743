import React, { useMemo, useCallback } from "react";
import { Table } from "antd";
import { TextEnum } from "../../text";
import { Paginator } from "./paginator";

import "./table.scss";

type TableProps = {
  columns: Array<any>;
  dataSource: Array<any>;
  maxPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  onRowClick: (record: any) => any;
  isAdminColumn?: boolean;
  functionDisabler?: boolean;
  isUserOrTeamColumn?: boolean;
  selectedIds?: number[];
  setSelectedIds?: (ids: number[]) => void;
  scroll?: boolean;
};

export const DataTable: React.FC<TableProps> = ({
  columns,
  dataSource,
  maxPage,
  currentPage,
  setCurrentPage,
  onRowClick,
  isAdminColumn,
  functionDisabler,
  isUserOrTeamColumn,
  selectedIds,
  setSelectedIds,
  scroll,
}) => {
  const handleMouseEnter = useCallback(
    (e: any) => {
      if (!isAdminColumn) {
        e.currentTarget.style.cursor = "pointer";
      }
    },
    [isAdminColumn]
  );

  const handleRowClick = useCallback(
    (record: any, event: React.MouseEvent<HTMLElement>) => {
      if (!functionDisabler) {
        const target = event.target as HTMLElement;
        const tdElement = target.closest("td");
        if (tdElement) {
          const cellIndex = (tdElement as HTMLTableCellElement).cellIndex;
          const lastColumnIndex = columns.length - 1;
          const isLastColumn = cellIndex === lastColumnIndex;
          if (isUserOrTeamColumn) {
            if (selectedIds?.includes(record.id)) {
              const deselect = selectedIds.filter(
                (index) => index !== record.id
              );
              setSelectedIds!(deselect);
            } else {
              setSelectedIds!([...selectedIds!, record.id]);
            }
          } else if (!isLastColumn) {
            onRowClick(record);
          }
        }
      }
    },
    [
      onRowClick,
      setSelectedIds,
      functionDisabler,
      isUserOrTeamColumn,
      selectedIds,
      columns.length,
    ]
  );

  return useMemo(() => {
    return (
      <div className="table-container">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={dataSource}
          locale={{ emptyText: TextEnum.NO_DATA }}
          pagination={false}
          onRow={(record) => {
            const isSelected = selectedIds?.includes(record.id);
            const rowClass = isSelected ? "selected-row" : "";
            return {
              className: rowClass,
              onClick: (e) => handleRowClick(record, e),
              onMouseEnter: (e) => {
                handleMouseEnter(e);
              },
            };
          }}
          scroll={scroll ? { x: 2000 } : {}}
        />
        <Paginator
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          maxPages={maxPage}
        />
      </div>
    );
  }, [
    setCurrentPage,
    handleRowClick,
    handleMouseEnter,
    columns,
    dataSource,
    currentPage,
    maxPage,
    selectedIds,
    scroll,
  ]);
};
