import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";

import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

import { CreateVersionDTO } from "../../constants/dto/version";
import {
  setVersionActionStart,
  setVersionErrorMessage,
} from "../../constants/reducers/version.reducer";
import { getAllVersions } from "./get-all-version";

let body: CreateVersionDTO;

export const createVersion = async () => {
  store.dispatch(setVersionActionStart(true));
  const { platform, critical, version } = store.getState().version;
  const params = {
    platform: platform ?? 0,
    critical,
    version,
  };

  const request = await createCall(
    "cms/versions/create",
    BASE_METHODS.POST,
    "application/json",
    "1.0.0",
    params
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(CreateVersionDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setVersionErrorMessage(TextEnum.CREATE_VERSION_INVALID_RESPONSE)
      );
      return null;
    }
    await getAllVersions();
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 13002:
          errorMessage = TextEnum.VERSION_ALREADY_EXIST;
          break;
        default:
          break;
      }
      store.dispatch(setVersionErrorMessage(errorMessage));
      return null;
    }
  }

  store.dispatch(setVersionActionStart(false));
  store.dispatch(timeoutError());
  return null;
};
