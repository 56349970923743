import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { GetOneBadgeDTO } from "../../constants/dto/badge";
import {
  setBadge,
  setBadgeActionStart,
  setBadgeErrorMessage,
} from "../../constants/reducers/badge.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

type GetBadgeProp = {
  id: number;
};
let body: GetOneBadgeDTO;

export const getOneBadge = async ({ id }: GetBadgeProp) => {
  store.dispatch(setBadgeActionStart(true));
  const queryParams = new URLSearchParams();
  if (id) queryParams.append("id", id.toString());
  const request = await createCall(
    "cms/badges/get-one",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetOneBadgeDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setBadgeErrorMessage(TextEnum.GET_ONE_BADGE_INVALID_RESPONSE)
      );
      return;
    }
    store.dispatch(setBadge(response.data.badge));
    return request;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 10001:
          errorMessage = TextEnum.GET_ONE_BADGE_BADGE_NOT_FOUND;
          break;
        default:
          break;
      }
      store.dispatch(setBadgeErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setBadgeActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
