import { store } from "../../store";
import { logout } from "../../constants/reducers/session.reducer";
import { createCall } from "../base";
import { BASE_METHODS } from "../../constants/action-types";
import { loginOk } from "../../constants/reducers/login.reducer";
export const logoutAction = async () => {
  await createCall(
    "cms/admins/logout",
    BASE_METHODS.POST,
    "application/json",
    "1.0.0",
    undefined,
    undefined
  );
  store.dispatch(loginOk());
  store.dispatch(logout());
};
