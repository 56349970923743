import React, { useEffect, useMemo, useState } from "react";

import "./date-picker.scss";

type DatePickerProps = {
  onChange: (event: any) => void;
  validDate: string;
  compareDate?: string;
  placeholder: string;
  disabledFrom: string;
};

export const CustomDatePicker: React.FC<DatePickerProps> = ({
  onChange,
  validDate,
  placeholder,
  compareDate,
  disabledFrom,
}) => {
  const [disableFrom, setDisableFrom] = useState("");

  useEffect(() => {
    setDisableFrom(new Date().toISOString().split("T")[0]);
    if (compareDate) {
      const date = new Date();
      date.setDate(new Date(compareDate).getDate() + 1);
      setDisableFrom(date.toISOString().split("T")[0]);
    }
  }, [compareDate]);

  return useMemo(() => {
    return (
      <div className="datePicker">
        <input
          type="date"
          placeholder={
            new Date(disabledFrom).getTime() <= new Date().getTime()
              ? ""
              : placeholder
          }
          onChange={(e) => onChange(e.target.value)}
          value={validDate}
          min={disableFrom}
          required
          disabled={new Date(disabledFrom).getTime() <= new Date().getTime()}
        />
      </div>
    );
  }, [onChange, validDate, placeholder, disableFrom, disabledFrom]);
};
