import { Expose } from "class-transformer";
import { IsDefined, IsNumber, IsString } from "class-validator";

export class PredictiveSearch {
  @Expose()
  @IsDefined()
  @IsNumber()
  value!: number;

  @Expose()
  @IsDefined()
  @IsString()
  label!: string;
}
