import { useMemo } from "react";

import "./button.scss";

type SwitchButtonProps = {
  handleChangeActual: (value: boolean) => void;
  decider: boolean;
  mainButton: string;
  sideButton: string;
  disabledFrom?: string;
};

export const SwitchButton: React.FC<SwitchButtonProps> = ({
  handleChangeActual,
  decider,
  mainButton,
  sideButton,
  disabledFrom,
}) => {
  return useMemo(() => {
    return (
      <div>
        <button
          className={`${decider === false ? "selected" : "not-selected"}`}
          onClick={() => handleChangeActual(false)}
          disabled={
            disabledFrom
              ? new Date(disabledFrom).getTime() <= new Date().getTime()
              : false
          }
        >
          {mainButton}
        </button>
        <button
          className={`${decider ? "selected" : "not-selected"}`}
          onClick={() => handleChangeActual(true)}
          disabled={
            disabledFrom
              ? new Date(disabledFrom).getTime() <= new Date().getTime()
              : false
          }
        >
          {sideButton}
        </button>
      </div>
    );
  }, [decider, handleChangeActual, mainButton, sideButton, disabledFrom]);
};
