import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { resetCompany } from "../reducers/company.reducer";
import { getAllCompany, predictiveSearchCompany } from "../../actions/company";

export const companyLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetCompany());
  return await getAllCompany();
};

export const predictiveLoader = async (args: LoaderFunctionArgs) => {
  return await predictiveSearchCompany({ name: "" });
};
