import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { ForgotPasswordConfirmDTO } from "../../constants/dto/forgot-password";
import {
  setIsTokenValid,
  setErrorMessage,
  setToken,
  setForgotPasswordActionStart,
} from "../../constants/reducers/forgot.password.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { AxiosResponse } from "axios";
let body: ForgotPasswordConfirmDTO;

export const ForgotPasswordConfirm = async () => {
  store.dispatch(setForgotPasswordActionStart(true));
  const queryParams = new URLSearchParams();
  const getQueryParam = new URLSearchParams(window.location.search);
  const token = getQueryParam.get("token");
  if (token) queryParams.append("token", token);

  const request = await createCall(
    "users/forgot-password-confirm",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams,
    true
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(ForgotPasswordConfirmDTO, response.data.user);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setErrorMessage(TextEnum.FORGOT_PASSWORD_CONFIRM_INVALID_RESPONSE)
      );
      return null;
    }
    store.dispatch(setToken(token));
    store.dispatch(setIsTokenValid(response.data.user));
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 6001:
          errorMessage = TextEnum.FORGOT_PASSWORD_CONFIRM_TOKEN_INVALID;
          break;
        default:
          break;
      }
      store.dispatch(setErrorMessage(errorMessage));
      return null;
    }
    store.dispatch(setForgotPasswordActionStart(false));
    store.dispatch(timeoutError());
    return null;
  }
};
