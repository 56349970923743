import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { BadgeTypes } from "../enum";
import { getAllBadge } from "../../actions/badge";
import { resetBadge } from "../reducers/badge.reducer";

export const badgeLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetBadge());
  const queryParams = {
    name: "",
  };
  return await getAllBadge(queryParams);
};

export const badgeTypeLoader = (args: LoaderFunctionArgs) => {
  return BadgeTypes;
};
