import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";

import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

import { GetAllVersionDTO } from "../../constants/dto/version";
import {
  setVersionActionStart,
  setVersionErrorMessage,
  setVersions,
} from "../../constants/reducers/version.reducer";

let body: GetAllVersionDTO;

export const getAllVersions = async () => {
  store.dispatch(setVersionActionStart(true));
  const { searchText, page } = store.getState().version;
  const queryParams = new URLSearchParams();
  if (searchText) queryParams.append("searchText", searchText.trim());
  if (page) queryParams.append("page", page.toString());

  const request = await createCall(
    "cms/versions",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetAllVersionDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setVersionErrorMessage(TextEnum.GET_ALL_VERSION_INVALID_RESPONSE)
      );
      return null;
    }
    store.dispatch(
      setVersions({
        versions: response.data.versions,
        pageCount: response.data.pageCount,
      })
    );
    return response;
  } catch (error) {}
  const responseError = request as any;
  const errorResponeData: CustomErrorType = responseError.response?.data;
  if (errorResponeData) {
    let errorMessage = "";
    switch (errorResponeData.code) {
      case 13003:
        errorMessage = TextEnum.GET_ALL_VERSION_ERROR_IN_SENT_RESPONSE;
        break;
      default:
        break;
    }
    store.dispatch(setVersionErrorMessage(errorMessage));
    return null;
  }
  store.dispatch(setVersionActionStart(false));
  store.dispatch(timeoutError());
  return null;
};
