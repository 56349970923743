import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TextEnum } from "../text";
import { LoginState } from "../state";

const initialState: LoginState = {
  alertMessage: null,
  email: "",
  password: "",
  loginStarted: null,
  remainingTime: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    changeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    changePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    loginStarted: (state) => {
      state.loginStarted = new Date(Date.now());
    },
    loginUnexpectedResponse: (state) => {
      state.loginStarted = null;
      state.remainingTime = null;
      state.alertMessage = TextEnum.LOGIN_UNEXPECTED_RESPONSE;
    },
    loginInvalidJson: (state) => {
      state.loginStarted = null;
      state.remainingTime = null;
      state.alertMessage = TextEnum.LOGIN_INVALID_JSON;
    },
    loginInvalidResponse: (state) => {
      state.loginStarted = null;
      state.remainingTime = null;
      state.alertMessage = TextEnum.LOGIN_INVALID_RESPONSE;
    },
    loginTimeout: (state) => {
      state.loginStarted = null;
      state.remainingTime = null;
      state.alertMessage = TextEnum.SERVER_TIMEOUT_ERROR_TEXT;
    },
    loginUnauthorized: (state) => {
      state.alertMessage = TextEnum.UNAUTHORIZED_ERROR_TEXT;
      state.loginStarted = null;
      state.remainingTime = null;
    },
    loginOk: (state) => {
      state.loginStarted = null;
      state.remainingTime = null;
    },
    setAlertMessage: (state, action: PayloadAction<string | null>) => {
      state.alertMessage = action.payload;
      state.loginStarted = null;
    },
    tooManyLoginAttempts: (state, action: PayloadAction<Date | null>) => {
      state.loginStarted = null;
      state.remainingTime = action.payload;
      state.alertMessage = TextEnum.LOGIN_TOO_MANY_ATTEMPTS;
    },
  },
});

export const {
  changeEmail,
  changePassword,
  loginStarted,
  loginUnexpectedResponse,
  loginInvalidJson,
  loginInvalidResponse,
  loginTimeout,
  loginUnauthorized,
  loginOk,
  setAlertMessage,
  tooManyLoginAttempts,
} = loginSlice.actions;
