import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { Company } from "../../entity";

export class GetOneCompanyDTO {
  @Expose()
  @ValidateNested()
  @Type(() => Company)
  company!: Company;
}
