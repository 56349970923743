import { TextEnum } from "../../text";
import { PictureCropper } from "../picture";

import "./picture-cropper-modal.scss";

type PictureUploaderProps = {
  pictureUrl: string;
  setPicture: (value: File) => void;
  isCircular: boolean;
  previewCanvasRef: React.RefObject<HTMLCanvasElement>;
  setModalVisible: (value: boolean) => void;
  setPictureUrl: (value: string) => void;
  type: number;
};

export const PictureCropperModal: React.FC<PictureUploaderProps> = ({
  pictureUrl,
  isCircular,
  setPicture,
  previewCanvasRef,
  setModalVisible,
  setPictureUrl,
  type,
}) => {
  const handleCancel = () => {
    setPicture(null!);
    setPictureUrl("");
    setModalVisible(false);
  };
  const handleOK = () => {
    setModalVisible(false);
  };

  return (
    <div className="picture-cropper-custom-modal">
      <div className="picture-cropper-modal">
        <div className="picture-cropper-custom-body">
          <PictureCropper
            type={type}
            pictureUrl={pictureUrl}
            setPicture={setPicture}
            isCircular={isCircular}
            previewCanvasRef={previewCanvasRef}
          />
        </div>
        <div className="custom-modal-footer">
          <button onClick={handleCancel}>{TextEnum.CANCEL_BUTTON}</button>
          <button
            onClick={handleOK}
            disabled={!pictureUrl || previewCanvasRef.current === null}
            className={`${
              !pictureUrl || previewCanvasRef.current === null ? "disabled" : ""
            }`}
          >
            {TextEnum.SAVE_BUTTON}
          </button>
        </div>
      </div>
    </div>
  );
};
