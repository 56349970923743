import { useEffect, useState } from "react";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { TextBox } from "../../constants/features/input";
import { DataTable } from "../../constants/features/table";
import { MessageModal } from "../../constants/features/modal";
import { UserColumns } from "../../constants/features/columns";
import { CustomPopConfirm } from "../../constants/features/popconfirm";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "../../constants/global-hooks";
import {
  useHandleDeleteUsers,
  useHandleGetUsers,
  useHandleUserAlertMessage,
  useHandleUserSearchText,
  useHandleUserpageChange,
  useHandleWannabeDeletedUsers,
} from "../../constants/utils";
import * as XLSX from "xlsx";

import "./user.scss";
import { getAllUserXlsxData } from "../../actions/user";

export const UserPage = () => {
  const dispatch = useAppDispatch();
  const {
    searchText,
    page,
    pageCount,
    users,
    errorMessage,
    nameOrder,
    createdOrder,
    selectedIds,
    searchCity,
  } = useAppSelector((state: RootState) => state.user);
  const [searchTerm, setSearchTerm] = useState(false);

  const handleAlertMessage = useHandleUserAlertMessage(dispatch);

  useEffect(() => {
    if (searchText || nameOrder || createdOrder) {
      setSearchTerm(true);
    }
  }, [searchText, nameOrder, createdOrder]);

  const getUsers = useHandleGetUsers();

  const debouncedGetUsers = useDebounce(getUsers, searchTerm ? 500 : 0);

  useEffect(() => {
    debouncedGetUsers();
  }, [
    debouncedGetUsers,
    page,
    searchText,
    nameOrder,
    createdOrder,
    searchCity,
  ]);

  const generateXLSX = async () => {
    const users = await getAllUserXlsxData();

    if (users.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(users);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

      const arrayBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Uint8Array(arrayBuffer);

      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `felhasználók-${new Date().getTime()}.xlsx`;
      link.click();

      URL.revokeObjectURL(url);
    } else {
      console.warn("No users to export.");
    }
  };
  return (
    <main className="main-page">
      <div className="title">{TextEnum.USER_PAGE_TITLE}</div>
      <button className="export-button" onClick={generateXLSX}>
        Exportálás
      </button>
      <div className="user-buttons-container">
        <TextBox
          value={searchText}
          onChange={useHandleUserSearchText(dispatch)}
          type={"input"}
          customErrorMessage={""}
          setDecider={() => null}
          placeholder={TextEnum.SEARCH}
          search={true}
        />
        <CustomPopConfirm
          title={
            selectedIds.length > 1
              ? TextEnum.MULTIPLE_USER_DELETE_POPUP_CONFIRM
              : TextEnum.USER_DELETE_POPUP_CONFIRM
          }
          okText={TextEnum.YES}
          cancelText={TextEnum.NO}
          onConfirm={useHandleDeleteUsers(dispatch)}
          disabled={selectedIds.length === 0}
        >
          <button
            className={`user-delete-button ${
              selectedIds.length === 0 ? "disabled" : ""
            }`}
          >
            {selectedIds.length > 1
              ? TextEnum.DELETE_MULTIPLE_USER_BUTTON_TITLE
              : TextEnum.DELETE_USER_BUTTON_TITLE}
          </button>
        </CustomPopConfirm>
      </div>
      <div>
        <DataTable
          dataSource={users}
          columns={Object.values(UserColumns())}
          maxPage={pageCount}
          currentPage={page}
          setCurrentPage={useHandleUserpageChange(dispatch, setSearchTerm)}
          onRowClick={useHandleWannabeDeletedUsers(dispatch)}
          isUserOrTeamColumn={true}
          selectedIds={selectedIds}
          setSelectedIds={useHandleWannabeDeletedUsers(dispatch)}
        />
      </div>
      {errorMessage && (
        <MessageModal
          title=""
          message={errorMessage}
          onCloseClick={handleAlertMessage}
        />
      )}
    </main>
  );
};
