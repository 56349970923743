import { useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { TextBox } from "../../constants/features/input";
import { MessageModal } from "../../constants/features/modal";
import { CustomBreadCrumb } from "../../constants/features/breadcrumb";
import { resetCompany } from "../../constants/reducers/company.reducer";
import {
  createCompany,
  getOneCompany,
  modifyCompany,
} from "../../actions/company";
import {
  useAppDispatch,
  useAppParams,
  useAppSelector,
} from "../../constants/global-hooks";
import {
  useHandleCompanyAlertMessageChange,
  useHandleCompanyCreatorEmailChange,
  useHandleCompanyNameChange,
  useHandleCompanyNavigateBack,
  useHandleCompanyTaxNumberChange,
  useHandleCompanyInputDeciderChange,
} from "../../constants/utils";

import "./create-company.scss";

export const CreateCompanyPage = () => {
  const params = useAppParams();
  const dispatch = useAppDispatch();
  const { name, taxNumber, email, errorMessage, companyActionStart, company } =
    useAppSelector((state: RootState) => state.company);
  const [state, setState] = useState({
    buttonDisabler: true,
    inputButtonDisabler: true,
    clicked: false,
  });
  const handleNavigateBack = useHandleCompanyNavigateBack(dispatch);
  const handleCreatorEmailChange = useHandleCompanyCreatorEmailChange(dispatch);
  const handleAlertMessage = useHandleCompanyAlertMessageChange(dispatch);
  const handleinputDeciderChange = useHandleCompanyInputDeciderChange(setState);

  const getCompany = async (id: number) => {
    await getOneCompany({ id });
  };

  useEffect(() => {
    dispatch(resetCompany());
    if (params.id) {
      getCompany(parseInt(params.id));
    }
  }, [dispatch, params]);

  const handleSave = async () => {
    handleAlertMessage(null);
    company ? await modifyCompany() : await createCompany();
    setState((prevState) => ({ ...prevState, clicked: true }));
  };

  useEffect(() => {
    if (state.clicked && errorMessage === null && !companyActionStart) {
      handleNavigateBack();
    }
    setState((prevState) => ({ ...prevState, clicked: false }));
  }, [handleNavigateBack, state.clicked, errorMessage, companyActionStart]);

  useEffect(() => {
    if (name && taxNumber && email) {
      setState((prevState) => ({ ...prevState, buttonDisabler: false }));
    } else if (company && name && taxNumber) {
      setState((prevState) => ({ ...prevState, buttonDisabler: false }));
    } else {
      setState((prevState) => ({ ...prevState, buttonDisabler: true }));
    }
  }, [name, taxNumber, email, company]);


  return (
    <main className="main-page">
      <div className="title">
        {company ? TextEnum.MODIFY : TextEnum.CREATION_MODAL_TITLE}
      </div>
      <CustomBreadCrumb
        title={TextEnum.COMPANY_PAGE_TITLE}
        onClick={handleNavigateBack}
        modifyTitle={TextEnum.MODIFY_COMPANY_PAGE_TITLE}
        createTitle={TextEnum.CREATE_COMPANY_PAGE_TITLE}
        modify={company ? true : false}
      />
      <div className="container">
        <div className="inputContainer">
          <TextBox
            onChange={useHandleCompanyNameChange(dispatch)}
            value={name}
            type="input"
            customErrorMessage={TextEnum.NAME_REQUIRED_ERROR_MESSAGE}
            setDecider={handleinputDeciderChange}
            placeholder={TextEnum.COMPANY_NAME}
          />
        </div>
        <div className="inputContainer">
          <TextBox
            onChange={useHandleCompanyTaxNumberChange(dispatch)}
            value={taxNumber}
            type="input"
            customErrorMessage={TextEnum.TAX_NUMBER_REQUIRED_ERROR_MESSAGE}
            setDecider={handleinputDeciderChange}
            placeholder={TextEnum.COMPANY_TAX_NUMBER}
          />
        </div>
        {!company && (
          <div className="inputContainer">
            <TextBox
              onChange={handleCreatorEmailChange}
              value={email}
              type="input"
              customErrorMessage={TextEnum.EMAIL_REQUIRED_ERROR_MESSAGE}
              isEmail={true}
              setDecider={handleinputDeciderChange}
              placeholder={TextEnum.EMAIL_TEXT}
            />
          </div>
        )}

        <button
          disabled={
            companyActionStart ||
            state.buttonDisabler ||
            state.inputButtonDisabler
          }
          className={`create-company-button ${
            state.buttonDisabler ||
            companyActionStart ||
            state.inputButtonDisabler
              ? "disabled"
              : ""
          }`}
          onClick={handleSave}
        >
          {companyActionStart ? <LoadingOutlined /> : TextEnum.SAVE_BUTTON}
        </button>
      </div>

      {errorMessage && (
        <MessageModal
          title=""
          message={errorMessage}
          onCloseClick={handleAlertMessage}
        />
      )}
    </main>
  );
};
