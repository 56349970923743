import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { SuccessDTO } from "../../constants/dto/success";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import {
  setVersionActionStart,
  setVersionErrorMessage,
} from "../../constants/reducers/version.reducer";
import { getAllVersions } from "./get-all-version";

type DeleteVersionProps = {
  id: number;
};
let body: SuccessDTO;
export const deleteVersion = async (props: DeleteVersionProps) => {
  store.dispatch(setVersionActionStart(true));
  const { id } = props;

  const request = await createCall(
    `cms/versions/delete/${id}`,
    BASE_METHODS.DELETE,
    "application/json",
    "1.0.0"
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(SuccessDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setVersionErrorMessage(TextEnum.DELETE_VERSION_INVALID_RESPONSE)
      );
      return;
    }
    await getAllVersions();
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 13001:
          errorMessage = TextEnum.DELETE_VERSION_NOT_FOUND;
          break;
        default:
          break;
      }
      store.dispatch(setVersionErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setVersionActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
