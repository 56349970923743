import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import { store } from "../../store";
import { createCall } from "../base";
import { SuccessDTO } from "../../constants/dto/success";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import {
  resetForgotPassword,
  setErrorMessage,
  setForgotPasswordActionStart,
} from "../../constants/reducers/forgot.password.reducer";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { TextEnum } from "../../constants/text";

let body: SuccessDTO;

export const forgotPasswordModifyPassword = async () => {
  store.dispatch(setForgotPasswordActionStart(true));
  const { isTokenValid, token, password } = store.getState().forgotPassword;
  const bodyToSend = {
    id: isTokenValid?.id,
    token,
    password,
  };
  const request = await createCall(
    "users/modify-password",
    BASE_METHODS.PUT,
    "application/json",
    "1.0.0",
    bodyToSend,
    undefined,
    true
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(SuccessDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setErrorMessage(
          TextEnum.FORGOT_PASSWORD_MODIFY_PASSWORD_INVALID_RESPONSE
        )
      );
      return;
    }
    store.dispatch(
      setErrorMessage(TextEnum.FORGOT_PASSWORD_MODIFY_PASSWORD_SUCCESS)
    );
    store.dispatch(resetForgotPassword());
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 1001:
          errorMessage =
            TextEnum.FORGOT_PASSWORD_MODIFY_PASSWORD_USER_NOT_FOUND;
          break;
        default:
          break;
      }
      store.dispatch(setErrorMessage(errorMessage));
      return;
    }
    store.dispatch(setForgotPasswordActionStart(false));
    store.dispatch(timeoutError());
    return;
  }
};
