import { Player } from "./player";
import { useState } from "react";
type TopThreeType = {
  topThree: {
    first: string;
    second: string;
    third: string;
  };
};

export const Podium: React.FC<TopThreeType> = ({ topThree }) => {
  const [index, setIndex] = useState<number | null>(null);
  return (
    <div className="podium">
      <Player
        key={topThree?.second}
        name={index === 2 ? topThree?.second : ""}
        position={2}
        className={`position-${2}`}
        setIndex={(index) => setIndex(index!)}
      />
      <Player
        key={topThree?.first}
        name={index === 1 ? topThree?.first : ""}
        position={1}
        className={`position-${1}`}
        setIndex={(index) => setIndex(index!)}
      />
      <Player
        key={topThree?.third}
        name={index === 3 ? topThree?.third : ""}
        position={3}
        className={`position-${3}`}
        setIndex={(index) => setIndex(index!)}
      />
    </div>
  );
};
