import { store } from "../../store";
import {
  getMeFailure,
  getMeOk,
} from "../../constants/reducers/session.reducer";
import { createCall } from "../base";
import { BASE_METHODS } from "../../constants/action-types";
import { GetMeDTO } from "../../constants/dto/admin";
import { AxiosResponse } from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

let body: GetMeDTO;
export const getMe = async () => {
  const request = await createCall(
    "cms/admins/me",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    undefined
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetMeDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(getMeFailure());
      return;
    }
    store.dispatch(getMeOk({ user: response.data.user }));
  } catch (error) {
    store.dispatch(getMeFailure());
    return;
  }
};
