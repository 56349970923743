import { useState, useEffect } from "react";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { TextBox } from "../../constants/features/input";
import { DataTable } from "../../constants/features/table";
import { VersionColumns } from "../../constants/features/columns";
import { resetVersion } from "../../constants/reducers/version.reducer";
import { MessageModal, VersionModal } from "../../constants/features/modal";
import { useHandleVesionAlertMessage } from "../../constants/utils/version-utils";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "../../constants/global-hooks";
import {
  useHandleVesionChange,
  useHandleVesionPageChange,
  useHandleVesionPlatform,
  useHandleVesionSearchChange,
  useHandleVesionSave,
  useHandleVesionCriticalChange,
  useHandleGetAllVesion,
} from "../../constants/utils";

import "./version.scss";

export const VerisonPage = () => {
  const dispatch = useAppDispatch();
  const {
    versions,
    pageCount,
    page,
    searchText,
    version,
    critical,
    platform,
    errorMessage,
  } = useAppSelector((state: RootState) => state.version);

  const [searchTerm, setSearchTerm] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleVersionChange = useHandleVesionChange(dispatch);
  const handleChangePlatform = useHandleVesionPlatform(dispatch);
  const handleSave = useHandleVesionSave();
  const handleCriticalChange = useHandleVesionCriticalChange(dispatch);
  const handleAlertMessage = useHandleVesionAlertMessage(dispatch);
  useEffect(() => {
    if (searchText) {
      setSearchTerm(true);
    }
  }, [searchText]);

  const getVersions = useHandleGetAllVesion();

  const debouncedGetVersions = useDebounce(getVersions, searchTerm ? 500 : 0);

  useEffect(() => {
    debouncedGetVersions();
  }, [debouncedGetVersions, page, searchText]);

  const handleModalVisible = () => {
    setModalVisible(true);
  };

  useEffect(() => {
    if (!modalVisible) {
      dispatch(resetVersion());
    }
  }, [modalVisible, dispatch]);

  return (
    <div>
      <main className="main-page">
        <div className="title">{TextEnum.VERSION_PAGE_TITLE}</div>
        <div className="version-buttons-container">
          <TextBox
            value={searchText}
            onChange={useHandleVesionSearchChange(dispatch)}
            type={"input"}
            customErrorMessage={""}
            setDecider={() => null}
            placeholder={TextEnum.SEARCH}
            search={true}
          />
          <button
            className="create-version-button"
            onClick={handleModalVisible}
          >
            {TextEnum.NEW_VERSION}
          </button>
        </div>
        <div>
          <DataTable
            dataSource={versions}
            columns={Object.values(VersionColumns())}
            maxPage={pageCount}
            currentPage={page}
            setCurrentPage={useHandleVesionPageChange(dispatch, setSearchTerm)}
            onRowClick={() => {}}
            functionDisabler={true}
            isAdminColumn={true}
          />
        </div>
        {errorMessage && (
          <MessageModal
            title=""
            message={errorMessage}
            onCloseClick={handleAlertMessage}
          />
        )}
      </main>
      {modalVisible && (
        <VersionModal
          setModalVisible={setModalVisible}
          version={version}
          setVersion={handleVersionChange}
          platform={platform}
          setPlatform={handleChangePlatform}
          critical={critical}
          setCritical={handleCriticalChange}
          handleSave={handleSave}
          error={errorMessage}
        />
      )}
    </div>
  );
};
