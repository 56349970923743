import { Popconfirm } from "antd";

interface PopconfirmProps {
  children: JSX.Element;
  title: string;
  okText: string;
  cancelText: string;
  onConfirm: () => void;
  disabled: boolean;
}

export const CustomPopConfirm: React.FC<PopconfirmProps> = ({
  children,
  title,
  okText,
  cancelText,
  onConfirm,
  disabled,
}) => {
  return (
    <Popconfirm
      title={title}
      okText={okText}
      cancelText={cancelText}
      onConfirm={onConfirm}
      disabled={disabled}
    >
      {children}
    </Popconfirm>
  );
};
