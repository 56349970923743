import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TextEnum } from "../text";
import { BaseState } from "../state";

const initialState: BaseState = {
  errorTitle: null,
  errorMessage: null,
  requestStart: false,
};

export const baseSlice = createSlice({
  name: "base",
  initialState,
  reducers: {
    timeoutError: (state) => {
      state.errorTitle = TextEnum.ERROR;
      state.errorMessage = TextEnum.SERVER_TIMEOUT_ERROR_TEXT;
      state.requestStart = false;
    },
    basicErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorTitle = TextEnum.ERROR;
      state.errorMessage = action.payload;
      state.requestStart = false;
    },
    clearError: (state) => {
      state.errorTitle = null;
      state.errorMessage = null;
      state.requestStart = false;
    },
    requestStart: (state, action: PayloadAction<boolean>) => {
      state.requestStart = action.payload;
    },
  },
});

export const { timeoutError, clearError, basicErrorMessage, requestStart } =
  baseSlice.actions;
