import { useCallback } from "react";
import {
  setEmail,
  setPassword,
  setPasswordCheck,
} from "../reducers/forgot.password.reducer";

export const useHandleForgotPasswordEmailChange = (dispatch: Function) => {
  const handleEmailChange = useCallback(
    (event: string) => {
      dispatch(setEmail(event));
    },
    [dispatch]
  );
  return handleEmailChange;
};

export const useHandleForgotPasswordPasswordChange = (dispatch: Function) => {
  const handlePasswordChange = useCallback(
    (value: string) => {
      dispatch(setPassword(value));
    },
    [dispatch]
  );
  return handlePasswordChange;
};

export const useHandleForgotPasswordPasswordCheckChange = (
  dispatch: Function
) => {
  const handlePasswordCheckChange = useCallback(
    (value: string) => {
      dispatch(setPasswordCheck(value));
    },
    [dispatch]
  );
  return handlePasswordCheckChange;
};
