import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";
import {
  setCompanies,
  setCompanyActionStart,
  setCompanyErrorMessage,
} from "../../constants/reducers/company.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { AxiosResponse } from "axios";
import { GetAllCompanyDTO } from "../../constants/dto/company";

let body: GetAllCompanyDTO;

export const getAllCompany = async () => {
  store.dispatch(setCompanyActionStart(true));
  const { searchText, page } = store.getState().company;
  const queryParams = new URLSearchParams();

  if (searchText) queryParams.append("searchText", searchText);
  if (page) queryParams.append("page", page.toString());

  const request = await createCall(
    "cms/companies",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetAllCompanyDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setCompanyErrorMessage(TextEnum.GET_ALL_COMPANY_INVALID_RESPONSE)
      );
      return null;
    }
    store.dispatch(
      setCompanies({
        companies: response.data.companies,
        pageCount: response.data.pageCount,
      })
    );
    return response;
  } catch (error) {
    const responseError = request as any;
    const errorResponeData: CustomErrorType = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 2003:
          errorMessage = TextEnum.GET_ALL_COMPANY_ERROR_IN_SENT_RESPONSE;
          break;
        default:
          break;
      }
      store.dispatch(setCompanyErrorMessage(errorMessage));
      return null;
    }
    store.dispatch(setCompanyActionStart(false));
    store.dispatch(timeoutError());
    return null;
  }
};
