import { Badge } from "../../constants/entity";
import { useAppDispatch, useAppSelector } from "../../constants/global-hooks";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { TextEnum } from "../../constants/text";
import { resetBadge } from "../../constants/reducers/badge.reducer";

export const BadgeCards: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { badges } = useAppSelector((state: RootState) => state.badge);

  const handleModify = async (id: number) => {
    navigate(`/badge/modify/${id}`);
  };

  const handleCreate = () => {
    dispatch(resetBadge());
    navigate("/badge/create");
  };
  return (
    <div className="badge-card-row">
      {badges.map((badge: Badge) => (
        <div className="badge-card-container" key={badge.id}>
          <div className="cards">
            <img src={badge.url} alt={badge.name} />
            <button
              className="badge-button"
              onClick={() => handleModify(badge.id)}
            >
              <EditOutlined />
            </button>
            <div className="badge-name">{badge.name}</div>
          </div>
        </div>
      ))}
      {badges.length < 8 && (
        <div className="badge-card-container">
          <div className="cards">
            <button className="new-badge-button" onClick={handleCreate}>
              {TextEnum.NEW_BADGE_CARD_TITLE}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
