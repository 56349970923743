import { useCallback } from "react";
import { createVersion, getAllVersions } from "../../actions/version";
import {
  setVersionCritical,
  setVersionErrorMessage,
  setVersionPage,
  setVersionPlatform,
  setVersionSearchText,
  setVersionVersion,
} from "../reducers/version.reducer";

export const useHandleVesionPageChange = (
  dispatch: Function,
  setSearchTerm: Function
) => {
  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(setVersionPage(page));
      setSearchTerm(false);
    },
    [dispatch, setSearchTerm]
  );
  return handlePageChange;
};

export const useHandleVesionSearchChange = (dispatch: Function) => {
  const handleSearchChange = useCallback(
    (value: string) => {
      dispatch(setVersionSearchText(value));
    },
    [dispatch]
  );
  return handleSearchChange;
};

export const useHandleVesionChange = (dispatch: Function) => {
  const handleVersionChange = useCallback(
    (value: string) => {
      dispatch(setVersionVersion(value));
    },
    [dispatch]
  );
  return handleVersionChange;
};

export const useHandleVesionPlatform = (dispatch: Function) => {
  const handlePlatformChange = useCallback(
    (value: boolean) => {
      dispatch(setVersionPlatform(value));
    },
    [dispatch]
  );
  return handlePlatformChange;
};

export const useHandleVesionSave = () => {
  const handleSave = useCallback(async () => {
    await createVersion();
  }, []);
  return handleSave;
};

export const useHandleVesionCriticalChange = (dispatch: Function) => {
  const handleCriticalChange = useCallback(
    (value: boolean) => {
      dispatch(setVersionCritical(value));
    },
    [dispatch]
  );
  return handleCriticalChange;
};

export const useHandleGetAllVesion = () => {
  const getVersions = useCallback(async () => {
    await getAllVersions();
  }, []);
  return getVersions;
};

export const useHandleVesionAlertMessage = (dispatch: Function) => {
  const handleAllertMessage = useCallback(
    (message: string) => {
      dispatch(setVersionErrorMessage(message));
    },
    [dispatch]
  );
  return handleAllertMessage;
};
