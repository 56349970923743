import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Admin } from "../entity";
import { AdminState } from "../state";

const initialState: AdminState = {
  admins: [],
  searchText: "",
  email: "",
  password: "",
  page: 1,
  pageCount: null!,
  errorMessage: null!,
  adminActionStart: false,
  name: "",
  type: 1,
};

//admin reducer
export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdmins: (
      state,
      action: PayloadAction<{ admins: Admin[]; pageCount: number }>
    ) => {
      state.admins = action.payload.admins;
      state.pageCount = action.payload.pageCount;
      state.errorMessage = null!;
      state.adminActionStart = false;
    },

    setAdminSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
      state.page = 1;
    },

    setAdminEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },

    setAdminName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },

    setAdminPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },

    setAdminPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setAdminErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.adminActionStart = false;
    },

    addAdminToAdmins: (state, action: PayloadAction<Admin>) => {
      state.admins = [...state.admins, action.payload];
      state.errorMessage = null!;
      state.adminActionStart = false;
    },

    removeAdminFromAdmins: (state, action: PayloadAction<number>) => {
      if (state.admins.length % 10 === 1) {
        state.page -= 1;
        state.pageCount -= 1;
      }
      state.admins = state.admins.filter(
        (admin) => admin.id !== action.payload
      );
      state.errorMessage = null!;
      state.adminActionStart = false;
    },

    resetAdmin: (state) => {
      state.email = "";
      state.password = "";
      state.searchText = "";
      state.name = "";
      state.type = 1;
    },

    setAdminActionStart: (state, action: PayloadAction<boolean>) => {
      state.adminActionStart = action.payload;
    },

    setAdminType: (state, action: PayloadAction<number>) => {
      state.type = action.payload;
    },
  },
});

export const {
  setAdmins,
  setAdminSearchText,
  setAdminEmail,
  setAdminPassword,
  setAdminPage,
  setAdminErrorMessage,
  addAdminToAdmins,
  removeAdminFromAdmins,
  resetAdmin,
  setAdminActionStart,
  setAdminName,
  setAdminType,
} = adminSlice.actions;
