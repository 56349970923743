import React from "react";
import { useAppNavigate } from "../../global-hooks";
export type MenuItemProps = {
  label: string;
  path: string;
  icon?: any;
};

export const MenuItem: React.FC<MenuItemProps> = ({ label, icon, path }) => {
  const navigate = useAppNavigate();

  return (
    <ul>
      <li key={label}>
        <button
          disabled={window.location.pathname === path}
          onClick={() => navigate(path)}
          className={window.location.pathname.includes(path) ? "selected" : ""}
        >
          <div className="tooltip">
            {icon && <div>{icon}</div>}
            <div className="menu-label">{label}</div>
          </div>
        </button>
      </li>
    </ul>
  );
};
