import { AxiosResponse } from "axios";
import { BASE_METHODS, CustomErrorType } from "../../constants/action-types";

import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import { GetAllUserDTO } from "../../constants/dto/user";
import {
  setUserActionStart,
  setUserErrorMessage,
  setUsers,
} from "../../constants/reducers/user.reducer";

let body: GetAllUserDTO;

export const getAllUsers = async () => {
  store.dispatch(setUserActionStart(true));
  const { searchText, page, nameOrder, createdOrder, searchCity } =
    store.getState().user;
  const queryParams = new URLSearchParams();
  if (searchText) queryParams.append("searchText", searchText.trim());
  if (page) queryParams.append("page", page.toString());
  if (nameOrder) queryParams.append("nameOrder", nameOrder);
  if (createdOrder) queryParams.append("createdOrder", createdOrder);
  if (searchCity) queryParams.append("searchCity", searchCity);

  const request = await createCall(
    "cms/users",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetAllUserDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setUserErrorMessage(TextEnum.GET_ALL_USER_INVALID_RESPONSE)
      );
      return null;
    }
    store.dispatch(
      setUsers({
        users: response.data.users,
        pageCount: response.data.pageCount,
      })
    );
    return response;
  } catch (error) {}
  const responseError = request as any;
  const errorResponeData: CustomErrorType = responseError.response?.data;
  if (errorResponeData) {
    let errorMessage = "";
    switch (errorResponeData.code) {
      case 1010:
        errorMessage = TextEnum.GET_ALL_USER_ERROR_IN_SENT_RESPONSE;
        break;
      default:
        break;
    }
    store.dispatch(setUserErrorMessage(errorMessage));
    return null;
  }
  store.dispatch(setUserActionStart(false));
  store.dispatch(timeoutError());
  return null;
};
