import { Expose } from "class-transformer";
import {
  IsBoolean,
  IsDateString,
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator";
export class NonWinnerUsers {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsOptional()
  @IsString()
  email?: string;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  created!: string;

  @Expose()
  @IsOptional()
  @IsNumber()
  gender?: number;

  @Expose()
  @IsOptional()
  @IsString()
  birthDate?: string | null;

  @Expose()
  @IsOptional()
  @IsString()
  city?: string;

  @Expose()
  @IsDefined()
  @IsNumber()
  allScore!: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  score?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  childOccasion?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  mixedOccasion?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  occasion?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  inviteSent?: number;

  @Expose()
  @IsDefined()
  @IsBoolean()
  completed!: boolean;

  @Expose()
  @IsOptional()
  @IsNumber()
  memberCount?: number;
}
