import { AxiosResponse } from "axios";
import { BASE_METHODS } from "../../constants/action-types";

import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";
import {
  removeUsers,
  setUserActionStart,
  setUserErrorMessage,
} from "../../constants/reducers/user.reducer";
import { SuccessDTO } from "../../constants/dto/success";

let body: SuccessDTO;

export const deleteUsers = async () => {
  store.dispatch(setUserActionStart(true));
  const { selectedIds } = store.getState().user;

  const params = {
    ids: selectedIds,
  };

  const request = await createCall(
    "cms/users/delete-users",
    BASE_METHODS.DELETE,
    "application/json",
    "1.0.0",
    params,
    undefined
  );

  try {
    const response = request as AxiosResponse;
    body = plainToClass(SuccessDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setUserErrorMessage(TextEnum.DELETE_USERS_INVALID_RESPONSE)
      );
      return null;
    }
    store.dispatch(removeUsers());
  } catch (error) {
    store.dispatch(setUserActionStart(false));
    store.dispatch(timeoutError());
    return null;
  }
};
