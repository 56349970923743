import { useEffect, useState } from "react";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { TextBox } from "../../constants/features/input";
import { Podium } from "../../constants/features/podium";
import { DataTable } from "../../constants/features/table";
import { LeaderboardColumns } from "../../constants/features/columns";
import { CustomBreadCrumb } from "../../constants/features/breadcrumb";
import {
  useAppDispatch,
  useAppParams,
  useAppSelector,
  useDebounce,
} from "../../constants/global-hooks";
import {
  useHandleGetLeaderboard,
  useHandleLeaderboardNavigateBack,
  useHandleLeaderboardNavigateBackSecondPage,
  useHandleLeaderboardPageChange,
  useHandleLeaderboardSearchTextChange,
  useHandleSetAlertMessage,
} from "../../constants/utils";

import "./leaderboard.scss";
import { MessageModal } from "../../constants/features/modal";
import { getLeaderboardXlsxData } from "../../actions/leaderboard";
import * as XLSX from "xlsx";

export const LeaderboardPage = () => {
  const dispatch = useAppDispatch();
  const {
    topThree,
    searchText,
    allScoreOrder,
    pageCount,
    page,
    scoreOrder,
    occasionOrder,
    childOccasionOrder,
    mixedOccasionOrder,
    inviteSentOrder,
    nonWinnerDatas,
    errorMessage,
    selectedIds,
  } = useAppSelector((state: RootState) => state.leaderboard);
  const [searchTerm, setSearchTerm] = useState(false);
  const params = useAppParams();
  const handleNavigateBack = useHandleLeaderboardNavigateBack();
  const handleNavigateBackSecondPage =
    useHandleLeaderboardNavigateBackSecondPage();
  const getLeaderboard = useHandleGetLeaderboard();
  const handleAlertMessage = useHandleSetAlertMessage(dispatch);

  useEffect(() => {
    if (
      searchText ||
      allScoreOrder ||
      scoreOrder ||
      occasionOrder ||
      childOccasionOrder ||
      mixedOccasionOrder ||
      inviteSentOrder
    ) {
      setSearchTerm(true);
    }
  }, [
    searchText,
    allScoreOrder,
    scoreOrder,
    occasionOrder,
    childOccasionOrder,
    mixedOccasionOrder,
    inviteSentOrder,
  ]);

  const debouncedGetLeaderboard = useDebounce(
    getLeaderboard,
    searchTerm ? 500 : 0
  );

  useEffect(() => {
    debouncedGetLeaderboard();
  }, [
    debouncedGetLeaderboard,
    page,
    searchText,
    allScoreOrder,
    scoreOrder,
    occasionOrder,
    childOccasionOrder,
    mixedOccasionOrder,
    inviteSentOrder,
  ]);

  useEffect(() => {
    getLeaderboard();
  }, [getLeaderboard]);

  const generateXLSX = async () => {
    const leaderboard = await getLeaderboardXlsxData(params.id!);
    if (leaderboard) {
      const workbook = XLSX.utils.book_new();
      const nonWinnerWorksheet = XLSX.utils.json_to_sheet(
        leaderboard.nonWinnerDatas
      );
      XLSX.utils.book_append_sheet(workbook, nonWinnerWorksheet, "All data");

      const arrayBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([arrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `ranglista-${new Date().getTime()}.xlsx`;
      link.click();

      URL.revokeObjectURL(url);
    } else {
      console.warn("No leaderboard to export.");
    }
  };

  return (
    <main className="main-page">
      <div className="title">{TextEnum.LEADERBOARD_PAGE_TITLE}</div>

      <CustomBreadCrumb
        title={TextEnum.CAMPAIGNS_PAGE_TITLE}
        onClick={handleNavigateBack}
        modifyTitle={TextEnum.MODIFY_CAMPAIGN_PAGE_TITLE}
        createTitle={""}
        thirdPageTitle={TextEnum.LEADERBOARD_PAGE_TITLE}
        modify={true}
        onSecondPageClick={handleNavigateBackSecondPage}
      />
      <button className="export-button" onClick={generateXLSX}>
        Exportálás
      </button>
      {topThree && <Podium topThree={topThree} />}
      <div className="buttons-container">
        <div className="searchbox">
          <TextBox
            value={searchText}
            onChange={useHandleLeaderboardSearchTextChange(dispatch)}
            type={"input"}
            customErrorMessage={""}
            setDecider={() => null}
            placeholder={TextEnum.SEARCH}
            search={true}
          />
        </div>
      </div>
      <DataTable
        dataSource={nonWinnerDatas}
        columns={Object.values(LeaderboardColumns())}
        maxPage={pageCount}
        currentPage={page}
        setCurrentPage={useHandleLeaderboardPageChange(dispatch)}
        onRowClick={() => {}}
        selectedIds={selectedIds}
        scroll={true}
      />

      {errorMessage !== null && (
        <MessageModal
          title=""
          message={errorMessage!}
          onCloseClick={handleAlertMessage}
        />
      )}
    </main>
  );
};
