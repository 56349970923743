import { TextEnum } from "../../text";
import userIcon from "../../../static/user@1.5x.png";
import usersIcon from "../../../static/users@1.5x.svg";
import badgeIcon from "../../../static/Vector@1.5x.svg";
import campaignIcon from "../../../static/Path@1.5x.svg";
import adminIcon from "../../../static/wrench-1@1.5x.svg";
import companyIcon from "../../../static/building@1.5x.svg";

export const MenuElements = [
  {
    label: TextEnum.CAMPAIGNS_PAGE_TITLE,
    icon: (
      <img style={{ fill: "white" }} src={campaignIcon} alt={campaignIcon} />
    ),
    path: "/campaign",
  },
  {
    label: TextEnum.COMPANY_PAGE_TITLE,
    icon: <img src={companyIcon} alt={companyIcon} />,
    path: "/company",
  },
  {
    label: TextEnum.BADGE_PAGE_TITLE,
    icon: <img src={badgeIcon} alt={badgeIcon} />,
    path: "/badge",
  },
  {
    label: TextEnum.USER_PAGE_TITLE,
    icon: <img src={userIcon} alt={userIcon} />,
    path: "/user",
  },
  {
    label: TextEnum.TEAM_PAGE_TITLE,
    icon: <img src={usersIcon} alt={usersIcon} />,
    path: "/team",
  },
  {
    label: TextEnum.ADMIN_PAGE_TITLE,
    icon: <img src={adminIcon} alt={adminIcon} />,
    path: "/admin",
  },
  {
    label: TextEnum.VERSION_PAGE_TITLE,
    icon: <img src={adminIcon} alt={adminIcon} />,
    path: "/version",
  },
];
