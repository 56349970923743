import { Expose } from "class-transformer";
import { IsDefined, IsNumber, IsOptional } from "class-validator";
export class Goal {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  score?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  childScore?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  mixedScore?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  inviteSent?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  occasion?: number;
}
