import { Expose, Type } from "class-transformer";
import { IsArray, IsNumber, ValidateNested } from "class-validator";
import { Company } from "../../entity";

export class GetAllCompanyDTO {
  @Expose()
  @ValidateNested()
  @Type(() => Company)
  @IsArray()
  companies!: Company[];

  @Expose()
  @IsNumber()
  pageCount!: number;
}
