import { AxiosResponse } from "axios";
import { BASE_METHODS } from "../../constants/action-types";
import { GetAllBadgeDTO } from "../../constants/dto/badge";
import {
  setBadgeActionStart,
  setBadgeErrorMessage,
  setBadges,
} from "../../constants/reducers/badge.reducer";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { TextEnum } from "../../constants/text";
import { timeoutError } from "../../constants/reducers/base.reducer";

type BadgeSearchProp = {
  name: string;
};

let body: GetAllBadgeDTO;

export const getAllBadge = async ({ name }: BadgeSearchProp) => {
  store.dispatch(setBadgeActionStart(true));
  const queryParams = new URLSearchParams();
  if (name) queryParams.append("name", name);
  const request = await createCall(
    "cms/badges",
    BASE_METHODS.GET,
    "application/json",
    "1.0.0",
    undefined,
    queryParams
  );
  try {
    const response = request as AxiosResponse;
    body = plainToClass(GetAllBadgeDTO, response.data);
    const errors = await validate(body);
    if (errors.length > 0) {
      store.dispatch(
        setBadgeErrorMessage(TextEnum.GET_ALL_BADGE_INVALID_RESPONSE)
      );
      return null;
    }
    store.dispatch(setBadges(response.data.badges));
    return response;
  } catch (error) {
    store.dispatch(setBadgeActionStart(false));
    store.dispatch(timeoutError());
    return null;
  }
};
