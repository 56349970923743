import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PaymentState } from "../state";

const initialState: PaymentState = {
  errorMessage: { code: 1, message: "" },
  actionStart: false,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentErrorMessage: (
      state,
      action: PayloadAction<{ code: number; message: string }>
    ) => {
      state.errorMessage = action.payload;
      state.actionStart = false;
    },
    resetPayment: (state) => {
      state.errorMessage = { code: 1, message: "" };
      state.actionStart = false;
    },
    paymentActionStart: (state, action: PayloadAction<boolean>) => {
      state.actionStart = action.payload;
    },
  },
});

export const { resetPayment, setPaymentErrorMessage, paymentActionStart } =
  paymentSlice.actions;
