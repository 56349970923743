import { useCallback } from "react";
import { Campaign } from "../entity";
import { useAppNavigate } from "../global-hooks";
import { getAllCampaign } from "../../actions/campaign";
import {
  resetCampaign,
  setCampaignChildScore,
  setCampaignDescription,
  setCampaignErrorMessage,
  setCampaignInviteSent,
  setCampaignMixedScore,
  setCampaignName,
  setCampaignOccasion,
  setCampaignPage,
  setCampaignPicture,
  setCampaignPictureUrl,
  setCampaignScore,
  setCampaignSubtitle,
  setCampaignType,
  setIsDeleted,
  setPrivateCampaign,
  setSearchText,
  setValidFrom,
  setValidFromOrder,
  setValidTo,
  setValidToOrder,
} from "../reducers/campaign.reducer";

export const useHandleCampaignNavigateBack = (dispatch: Function) => {
  const navigate = useAppNavigate();
  const handleNavigateBack = useCallback(() => {
    dispatch(resetCampaign());
    navigate("/campaign");
  }, [dispatch, navigate]);
  return handleNavigateBack;
};

export const useHandleCampaignAlertMessage = (dispatch: Function) => {
  const handleAllertMessage = useCallback(
    (message: string | null) => {
      dispatch(setCampaignErrorMessage(message));
    },
    [dispatch]
  );
  return handleAllertMessage;
};

export const useHandleCampaignNameChange = (dispatch: Function) => {
  const handleNameChange = useCallback(
    (event: string) => {
      dispatch(setCampaignName(event));
    },
    [dispatch]
  );
  return handleNameChange;
};

export const useHandleCampaignDescriptionChange = (dispatch: Function) => {
  const handleDescriptionChange = useCallback(
    (event: string) => {
      dispatch(setCampaignDescription(event));
    },
    [dispatch]
  );
  return handleDescriptionChange;
};

export const useHandleCampaignValidFromChange = (dispatch: Function) => {
  const handleChangeValidFrom = useCallback(
    (value: string) => {
      let formattedDate = "";
      if (value) {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        formattedDate = `${year}-${month}-${day}`;
      }
      dispatch(setValidFrom(formattedDate));
    },
    [dispatch]
  );
  return handleChangeValidFrom;
};

export const useHandleCampaignValidToChange = (dispatch: Function) => {
  const handleChangeValidTo = useCallback(
    (value: string) => {
      let formattedDate = "";
      if (value) {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        formattedDate = `${year}-${month}-${day}`;
      }
      dispatch(setValidTo(formattedDate));
    },
    [dispatch]
  );
  return handleChangeValidTo;
};

export const useHandleCampaignPictureUrlChange = (dispatch: Function) => {
  const handleSetPictureUrl = useCallback(
    (value: string) => {
      dispatch(setCampaignPictureUrl(value));
    },
    [dispatch]
  );
  return handleSetPictureUrl;
};

export const useHandleCampaignPictureChange = (dispatch: Function) => {
  const handleSetPicture = useCallback(
    (value: File) => {
      dispatch(setCampaignPicture(value));
    },
    [dispatch]
  );
  return handleSetPicture;
};

export const useHandleCampaignSubtitleChange = (dispatch: Function) => {
  const handleSubtitleChange = useCallback(
    (value: string) => {
      dispatch(setCampaignSubtitle(value));
    },
    [dispatch]
  );
  return handleSubtitleChange;
};

export const useHandleCampaignScoreChange = (dispatch: Function) => {
  const handleScoreChange = useCallback(
    (value: number) => {
      dispatch(setCampaignScore(value));
    },
    [dispatch]
  );
  return handleScoreChange;
};

export const useHandleCampaignChildScoreChange = (dispatch: Function) => {
  const handleChildScoreChange = useCallback(
    (value: number) => {
      dispatch(setCampaignChildScore(value));
    },
    [dispatch]
  );
  return handleChildScoreChange;
};

export const useHandleCampaignMixedScoreChange = (dispatch: Function) => {
  const handleMixedScoreChange = useCallback(
    (value: number) => {
      dispatch(setCampaignMixedScore(value));
    },
    [dispatch]
  );
  return handleMixedScoreChange;
};

export const useHandleCampaignInviteSentChange = (dispatch: Function) => {
  const handleInviteSentChange = useCallback(
    (value: number) => {
      dispatch(setCampaignInviteSent(value));
    },
    [dispatch]
  );
  return handleInviteSentChange;
};

export const useHandleCampaignOccasionChange = (dispatch: Function) => {
  const handleOccasionChange = useCallback(
    (value: number) => {
      dispatch(setCampaignOccasion(value));
    },
    [dispatch]
  );
  return handleOccasionChange;
};

export const useHandleCampaignTypeChange = (dispatch: Function) => {
  const handleTypeChange = useCallback(
    (value: boolean) => {
      dispatch(setCampaignType(value));
    },
    [dispatch]
  );
  return handleTypeChange;
};

export const useHandleCampaignPrivativityChange = (dispatch: Function) => {
  const handleTypeChange = useCallback(
    (value: boolean) => {
      dispatch(setPrivateCampaign(value));
    },
    [dispatch]
  );
  return handleTypeChange;
};

export const useHandleCampaignDeciderChange = (setState: Function) => {
  const handleDecider = useCallback(
    (decider: boolean) => {
      setState((prevState: any) => ({ ...prevState, decider }));
    },
    [setState]
  );
  return handleDecider;
};

export const useHandleCampaignIsDeleteChange = (
  dispatch: Function,
  setState: Function
) => {
  const handleChangeActual = useCallback(
    (value: boolean) => {
      dispatch(setIsDeleted(value));
      setState((prevState: any) => ({ ...prevState, searchTerm: false }));
    },
    [dispatch, setState]
  );
  return handleChangeActual;
};

export const useHandleCampaignPageChange = (
  dispatch: Function,
  setState: Function
) => {
  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(setCampaignPage(page));
      setState((prevState: any) => ({ ...prevState, searchTerm: false }));
    },
    [dispatch, setState]
  );
  return handlePageChange;
};

export const useHandleCampaignSearchTextChange = (dispatch: Function) => {
  const handleSearchChange = useCallback(
    (message: string) => {
      dispatch(setSearchText(message));
    },
    [dispatch]
  );
  return handleSearchChange;
};

export const useHandleCampaignModify = () => {
  const navigate = useAppNavigate();
  const handleModify = useCallback(
    async (record: Campaign) => {
      navigate(`modify/${record.id}`);
    },
    [navigate]
  );
  return handleModify;
};

export const useHandleGetAllCampaign = () => {
  const getCampaigns = useCallback(async () => {
    await getAllCampaign();
  }, []);
  return getCampaigns;
};

export const useHandleCampaignValidFromOrderChange = (dispatch: Function) => {
  const handleValidFromOrderChange = useCallback(
    (value: string) => {
      dispatch(setValidFromOrder(value));
    },
    [dispatch]
  );
  return handleValidFromOrderChange;
};
export const useHandleCampaignValidToOrderChange = (dispatch: Function) => {
  const handleValidToOrderChange = useCallback(
    (value: string) => {
      dispatch(setValidToOrder(value));
    },
    [dispatch]
  );
  return handleValidToOrderChange;
};
