import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Version } from "../entity";
import { VersionState } from "../state";

const initialState: VersionState = {
  versions: [],
  page: 1,
  version: "",
  platform: 0,
  critical: false,
  pageCount: null!,
  errorMessage: null,
  versionActionStart: false,
  searchText: "",
};

export const versionSlice = createSlice({
  name: "vesrion",
  initialState,
  reducers: {
    setVersions: (
      state,
      action: PayloadAction<{ versions: Version[]; pageCount: number }>
    ) => {
      state.versions = action.payload.versions;
      state.pageCount = action.payload.pageCount;
      state.versionActionStart = false;
    },

    setVersionPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setVersionVersion: (state, action: PayloadAction<string>) => {
      state.version = action.payload;
    },
    setVersionPlatform: (state, action: PayloadAction<boolean>) => {
      state.platform = action.payload ? 1 : 0;
    },
    setVersionCritical: (state, action: PayloadAction<boolean>) => {
      state.critical = action.payload;
    },
    setVersionSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setVersionErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.versionActionStart = false;
    },
    resetVersion: (state) => {
      state.page = 1;
      state.version = "";
      state.platform = null!;
      state.critical = false;
      state.errorMessage = null;
      state.versionActionStart = false;
      state.searchText = "";
    },
    setVersionActionStart: (state, action: PayloadAction<boolean>) => {
      state.versionActionStart = action.payload;
    },

    removeVersionFromVersions: (state, action: PayloadAction<number>) => {
      if (state.versions.length % 10 === 1) {
        state.page -= 1;
        state.pageCount -= 1;
      }
      state.versions = state.versions.filter(
        (version) => version.id !== action.payload
      );
      state.versionActionStart = false;
    },
  },
});

export const {
  setVersions,
  setVersionPage,
  setVersionVersion,
  setVersionPlatform,
  setVersionCritical,
  setVersionSearchText,
  resetVersion,
  setVersionErrorMessage,
  setVersionActionStart,
  removeVersionFromVersions,
} = versionSlice.actions;
