import { Modal } from "../modal";

interface ConfirmMessageProps {
  message: string;
  onClose: () => void;
}

export const ConfirmMessage: React.FC<ConfirmMessageProps> = ({
  message,
  onClose,
}) => {
  return (
    <Modal className="confirm-message-modal">
      <div className="modal-title">Sikeres mentés!</div>
      <div className="modal-message">{message}</div>
      <footer>
        <button className="button button--primary" onClick={() => onClose()}>
          Rendben
        </button>
      </footer>
    </Modal>
  );
};
