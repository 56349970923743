import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { TextBox } from "../../constants/features/input";
import { forgotPassword } from "../../actions/forgot-password";
import { InfoMessageModal } from "../../constants/features/modal";
import { useHandleForgotPasswordEmailChange } from "../../constants/utils";
import { useAppDispatch, useAppSelector } from "../../constants/global-hooks";
import { setErrorMessage } from "../../constants/reducers/forgot.password.reducer";

import "./forgot-password.scss";

export const ForgotPasswordPage = () => {
  const dispatch = useAppDispatch();
  const { email, errorMessage, forgotPasswordActionStart } = useAppSelector(
    (state: RootState) => state.forgotPassword
  );

  const handleButtonClick = async () => {
    await forgotPassword();
  };

  const handleModalClose = () => {
    dispatch(setErrorMessage(null));
  };

  return (
    <main className="forgot-password-main-page">
      <TextBox
        disabled={false}
        value={email}
        onChange={useHandleForgotPasswordEmailChange(dispatch)}
        placeholder={TextEnum.EMAIL_TEXT}
        type={"input"}
        isLogin={true}
        customErrorMessage=""
        setDecider={() => {
          return;
        }}
      />
      <button
        className={`${!email || forgotPasswordActionStart ? "disabled" : ""}`}
        disabled={!email || forgotPasswordActionStart}
        type="submit"
        onClick={handleButtonClick}
      >
        {TextEnum.FORGOT_PASSWORD_BUTTON}
      </button>
      {errorMessage !== null && (
        <InfoMessageModal
          message={TextEnum.FORGOT_PASSWORD_MODAL_MESSAGE}
          onClose={handleModalClose}
        />
      )}
    </main>
  );
};
