import { useMemo } from "react";

import "./button.scss";

type DeciderButtonProps = {
  decider: number;
  handleChange: (value: number) => void;
  defaultButton: string;
  secondButton: string;
  thirdButton: string;
};

export const DeciderButtons: React.FC<DeciderButtonProps> = ({
  decider,
  handleChange,
  defaultButton,
  secondButton,
  thirdButton,
}) => {
  return useMemo(() => {
    return (
      <div>
        <button
          className={`${decider === 1 ? "selected" : "not-selected"}`}
          onClick={() => handleChange(1)}
        >
          {defaultButton}
        </button>
        <button
          className={`${decider === 2 ? "selected" : "not-selected"}`}
          onClick={() => handleChange(2)}
        >
          {secondButton}
        </button>
        <button
          className={`${decider === 3 ? "selected" : "not-selected"}`}
          onClick={() => handleChange(3)}
        >
          {thirdButton}
        </button>
      </div>
    );
  }, [decider, defaultButton, secondButton, thirdButton, handleChange]);
};
