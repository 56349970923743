import { Expose, Type } from "class-transformer";
import { IsArray, ValidateNested } from "class-validator";
import { PredictiveSearch } from "../../entity";

export class PredictiveSearchCompanyDTO {
  @Expose()
  @ValidateNested()
  @Type(() => PredictiveSearch)
  @IsArray()
  companies!: PredictiveSearch[];
}
