import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Team } from "../entity";
import { TeamState } from "../state";

const initialState: TeamState = {
  teams: [],
  searchText: "",
  page: 1,
  companyName: "",
  nameOrder: "",
  createdOrder: "",
  pageCount: null!,
  errorMessage: "",
  teamActionStart: false,
  selectedIds: [],
  companyTeam: true
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeams: (
      state,
      action: PayloadAction<{ teams: Team[]; pageCount: number }>
    ) => {
      state.teams = action.payload.teams;
      state.pageCount = action.payload.pageCount;
      state.errorMessage = null!;
      state.teamActionStart = false;
    },

    setTeamPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setTeamSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
      state.page = 1;
    },

    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },

    setNameOrder: (state, action: PayloadAction<string>) => {
      state.nameOrder = action.payload;
    },

    setCreatedOrder: (state, action: PayloadAction<string>) => {
      state.createdOrder = action.payload;
    },

    setCompanyTeam: (state, action: PayloadAction<boolean>) => {
      state.companyTeam = action.payload;
    },

    setTeamActionStart: (state, action: PayloadAction<boolean>) => {
      state.teamActionStart = action.payload;
    },

    setTeamErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },

    setSelectedIds: (state, action: PayloadAction<number[]>) => {
      state.selectedIds = action.payload;
    },

    removeTeams: (state) => {
      state.teams = state.teams.filter(
        (team) => !state.selectedIds.includes(team.id)
      );
      state.errorMessage = null!;
      state.teamActionStart = false;
    },

    resetTeams: (state) => {
      state.companyName = "";
      state.createdOrder = "";
      state.nameOrder = "";
      state.page = 1;
      state.selectedIds = [];
      state.searchText = "";
    },
  },
});

export const {
  setTeams,
  setTeamPage,
  setCompanyName,
  setNameOrder,
  setCreatedOrder,
  setCompanyTeam,
  setTeamActionStart,
  setTeamErrorMessage,
  setSelectedIds,
  resetTeams,
  removeTeams,
  setTeamSearchText,
} = teamSlice.actions;
