import { useMemo } from "react";
import { LoadingOutlined, InteractionOutlined } from "@ant-design/icons";
import { TextEnum } from "../../text";
import { Company } from "../../entity";
import { RootState } from "../../../store";
import { CustomPopConfirm } from "../popconfirm";
import deleteIcon from "../../../static/trash.svg";
import { useAppSelector } from "../../global-hooks";
import { deleteCompany } from "../../../actions/company";

type CompanyColumnsProps = {
  handleModalVisible: (id: number) => void;
};

export const CompanyColumns = ({ handleModalVisible }: CompanyColumnsProps) => {
  const { companyActionStart } = useAppSelector(
    (state: RootState) => state.company
  );

  const handleDelete = async (id: number) => {
    await deleteCompany({ id });
  };

  return useMemo(() => {
    const columns = [
      {
        title: TextEnum.COMPANY_NAME,
        key: TextEnum.COMPANY_NAME,
        render: (text: string, record: Company) => {
          return record.name.length > 100
            ? record.name.substring(0, 100) + "..."
            : record?.name;
        },
        width: "350px",
      },
      {
        title: TextEnum.COMPANY_TAX_NUMBER,
        key: TextEnum.COMPANY_TAX_NUMBER,
        dataIndex: "taxNumber",
        width: "100px",
      },
      {
        title: TextEnum.COMPANY_CODE,
        key: TextEnum.COMPANY_CODE,
        dataIndex: "code",
        width: "50px",
      },

      {
        title: TextEnum.RESEND_BUTTON_HEADER,
        key: TextEnum.RESEND_BUTTON_HEADER,
        render: (text: string, record: Company) => {
          return (
            <div>
              <InteractionOutlined
                style={{ marginRight: "2em" }}
                onClick={() => handleModalVisible(record.id)}
              />
              <CustomPopConfirm
                title={TextEnum.COMPANY_DELETE_POPUP_CONFIRM}
                okText={TextEnum.YES}
                cancelText={TextEnum.NO}
                onConfirm={() => handleDelete(record.id)}
                disabled={false}
              >
                <button
                  disabled={companyActionStart}
                  className="company-table-delete-button"
                >
                  {companyActionStart ? (
                    <LoadingOutlined />
                  ) : (
                    <img
                      className="table-icon"
                      src={deleteIcon}
                      alt={deleteIcon}
                    />
                  )}
                </button>
              </CustomPopConfirm>
            </div>
          );
        },
        width: "100px",
      },
    ];

    return columns;
  }, [companyActionStart, handleModalVisible]);
};
