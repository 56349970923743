import { useState, useEffect } from "react";
import { TextEnum } from "../../text";
import { RootState } from "../../../store";
import { predictiveSearchCompany } from "../../../actions/company";
import { setCampaignSelectedId } from "../../reducers/campaign.reducer";
import {
  useAppDispatch,
  useAppParams,
  useAppSelector,
} from "../../global-hooks";

interface CompanyOption {
  label: string;
  value: number;
}
export const CustomCompanySelector = () => {
  const dispatch = useAppDispatch();
  const params = useAppParams();
  const { company, selectedId, campaign } = useAppSelector(
    (state: RootState) => state.campaign
  );

  const [state, setState] = useState({
    showOptions: false,
    selectedCompany: "",
    searchCompanyName: "",
  });

  useEffect(() => {
    if (campaign && params.id) {
      setState((prevState) => ({
        ...prevState,
        selectedCompany: campaign.company?.name,
      }));
      setState((prevState) => ({
        ...prevState,
        searchCompanyName: campaign.company?.name,
      }));
    }
  }, [dispatch, campaign, params.id]);

  const handleSelectCompany = (option: CompanyOption) => {
    setState((prevState) => ({
      ...prevState,
      searchCompanyName: option.label,
    }));
    setState((prevState) => ({
      ...prevState,
      selectedCompany: option.label,
    }));
    dispatch(setCampaignSelectedId(option.value));
  };

  const handleSearch = async (value: string) => {
    setState((prevState) => ({
      ...prevState,
      searchCompanyName: value,
    }));
    await predictiveSearchCompany({ name: value });
  };

  const handleCancelSelectedCompany = () => {
    dispatch(setCampaignSelectedId(null!));
    setState((prevState) => ({
      ...prevState,
      selectedCompany: "",
    }));
    setState((prevState) => ({
      ...prevState,
      searchCompanyName: "",
    }));
  };

  return (
    <div className="select-wrapper">
      <input
        maxLength={255}
        type="text"
        placeholder={
          state.selectedCompany
            ? state.selectedCompany
            : TextEnum.CREATE_CAMPAIGN_COMPANY_SEARCH_BUTTON
        }
        onChange={(e) => handleSearch(e.target.value)}
        onBlur={() =>
          setState((prevState) => ({
            ...prevState,
            showOptions: false,
          }))
        }
        onFocus={() =>
          setState((prevState) => ({
            ...prevState,
            showOptions: true,
          }))
        }
        value={state.searchCompanyName}
        disabled={campaign ? true : false}
        style={campaign ? { backgroundColor: "silver" } : {}}
      />
      {state.showOptions && (
        <ul className="options-list">
          <li onMouseDown={handleCancelSelectedCompany}>
            {TextEnum.NONE_OF_THEM}
          </li>
          {company.map((option: CompanyOption) => (
            <li
              className={
                selectedId && selectedId === option.value ? "selected" : ""
              }
              key={option.value}
              onMouseDown={() => handleSelectCompany(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
