import { useRef, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { RootState } from "../../store";
import { TextEnum } from "../../constants/text";
import { ROLE_TYPE_ENUM } from "../../constants/enum";
import { TextBox } from "../../constants/features/input";
import { MessageModal } from "../../constants/features/modal";
import { useAppDispatch, useAppSelector } from "../../constants/global-hooks";
import {
  useHandleLogin,
  useHandleLoginAlertMessage,
  useHandleLoginEmailChange,
  useHandleLoginNavigate,
  useHandleLoginPasswordChange,
} from "../../constants/utils";

import "./login.scss";

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const authenticatedUser = useAppSelector(
    (state: RootState) => state.session.authenticatedUser
  );
  const { email, password, alertMessage, loginStarted, remainingTime } =
    useAppSelector((state: RootState) => state.login);

  const handleAlertMessage = useHandleLoginAlertMessage(dispatch);
  const handleEmailChange = useHandleLoginEmailChange(dispatch);
  const handlePasswordChange = useHandleLoginPasswordChange(dispatch);
  const handleForgotPasswordClick = useHandleLoginNavigate();
  const handleLogin = useHandleLogin();
  const timerRef = useRef<NodeJS.Timeout>();
  const [timer, setTimer] = useState<number>(null!);

  useEffect(() => {
    if (remainingTime) {
      clearTimeout(timerRef.current);
      timerRef.current = setInterval(() => {
        const remaining = Math.floor(
          (remainingTime.getTime() - new Date().getTime()) / 1000
        );
        setTimer(remaining);
      }, 100);
    }
    if (timer === 0) {
      setTimer(null!);
      clearInterval(timerRef.current);
    } else {
      return () => {
        clearInterval(timerRef.current);
      };
    }
  }, [remainingTime, timer]);

  useEffect(() => {
    if (timer <= 0) {
      handleAlertMessage(null);
    }
  }, [handleAlertMessage, timer]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleLogin();
      }
    };
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleLogin]);

  if (authenticatedUser) {
    if (authenticatedUser.role.id === ROLE_TYPE_ENUM.MOBILE_ADMIN) {
      const url =
        new URL(window.location.href).searchParams.get("redirect") ??
        "/version";
      return <Navigate to={url} />;
    }
    const url =
      new URL(window.location.href).searchParams.get("redirect") ?? "/campaign";
    return <Navigate to={url} />;
  }

  return (
    <main className="login-main-page">
      <TextBox
        value={email}
        onChange={handleEmailChange}
        type="input"
        placeholder={TextEnum.EMAIL_TEXT}
        isLogin={true}
        customErrorMessage=""
        setDecider={() => {
          return;
        }}
      />
      <TextBox
        value={password}
        onChange={handlePasswordChange}
        type="input"
        isPassword={true}
        placeholder={TextEnum.PASSWORD_PLACEHOLDER}
        isLogin={true}
        customErrorMessage=""
        setDecider={() => {
          return;
        }}
      />

      <button
        type="submit"
        onClick={handleLogin}
        disabled={loginStarted !== null}
      >
        {loginStarted !== null ? <LoadingOutlined /> : TextEnum.LOGIN_TEXT}
      </button>
      <span onClick={handleForgotPasswordClick}>
        {TextEnum.FORGOT_PASSWORD}
      </span>
      {alertMessage !== null && (
        <MessageModal
          title={TextEnum.ERROR}
          message={
            remainingTime && timer > 0 && timer !== null
              ? `${alertMessage}${timer}`
              : alertMessage
          }
          onCloseClick={handleAlertMessage}
        />
      )}
    </main>
  );
};
