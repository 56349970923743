import { Expose } from "class-transformer";
import { IsArray, IsDefined, IsNumber, ValidateNested } from "class-validator";
import { NonWinnerUsers } from "../../entity";

export class GetLeaderboardDTO {
  @Expose()
  @IsDefined()
  @ValidateNested()
  @IsArray()
  nonWinnerDatas!: NonWinnerUsers[];

  @Expose()
  @IsDefined()
  @IsNumber()
  pageCount!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  campaignType!: number;
}
